import React, { useCallback, useEffect } from "react";  
 
import Header from 'components/Dashboard/Header'  
  
import { 
    DashboardPage,
    DashboardBody,
    DashboardBodyContent,
    Content
} from "./styled";
import { ReadObject } from "services/storage";
import { useHistory } from "react-router-dom";
import { ThemedComponent } from "ui/theme";
import DashboardSide from "components/Dashboard/Side";

export default function ContainerAuthenticated({ children, page, subpage, noside }){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const completeNext = useCallback(() => {
        navigate('login')
    }, [navigate])

    const init = useCallback(() => {
        const authentication = ReadObject('authentication')
        if (!authentication?.jwt) {
            completeNext()
        }
    }, [completeNext])
    
    useEffect(() => {  
        init()
        window.scrollTo(0,0)
    }, [init])

    return ( 
        <>
            <ThemedComponent>
                <Content>
                    <DashboardPage>
                        <Header /> 
                        <DashboardBody> 
                            { noside ? null : <DashboardSide page={page} subpage={subpage} /> }
                            <DashboardBodyContent>                     
                                { children }
                            </DashboardBodyContent>
                        </DashboardBody>  
                    </DashboardPage> 
                </Content>
            </ThemedComponent>
        </>
    );
}