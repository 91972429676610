import React from "react";  

import { useHistory } from "react-router-dom";

import { ContentTableAction, ContentTableActionIconEdit, ContentTableActionIconRemove, ContentTableItem, DashboardTitle } from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import DashboardCardTable from "components/Cards/Table";
import DashboardCardWrapper from "components/Cards/Wrapper";

import Button from "components/Form/Button";
import { BetweenCenter, EmptyDiv, StatusItem, StatusItemContent } from "ui/styled";
import DashboardListActions from "components/Dashboard/ListActions";
import useController from "./controller";

export default function DashboardListOrders({ page }){  

    const  {
        loading,
        columns,
        rows,
        navigate
    } = useController()

    return ( 
        <>
            <ContainerAuthenticated page={page || 2} subpage={4}> 
                <BetweenCenter spaced>
                    <DashboardTitle>Ordens de compra</DashboardTitle>
                    {/* <EmptyDiv>
                        <Button secondary nospace onClick={() => navigate("dashboard/details/construction/orders")}>Novo</Button>
                    </EmptyDiv> */}
                </BetweenCenter>
                <Row>  
                    <Col sm={12}>
                        <DashboardCardWrapper custom={
                            <DashboardListActions />
                        }>
                            <DashboardCardTable rows={rows} columns={columns} blue />
                        </DashboardCardWrapper>
                    </Col> 
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}