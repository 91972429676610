import styled from 'styled-components'  


export const FormActions = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
`;


export const FormWhite = styled.div.attrs({ 
})`
    background: ${ props => props.theme.palette.colors.white };
    padding: 12px 0 0 12px;
`;


export const HeaderCheck = styled.div.attrs({ 
})`
    border: 1px solid ${ props => props.theme.palette.colors.backgroundcard };
    padding: 12px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const HeaderCheckTitle = styled.div.attrs({ 
})`
    font-size: 14px;
    font-weight: 700;
    color: ${ props => props.theme.palette.colors.black };
`;

export const HeaderCheckText = styled.div.attrs({ 
})`
    font-size: 14px;
    color: ${ props => props.theme.palette.colors.text };
    margin: 12px 0 0 6px;
`;



