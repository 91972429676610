import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister, valid } from "utils";
import { toast } from "react-toastify";
import Toggle from "components/Form/Toggle";
import { formPersonalData } from "utils/forms";

import { Create, ReadOne, Update, Delete } from "services/users";
import { optionsUserCategory } from "utils/options";
import { CoreContext } from "context/CoreContext";
import { Read } from "services/constructions";

export default function useController(){   

    const history = useHistory(); 
    const back = () => { history.goBack() ;}
    const navigate = to => history.push(`/${ to }`); 

    const { user } = useContext(CoreContext)

    const { id } = useParams()

    const refForm = useRef()
    const refFormAccess = useRef()
    
    const [activated, setActivated] = useState(false)
    const [register, setRegister] = useState(null)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    const actions = [
        { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Salvar', action:() => save(), loadable:true }
    ]

    const accessItems = [
        { 
            custom: <>
                <Toggle title="Habilitar acesso ao sistema" checked={activated} onChange={() => setActivated(!activated)} />
            </> 
        },
    ]

    const [optionsConstruction, setOptionsConstruction] = useState([])

    const formData = useMemo(() => {
        return [
            ...formPersonalData?.map(m => m?.ref === 'construction' ? ({
                ...m,
                options: optionsConstruction
            }) : m )
        ]
    }, [optionsConstruction])
    
    const init = useCallback(async () => {
        setLoading(true)

        if(id){ 
            const result = await ReadOne(id)
            if(result?.data?.id){
                const normalResult = normalizeStrapiRegister(result)
                setRegister({
                    ...normalResult,
                    category: optionsUserCategory?.find(f => f.ref === normalResult?.category)?.id, 
                })
                setActivated(!normalResult?.blocked)
            }
        }

        const resBuilds = await Read()
        setOptionsConstruction(resBuilds?.data?.length ? normalizeStrapiList(resBuilds)?.map(m => ({ ...m, title: m?.name })) : [])

        setLoading(false)
    }, [id])

    const save = async () => {
        
        const form = refForm.current.getForm()
        
        const payload = {
            ...form,
            blocked: !activated,
            role:1,
            username: form?.email?.replace(/ /g,''),
            password: `${ new Date().getTime() }`,

            category: optionsUserCategory?.find(f => f.id === form?.category)?.ref,
            user: user?.id
        }

        delete payload.id

        // if(!valid(payload, formPersonalData)){ toast.error("Preencha todos os campos"); return ;}

        setSaving(true)
        // const result = id ? await Update({data:payload}, id) : await Create({data:payload})
        const result = id ? await Update({...payload}, id) : await Create({...payload})

        if(result && !exposeStrapiError(result)){            
            toast.success("Salvo")
            history.goBack()
        } else {
            toast.error("Erro ao salvar, tente novamente mais tarde")
        }

        setSaving(false)
    }

    const remove = async row => {
        setLoading(true) 
        const result = await Delete(row?.id)
        if(result && !exposeStrapiError(result)){
            toast.success('Removido com sucesso') ;
        }
        history.goBack();
    }

    useEffect(() => { init() }, [id, init])

    return {
        back,
        navigate,

        refForm,
        refFormAccess,
        
        register,
        loading,
        saving,

        actions,
        accessItems,

        formData,

        remove,
        id
    }
}