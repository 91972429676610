import styled from 'styled-components'   
 
export const DashboardHeaderContainer = styled.div.attrs({ 
})`           
    height: 60px;
    width: 100%;

    background: ${ props => props.theme.palette.colors.white };
    background: linear-gradient(45deg, rgba(${props => props.theme.palette.colors.white}, .45) 0%, rgba(${props => props.theme.palette.colors.white}, .6) 25%, rgba(${props => props.theme.palette.colors.white}, .75) 50%, rgba(${props => props.theme.palette.colors.white}, .9) 75%, rgba(${props => props.theme.palette.colors.white}, 1) 100%);
    padding: 0 25px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    box-shadow: 0px 3px 6px ${ p => p.theme.palette.colors.shadow };
    z-index:1;
    border-bottom: 1px solid ${ p => p.theme.palette.colors.lightshadow };
`;

export const DashboardHeaderAction = styled.div.attrs({ 
})`           
    color: ${ props => props.theme.palette.colors.white };
    font-size: 15px;
    text-transform: uppercase;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const AppLogo = styled.img.attrs({ 
    src:`/logo1024.png`,
    alt:"logo-icon",
    height:50
})`            
`;

export const DashboardHeaderLogo = styled.img.attrs({ 
    height: 36
})`           
    margin: 0 16px;
    ${
        p => !p.opened ? `
            margin: 0 0 0 -6px;
        ` : ``
    }
`;

export const DashboardHeaderActionIcon = styled.img.attrs({ 
})`           
    margin-left: 30px;
    transition: all .3s ease;
    ${
        p => !p.opened ? `
            transform: rotate(180deg);
        ` : ``
    }
`;

export const DashboardMenuContainer = styled.div.attrs({ 
})`           
    position: fixed;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: ${ props => props.theme.palette.colors.shadow };
`;

export const DashboardMenu = styled.div.attrs({ 
    className:'menu-contant'
})`           
    max-width: 389px;
    background: ${ props => props.theme.palette.colors.white };
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const DashboardMenuHeader = styled.div.attrs({ 
})`           
    height: 60px;
    width: 100%; 
    display: flex;
    align-items: center;
    padding: 0 20px;

    text-transform: uppercase;
    font-size: 15px;
    color: ${ props => props.theme.palette.colors.white };
    cursor: pointer;

    background: ${ props => props.theme.palette.primary.main };
    background: linear-gradient(48deg, rgba(${props => props.theme.palette.primary.main},1) 0%, rgba(${props => props.theme.palette.primary.main},.9) 21%, rgba(${props => props.theme.palette.primary.main},.75) 49%, rgba(${props => props.theme.palette.primary.main},.6) 87%, rgba(${props => props.theme.palette.primary.main},.45) 100%);
`;

export const DashboardMenuHeaderIcon = styled.img.attrs({ 
})`           
    margin-right: 20px;
    cursor: pointer;
`;

export const DashboardMenuHeaderUserContent = styled.div.attrs({ 
})`           
    padding: 27px 25px;
    border-bottom: 1px solid ${ props => props.theme.palette.colors.lightgrey };
    margin-bottom: 28px;
`;

export const DashboardMenuHeaderUserImage = styled.div.attrs({ 
})`           
    width: 160px;
    height: 160px; 
    background: ${ props => props.theme.palette.colors.grey } url(/logo1024.png) no-repeat center center / cover;
    margin: 0 auto 12px;
    overflow: hidden;
`;

export const DashboardMenuHeaderUserText = styled.div.attrs({ 
})`           
    font-size: 15px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.grey };
    margin-bottom: 12px; 
`;

export const DashboardMenuOption = styled.div.attrs({ 
})`           
    padding: 20px 30px;
    font-size: 15px;
    color: ${ props => props.theme.palette.colors.grey };
    cursor: pointer; 

    &:hover{
        text-decoration: underline;
    }
    
    ${
        props => props.active ? `
            background: ${ props.theme.palette.primary.main };
            font-size: 15px;
            font-weight: bold;
            color: ${ props.theme.palette.colors.white };
        ` : ``
    }
`;

export const DashboardMenuContent = styled.div.attrs({ 
})` 
    flex:1;
`;

export const DashboardMenuFooter = styled.div.attrs({ 
})`
    padding: 20px;
`;

export const DashboardVersionContent = styled.div.attrs({ 
})` 
    margin: 24px 0;
`;

export const DashboardVersionText = styled.div.attrs({ 
})`
    font-size: 15px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.grey };
    text-align: center; 
`;





export const DashboardUserProfileContainer = styled.div.attrs({ 
})` 
    display: flex;
    align-items: center;
    gap: 12px;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.01);
    }
`;
export const DashboardUserProfileImage = styled.div.attrs({ 
})` 
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background: ${ p => p.theme.palette.colors.backgroundgrey } url(${ p => p.image }) no-repeat center center;
`;
export const DashboardUserProfile = styled.div.attrs({ 
})` 

`;
export const DashboardUserProfileTitle = styled.div.attrs({ 
})` 
    font-size: 12px;
    color: ${ p => p.theme.palette.colors.text};
`;
export const DashboardUserProfileText = styled.div.attrs({ 
})` 
    font-size: 16px;
    font-weight: bold;
    color: ${ p => p.theme.palette.colors.text};

`;








export const HeaderMenuContainer = styled.div.attrs({ 
})` 
    padding: 12px;
    border-radius: 8px;
    background: ${ p => p.theme.palette.colors.backgroundcard };
    box-shadow: 0px 3px 6px ${ p => p.theme.palette.colors.shadow };

    position: absolute;
    z-index:1;
    right: 10px;
    margin-top: 6px;


    width: 100%;
    max-width: 320px;

`;
export const HeaderMenuItem = styled.div.attrs({ 
})` 
    padding: 12px;
    border-radius: 8px;
    background: ${ p => p.theme.palette.colors.white };
    color: ${ p => p.theme.palette.colors.text };
    margin-bottom: 6px;
    display: flex;
    cursor: pointer;
    transition: all .3s ease;
    ${
        p => p.opened ? `
            background: ${ p.theme.palette.primary.main };
            color: ${ p.theme.palette.colors.white };
        ` : `
        `
    }
    &:hover{
        transform: scale(1.01);
    }
`;
export const HeaderMenuItemText = styled.div.attrs({ 
})` 
    flex:1;
`;
export const HeaderMenuItemIcon = styled.img.attrs( p => ({ 
    src: p.opened ? '/icons/chevron-white.svg' : '/icons/chevron.svg'
}))` 
    ${
        p => p.opened ? `
            transform: rotate(90deg);
        ` : `
            transform: rotate(180deg);
        `
    }
`;
export const HeaderMenuItemSeparator = styled.div.attrs({ 
})` 
    margin: 12px 0px;
    height: 1px;
    width: 100%;
    border-bottom: 1px solid ${ p => p.theme.palette.colors.lightshadow };
`;