import React, { useContext } from "react";  

import { useHistory } from 'react-router-dom';

import {  

    DashboardMenu,

    DashboardMenuOption,
    DashboardMenuContent,
    DashboardMenuFooter,
    
    DashboardMenuOptionIcon,

    BackItem,
    BackItemIcon,
    BackItemText,
    PageItem,
    PageItemIcon,
    PageItemText,

} from "./styled"; 

import Button from "components/Form/Button";
import { DoLogout } from "services/authentication";
import { CoreContext } from "context/CoreContext";

export default function DashboardSide({ page, subpage }){ 
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
    
    const { opened: cOpen} = useContext(CoreContext) 

    const exit = async () => {
        await DoLogout()
        navigate('login')
    }

    const suboptionRegisters = [
        { title: "Usuários", page:'dashboard/register/users' },
        { title: "Clientes", page:'dashboard/register/customers' },
        { title: "Obras", page:'dashboard/register/constructions' },
        { title: "Fornecedores", page:'dashboard/register/suppliers' },
        { title: "Arquitetos", page:'dashboard/register/architects' },
        { title: "Colaboradores", page:'dashboard/register/colaboratos' },
        { title: "Materiais", page:'dashboard/register/materials' },
    ]
     
    const suboptionConstructions = [
        { title: "Contratos e adendos", page:'dashboard/construction/contracts' },
        { title: "Solicitação de Materiais", page:'dashboard/construction/request-material' },
        { title: "Solicitação de serviços", page:'dashboard/construction/request-service' },
        { title: "Mapa de concorrência", page:'dashboard/construction/maps' },
        { title: "Ordens de compra", page:'dashboard/construction/orders' },
        { title: "Medição", page:'dashboard/construction/measurements' },
        { title: "Guia de pagamento", page:'dashboard/construction/payments' },
        { title: "Cronograma", page:'dashboard/construction/timeline' },
        { title: "Diário fotográfico", page:'dashboard/construction/daily' },
    ]

    const suboptionSupplies = [
        { title: "Contratos e adendos", page:'dashboard/supply/contracts' },
        { title: "Solicitação de Materiais", page:'dashboard/supply/request-material' },
        { title: "Solicitação de serviços", page:'dashboard/supply/request-service' },
        { title: "Mapa de concorrência", page:'dashboard/supply/maps' },
        { title: "Ordens de compra", page:'dashboard/supply/orders' },
    ]

    const suboptionFinances = [
        { title: "Medição", page:'dashboard/finance/measurements' },
        { title: "Guia de pagamento", page:'dashboard/finance/payments' },
        { title: "Controle financeiro", page:'dashboard/finance/controls' }
    ]

    const suboptionMaintain = [
        { title: "Contratos e adendos", page:'dashboard/maintain/contracts' },
        { title: "Solicitação de Materiais", page:'dashboard/maintain/request-material' },
        { title: "Solicitação de serviços", page:'dashboard/maintain/request-service' },
        { title: "Mapa de concorrência", page:'dashboard/maintain/maps' },
        { title: "Ordens de compra", page:'dashboard/maintain/orders' },
        { title: "Guia de pagamento", page:'dashboard/maintain/payments' },
        { title: "Cronograma", page:'dashboard/maintain/timeline' },
        { title: "Diário fotográfico", page:'dashboard/maintain/daily' },
        { title: "Controle de manutenção", page:'dashboard/maintain/control' },
        { title: "Manual da obra", page:'dashboard/maintain/manual' },
    ]

    const suboptionReports = [
        { title: "Centro de custos", page:'dashboard/report/center' },
        { title: "Contas a pagar e receber", page:'dashboard/report/accounts' },
        { title: "Movimentação de conta", page:'dashboard/report/moviment' },
        { title: "Controle de lucros e prejuizos", page:'dashboard/report/revenues' },
        { title: "Fluxo de caixa mensal", page:'dashboard/report/monthly' },
        { title: "Orçado e comprometido", page:'dashboard/report/budget' },
        { title: "Indicadores de custos", page:'dashboard/report/costs' },
    ]

    const suboptionCustomers = [
        { title: "Tutorial e primeiro acesso", page:'dashboard/customer/tutorial' },
        { title: "Financeiro", page:'dashboard/customer/finances' },
        { title: "Guia de pagamentos", page:'dashboard/customer/payments' },
        { title: "Manual de Obra", page:'dashboard/customer/manual' },
        { title: "Solicitar manutenção", page:'dashboard/customer/maintain' },
    ]

    const sideOptions = [
        { title: "Home", icon: "side1", page:'dashboard' },
        { title: "Cadastros", icon: "side2", page:'dashboard/register/users', suboptions:[ ...suboptionRegisters ] },
        { title: "Obra", icon: "side3", page:'dashboard/construction/contracts', suboptions:[ ...suboptionConstructions ] },
        { title: "Suprimentos", icon: "side4", page:'dashboard/supply/contracts', suboptions:[ ...suboptionSupplies ] },
        { title: "Financeiro", icon: "side5", page:'dashboard/finance/measurements', suboptions: [ ...suboptionFinances ] },
        { title: "Relatórios", icon: "side6", page:'dashboard/report/center', suboptions: [ ...suboptionReports ] },
        { title: "Clientes", icon: "side7", page:'dashboard/customer/tutorial', suboptions: [ ...suboptionCustomers ] },
        { title: "Manutenção", icon: "side8", page:'dashboard/maintain/contracts', suboptions:[ ...suboptionMaintain ] },
    ]
    
    const action = item => {
        if(item?.page){
            navigate(item.page)
        }
    }

    const opened = subpage !== undefined ? true : cOpen
    
    return ( 
        <>  
            { 
                    <DashboardMenu opened={opened}> 
                        {/* <DashboardMenuHeader onClick={() => setOpened(false)}>
                            <DashboardMenuHeaderIcon src={'/icons/close-white.svg'} />
                            fechar
                        </DashboardMenuHeader>  */}
                        {/* <DashboardMenuHeaderUserContent>
                            <DashboardMenuHeaderUserImage /> 
                        </DashboardMenuHeaderUserContent>  */}




                        {
                            !(sideOptions?.[page]?.suboptions?.[subpage]) ? <>
                                <DashboardMenuContent>
                                    {
                                        sideOptions?.map((item, key) => 
                                            <DashboardMenuOption key={key} active={page === key} onClick={() => action(item)}> 
                                                <DashboardMenuOptionIcon active={page === key} icon={item?.icon} />
                                                { opened ? item?.title : '' }
                                            </DashboardMenuOption>
                                        )
                                    }
                                </DashboardMenuContent>
                            </> : <>

                                <BackItem onClick={() => navigate('dashboard')}>
                                    <BackItemIcon />
                                    <BackItemText>Voltar menu principal</BackItemText>
                                </BackItem>
                                <PageItem>
                                    <PageItemIcon icon={sideOptions?.[page]?.icon} />
                                    <PageItemText>{ sideOptions?.[page]?.title }</PageItemText>
                                </PageItem>

                                <DashboardMenuContent>

                                    {
                                        (sideOptions?.[page]?.suboptions)?.map((item, key) => 
                                            <DashboardMenuOption key={key} active={subpage === key} onClick={() => action(item)}> 
                                                { opened ? item?.title : '' }
                                            </DashboardMenuOption>
                                        )
                                    }

                                </DashboardMenuContent>
                            </>
                        }
                        
                        {/* 
                        <DashboardMenuFooter opened={opened}>
                            <Button primary outline onClick={exit} centred>
                                Sair
                            </Button>   
                        </DashboardMenuFooter> 
                        */}

                    </DashboardMenu> 
            } 
        </>
    );
}