import styled from 'styled-components'  


export const FormActions = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
`;


export const UploadContentTitle = styled.div.attrs({ 
})`
    font-size: 14px;
    font-weight: bold;
    color: ${ p => p.theme.palette.colors.text };
    padding: 8px 0; 
`;

export const UploadContentText = styled.div.attrs({ 
})`
    padding: 8px 0 0;
    font-size: 12px;
    color: ${ p => p.theme.palette.colors.text };
`;

