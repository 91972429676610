import { useCallback, useEffect, useMemo, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister, valid } from "utils";
import { toast } from "react-toastify";
import { formMaterial } from "utils/forms";

import { Create, ReadOne, Update, Delete } from "services/materials";

import { Read as ReadSubservices } from "services/sub-services";
import { Read as ReadServices } from "services/services";

export default function useController(){   

    const history = useHistory(); 
    const back = () => { history.goBack() ;}
    const navigate = to => history.push(`/${ to }`); 

    const { id } = useParams()

    const refForm = useRef()
    
    const [register, setRegister] = useState(null)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    const actions = [
        { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Salvar', action:() => save(), loadable:true }
    ]

    const [optionsService, setOptionsService] = useState([])
    const [optionsSubServices, setOptionsSubServices] = useState([])

    const formData = useMemo(() => {
        return [
            ...formMaterial?.map(m => m?.ref === 'service' ? ({
                ...m,
                options: optionsService
            }) : m )?.map(m => m?.ref === 'sub_service' ? ({
                ...m,
                options: optionsSubServices
            }) : m )
        ]
    }, [optionsService, optionsSubServices])
  
    const init = useCallback(async () => {
        setLoading(true)

        const resSubservice = await ReadSubservices()
        const subservices = resSubservice?.data?.length ? normalizeStrapiList(resSubservice)?.map(m => ({ ...m, title: m?.name })) : []
        setOptionsSubServices(subservices)
        
        const resServices = await ReadServices()
        const services = resServices?.data?.length ? normalizeStrapiList(resServices)?.map(m => ({ ...m, title: m?.name })) : []
        setOptionsService(services) 

        if(id){
            const result = await ReadOne(id)
            if(result?.data?.id){
                const normalResult = normalizeStrapiRegister(result)
                setRegister({
                    ...normalResult,
                    service: normalResult?.service?.id,
                    sub_service: normalResult?.sub_service?.id,
                })
            }
        }
        setLoading(false)
    }, [id])

    const save = async () => {
        const form = refForm.current.getForm()
        
        const payload = {
            ...form,
        }

        delete payload.id

        if(!valid(payload, formMaterial)){ toast.error("Preencha todos os campos"); return ;}

        setSaving(true)
        const result = id ? await Update({data:payload}, id) : await Create({data:payload})

        if(result && !exposeStrapiError(result)){            
            toast.success("Salvo")
            history.goBack()
        } else {
            toast.error("Erro ao salvar, tente novamente mais tarde")
        }

        setSaving(false)
    }

    const remove = async row => {
        setLoading(true) 
        const result = await Delete(row?.id)
        if(result && !exposeStrapiError(result)){
            toast.success('Removido com sucesso') ;
        }
        history.goBack();
    }

    useEffect(() => {  init() ;} , [id, init])

    return {
        back,
        navigate,

        refForm,
        
        register,
        loading,
        saving,
        
        formData,

        actions,
        remove, 
        id
    }
}