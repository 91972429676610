import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CheckContainer, EmptyMessage, Load, LoadCenter, WhiteBg } from 'ui/styled';
import { theme } from 'ui/theme-color';
import Check from '../Check';

const StyledTableCell = styled(TableCell)(({ theme, blue }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: blue ? theme.palette.primary.main : theme.palette.colors.backgroundcard,
      color: blue ? theme.palette.colors.white : theme.palette.primary.main,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

export default function BasicTable({ preColumns, columns, rows, columnsAfterRows, loading, blue, noHeader, selectable, selected:pselected, single, centred }) {

  const [selected, setSelected] = React.useState(pselected || [])

  const isSelected = item => {
    return selected?.map(m => m?.id)?.includes(item?.id)
  }

  const toggleSelected = item => {
    if(single){
      setSelected( isSelected(item) ? null : [ item ] )
      return;
    }
    setSelected( isSelected(item) ? [ ...selected ]?.filter(f => f?.id !== item?.id ) : [ ...selected, item ] )
  }

  const toggleAll = () => {
      setSelected( selected?.length === rows?.length ? [] : rows )
  }

  React.useEffect(() => {
    if(typeof selectable === 'function'){
      selectable(selected)
    }
  }, [selected, selectable])

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 150 }} aria-label="simple table">



        {
          noHeader ? null : 
          <TableHead>





 
            { 

              !preColumns?.length ? null : 
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}  > 
                    {
                        preColumns?.map((item, key) => 
                            <TableCell key={key} align={ key === 0 ? "left" :  "right" } style={!item?.color ? null : { background: theme.palette.colors[item?.color] } } >
                                { item?.['prerenderCell'] && item.prerenderCell()  }
                            </TableCell>
                        )
                    }
                </TableRow>
            } 



            <TableRow>
                {
                    !!selectable ? 
                      <StyledTableCell align={"center" } blue={blue}>
                        {
                          single ? null :
                          <WhiteBg>
                            <CheckContainer>
                              <Check checked={ selected?.length === rows?.length } onChange={() => toggleAll()} />
                            </CheckContainer>
                          </WhiteBg>
                        }
                      </StyledTableCell>
                    : null
              }
              {
                  columns?.map((item, key) => 
                      <StyledTableCell key={key} blue={blue} align={ centred ? "center" : (key === 0 ? "left" : "right") } >{ item.title }</StyledTableCell>
                  )
              }
              {
                  columnsAfterRows?.map((item, key) => 
                      <StyledTableCell key={key} blue={blue} align={key === 0 ? "left" : "right" } >
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 150 }} aria-label="simple table">
                              <TableHead>
                                  <TableRow>
                                    {
                                      item?.columns?.map((i, k) => 
                                        <StyledTableCell key={`${k}`+key} blue={blue} align={k === 0 ? "left" : "right" } >{ i.title }</StyledTableCell>
                                      )
                                    }
                                  </TableRow>
                              </TableHead>
                          </Table>
                        </TableContainer>
                      </StyledTableCell>
                  )
              }
            </TableRow>
          </TableHead>
        }
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              style={row?.onClick ? { cursor: "pointer"} : null}
              onClick={row?.onClick}
            >
                {
                    !!selectable ? 
                      <TableCell align={"center" } >
                        <WhiteBg>
                          <CheckContainer>
                            <Check checked={isSelected(row)} onChange={() => toggleSelected(row)} />
                          </CheckContainer>
                        </WhiteBg>
                      </TableCell>
                    : null
                }
                {
                    columns?.map((item, key) => 
                        <TableCell key={key} align={ key === 0 ? "left" :  "right" } style={!item?.color ? null : { background: theme.palette.colors[item?.color] } } >
                            { item?.['renderCell'] ? item.renderCell({row}) : row?.[item.ref] }
                        </TableCell>
                    )
                }
                {
                    columnsAfterRows?.map((item, key) => 
                        <TableCell key={key} align={ key === 0 ? "left" :  "right" } style={!item?.color ? null : { background: theme.palette.colors[item?.color] } } >
                            { item?.['renderCell'] ? item.renderCell({row}) : row?.[item.ref] }
                        </TableCell>
                    )
                }
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {
        !loading ? <>
          { rows?.length ? null : <EmptyMessage>Nenhum registro encontrado</EmptyMessage> }
        </> : 
          <LoadCenter>
            <Load />
          </LoadCenter> 
      }
    </TableContainer>
  );
}