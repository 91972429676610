import { useCallback, useEffect, useMemo, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister, numerize, valid } from "utils";
import { toast } from "react-toastify";
import { formPersonalData } from "utils/forms";

import { Create, ReadOne, Update } from "services/constructions";
import { Read as ReadChrono } from "services/chronograms";
import { ReadAddressesByZipCode } from "services/api";
import { CommomDay, CommomDayDecoration } from "./styled";
import { parseCurrency, parseDatestringUSBR } from "utils/parsers";
import moment from "moment/moment";

export default function useController(){   

    const history = useHistory(); 
    const back = () => { history.goBack() ;}
    const navigate = to => history.push(`/${ to }`); 

    const { id } = useParams()

    const refForm = useRef() 
     
    const [register, setRegister] = useState(null)
    const [loading, setLoading] = useState(false) 
    
    const [active, setActive] = useState(0)

    const columns = [
        { title:'Tarefa', ref:'task' },
        { title:'Responsável', ref:'responsible' },
        { title:'Progresso', ref:'progress' },
        { title:'Data início', ref:'date_begin' },
        { title:'Data término', ref:'date_end' },
        { title:'Dias', ref:'days' },
        { title:'Verba', ref:'budget' }
    ] 

    const columnsMonth = [
        1,2,3,4,5,6,7,8,9,10,
        11,12,13,14,15,16,17,18,19,20,
        21,22,23,24,25,26,27,28,29,30,
        31
    ].map(day => (
        { 
            title: numerize(day), 
            renderCell: ({ row }) => <>
                <CommomDay>
                    <CommomDayDecoration active={day > row?.begins && day < (row?.begins+7)} sub={day < (row?.begins+2)} />
                </CommomDay>
            </>
        }
    ))
    // [
    //     { title:'Tarefa', ref:'task' },
    //     { title:'Responsável', ref:'responsible' },
    //     { title:'Progresso', ref:'progress' },
    //     { title:'Data início', ref:'date_begin' },
    //     { title:'Data término', ref:'date_end' },
    //     { title:'Dias', ref:'days' },
    //     { title:'Verba', ref:'budget' }
    // ] 

    const [rows, setRows] = useState([ ])
    const [rowsTwo, setRowsTwo] = useState([ ])
    const [rowsThree, setRowsThree] = useState([ ])

    const actions = [
        // { title:'Imprimir', outline:true, icon:'print', primary:true, action:() => null },
        // { title:'Baixar', outline:true, icon:'download', primary:true, action:() => null },
        // { title:'Cancelar', outline:true, action:() => history.goBack() },
        // { title:'Salvar', action:() => save(), loadable:true }
    ]

    const formActions = [
        // { title:'Cancelar', outline:true, action:() => history.goBack() }, 
        // { title:'Salvar', action:() => history.goBack(), loadable:true }
    ]
 
    const parseRow = item => {

        const cTask = item?.tasks?.[0]

        return ({ 
            task: cTask?.title,
            responsible: cTask?.responsible,
            progress: `${cTask?.progress}%`,
            date_begin: parseDatestringUSBR(cTask?.date_start),
            date_end:  parseDatestringUSBR(cTask?.date_end),
            days:`${ numerize(cTask?.days) } dias`,
            budget: parseCurrency(cTask?.value || 0),
            begins: parseInt( moment(cTask?.date_start)?.format('DD') ),
            ends: parseInt( moment(cTask?.date_ends)?.format('DD') ),
        })
    }
    
    const init = useCallback(async () => {
        setLoading(true)
        const result = await ReadOne(id)
        if(result?.data?.id){
            const normalResult = normalizeStrapiRegister(result)
            console.log("normalResult", normalResult)
            setRegister({ ...normalResult, customer: normalResult?.customer?.name })


            const resultChrono = await ReadChrono(id)
            const normalChrono = normalizeStrapiList(resultChrono)
            
            console.log("resultChrono", normalChrono)
            
            setRows(normalChrono.map(parseRow))

        }
        setLoading(false)
    }, [id])
    
    useEffect(() => { if(id){ init() ;} }, [id, init])

    return {
        refForm, 
        register,
        loading, 

        actions,

        active, 
        setActive, 

        columns,
        rows,
        formActions,

        rowsTwo,
        rowsThree,

        columnsMonth
    }
}