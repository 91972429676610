import React from "react";  

import { 
    FormActions, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import { formCommomAddress, formPersonalDataUser } from "utils/forms";
import useController from "./controller";

export default function DashboardFormsUserData(){   
    

    const {
        refForm,
        refFormAddress,
        
        fillAddress,
        register,
        loading,
        saving,

        actions,
    } = useController()



    const formPersonalDataUser = [
        
    ]


    return ( 
        <>
        <ContainerAuthenticated page={0}>
            <DashboardCardWrapper backable title={"Meus Dados"} actions={actions} loading={loading}>

                <DashboardCardWrapper closeable title={"Dados pessoais"} white>
                    <DashboardFormsCore formItems={formPersonalDataUser} register={register} ref={refForm} />
                </DashboardCardWrapper>
                
               <DashboardCardWrapper closeable title={"Endereço"} white>
                    <DashboardFormsCore formItems={formCommomAddress} register={register} ref={refFormAddress} />
                </DashboardCardWrapper>
                
                <FormActions> 
                    <DashboardActions actions={actions} loading={saving} />
                </FormActions>

            </DashboardCardWrapper>
        </ContainerAuthenticated>
        </>
    );
}