import React, { useContext, useEffect, useRef, useState } from "react";

import { 
    ModalBody, 
    ModalCard, 
    ModalHeader, 
    ModalHeaderActions, 
    ModalHeaderIconClose, 
    ModalHeaderTitle, 
    PhotoContent, 
    UploadContent, 
    UploadContentIcon 
} from "./styled";

import ModalWrapper from "../Wrapper";

import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import { ButtonContent } from "ui/styled";

import { exposeStrapiError, parseStrapiDate, parseStrapiImage } from "utils";

import FormCore from "components/Dashboard/FormCore";
import UploadFile from "components/Form/UploadFile";
import { Create } from "services/daily-photos";
import { toast } from "react-toastify";
import { optionsDailyPhotoCategory } from "utils/options";
import { parseDatestringBRUS } from "utils/parsers";

export default function ModalDailyPhoto(){

    const { modal, setModal } = useContext(CoreContext)

    const [loading, setLoading] = useState(false)

    const refForm = useRef() 
     
    const [register, setRegister] = useState(null)
    const [photo, setPhoto] = useState(null)

    const PhotoForm = [
        {
            placeholder: "Data",
            ref: "date",
            mask: "99/99/9999"
        },
        {
            placeholder: "Categoria",
            ref: "category",
            options: optionsDailyPhotoCategory
        },
        {
            placeholder: "Fotos da obra",
            custom: ({ row }) => <>
                <UploadFile onChange={setPhoto}>
                    {
                        photo ? <PhotoContent photo={photo?.url ? parseStrapiImage(photo?.url) : null }></PhotoContent> :
                        <UploadContent>
                            <UploadContentIcon />
                            Fotos da obra
                        </UploadContent>
                    }
                </UploadFile>
            </>
        },
    ]
 
    const close = () => {
        setModal(null)
    }

    const save = async () => {
        setLoading(true)
        const form = refForm?.current?.getForm()

        const payload = {
            construction: modal?.construction,
            image: photo?.id,
            title: form?.title,
            category: form?.category,
            date: parseDatestringBRUS(form?.date),
        }

        console.log("PAYLOAD", payload)

        const saved = await Create({ data:payload })

        if( saved && !exposeStrapiError(saved)){
            toast.success("Salvo com sucesso")
            await modal?.init()
            close()
        }
        
        setLoading(false)
    } 
    const confirm = async () => {
        await save()
    } 
    
    return (
        <>
            <ModalWrapper>
                <ModalCard>
                    <ModalHeader>
                        <ModalHeaderTitle>Insira fotos da obra</ModalHeaderTitle>
                        <ModalHeaderIconClose onClick={close} />
                    </ModalHeader> 
                    <ModalBody>
                        <FormCore formItems={[ ...PhotoForm]} register={register} ref={refForm}  />
                    </ModalBody>
                    <ModalHeaderActions>
                        <ButtonContent>
                            <Button secondary outline onClick={close}>Cancelar</Button>
                        </ButtonContent>
                        <ButtonContent>
                            <Button secondary loading={loading} onClick={confirm}>Enviar</Button>
                        </ButtonContent>
                    </ModalHeaderActions>
                </ModalCard>
            </ModalWrapper>
        </>
    )
}