import { useCallback, useEffect, useMemo, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister, valid } from "utils";
import { toast } from "react-toastify";
import { formConstructions } from "utils/forms";

import { Create, ReadOne, Update, Delete } from "services/constructions";
import { Read as ReadCustomer } from "services/customers";
import { Read as ReadArchitect } from "services/architects";
import { parseDatestringBRUS } from "utils/parsers";
import moment from "moment/moment";
import { PostContractFile } from "services/api";

export default function useController(){   

    const history = useHistory(); 
    const back = () => { history.goBack() ;}
    const navigate = to => history.push(`/${ to }`); 


    const { id } = useParams()

    const refForm = useRef()
    
    const [register, setRegister] = useState(null)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    
    const [fileContract, setFileContract] = useState(null)
    const [fileAddendum, setFileAddendum] = useState(null)

    const actions = [
        { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Salvar', action:() => save(), loadable:true }
    ]
    
    const [optionsCustomers, setOptionsCustomers] = useState([])
    const [optionsUsers, setOptionsUsers] = useState([])
    
    const [contracts, setContracts] = useState([])
    const [addendum, setAddendum] = useState([])

    const handleChangeFile = ( file, type) => {
        if(type === 'contracts'){
            setContracts([ ...contracts, file ])
        }
        if(type === 'addendum'){
            setAddendum([ ...addendum, file ])
        }
    }

    const formData = useMemo(() => {
        return [
            ...formConstructions?.map(m => m?.ref === 'customer' ? ({
                ...m,
                options: optionsCustomers
            }) : m )?.map(m => m?.ref === 'architect' ? ({
                ...m,
                options: optionsUsers
            }) : m )
        ]
    }, [optionsCustomers, optionsUsers])
    
    const init = useCallback(async () => {
        setLoading(true)

        const resCustomers = await ReadCustomer()
        const customers = resCustomers?.data?.length ? normalizeStrapiList(resCustomers)?.map(m => ({ ...m, title: m?.name })) : []
        setOptionsCustomers(customers)

        const resArchitect = await ReadArchitect()
        const architects = resArchitect?.data?.length ? normalizeStrapiList(resArchitect)?.map(m => ({ ...m, title: m?.name })) : []
        setOptionsUsers(architects)

        if(id){ 
            const result = await ReadOne(id)
            if(result?.data?.id){
                const normalResult = normalizeStrapiRegister(result) 

                setRegister({
                    ...normalResult,
                    customer: normalResult?.customer?.id,
                    architect: normalResult?.architect?.id,
                    date_start: moment(normalResult?.date_start)?.format("L"),
                    date_end: moment(normalResult?.date_end)?.format("L"),
                })

                setContracts(normalizeStrapiList(normalResult?.contracts).map( m => m.file).map(normalizeStrapiRegister) || [])
                setAddendum(normalizeStrapiList(normalResult?.addendum).map( m => m.file).map(normalizeStrapiRegister) || [])
            } 
        } 

        setLoading(false)
    }, [id])

    const save = async () => {
        const form = refForm.current.getForm()
        
        const payload = {
            ...form,
            date_end: !form?.date_end ? null : parseDatestringBRUS(form?.date_end),
            date_start: !form?.date_start ? null : parseDatestringBRUS(form?.date_start),

            contracts: contracts?.map(m => ({ file:m.id })),
            addendum: addendum?.map(m => ({ file:m.id })),
        }

        delete payload.id

        // if(!valid(payload, formConstructions)){ toast.error("Preencha todos os campos"); return ;}

        setSaving(true)
        const result = id ? await Update({data:payload}, id) : await Create({data:payload})

        if(result && !exposeStrapiError(result)){            

            await uploadContractItems(result?.data?.id)

            toast.success("Salvo")
            history.goBack()
        } else {
            toast.error("Erro ao salvar, tente novamente mais tarde")
        }

        setSaving(false)
    }

    const remove = async row => {
        setLoading(true) 
        const result = await Delete(row?.id)
        if(result && !exposeStrapiError(result)){
            toast.success('Removido com sucesso') ;
        }
        history.goBack();
    }

    useEffect(() => { init()}, [id, init])

    const uploadContractItems = async (construction_id) => {
        // await PostContractFile(fileAddendum, construction_id, addendum?.[0]?.id)
        return await PostContractFile(fileContract, construction_id, contracts?.[0]?.id)
    }

    const rawAddendumFile = (f) => {
        setFileAddendum(f)
    }
    
    const rawContractFile = (f) => {
        setFileContract(f)
    }

    return {
        back,
        navigate,

        refForm,

        register,
        loading,
        saving,

        actions,
        formData,
        handleChangeFile,
        contracts,
        addendum,

        remove,
        id,

        rawAddendumFile,
        rawContractFile
    }

}