import styled from 'styled-components'  
 
export const FormTitle = styled.div.attrs({ 
})`           
    color: ${ props => props.theme.palette.primary.main }; 
    font-weight: bold;
    font-size: 20px; 

    margin: 50px 0 10px;

    ${ p => p.centred ? `
        text-align: center;
    ` : ``}
`;
export const FormText = styled.div.attrs({ 
})`           
    font-size: 15px; 
    color: ${ props => props.theme.palette.colors.grey };

    margin-bottom: 30px;

    ${ p => p.centred ? `
        text-align: center;
    ` : ``}
`;
 
export const FormSpacer = styled.div.attrs({ 
})`           
    margin-top: 40px;
`; 

export const FormSuccess = styled.div.attrs({ 
})`           
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`; 
 
export const FormSuccessIcon = styled.img.attrs({ 
    src: '/icons/success.svg'
})`           
    margin: 0 auto 20px;
`; 