import React, { useEffect, useState } from "react";

import { Delete, Read } from "services/architects";

import { ContentTableAction, ContentTableActionIconEdit, ContentTableActionIconRemove, ContentTableItem} from "./styled";

import { exposeStrapiError, normalizeStrapiList } from "utils";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";


export default function useController() {
 
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const [loading, setLoading] = useState(false) 
    const [rows, setRows] = useState([])

   
    const columns = [
        { title:'Nome do arquiteto', ref:'name' },
        { title:'Telefone', ref:'phone' },
        { title:'E-mail', ref:'email' },
        { title:'CPF / CNPJ', ref:'cpf' },
        
        { 
            title:' ', 
            renderCell: ({ row }) => <>
                <ContentTableAction>
                    <ContentTableItem  onClick={() => navigate(`dashboard/register/architects/edit/${ row.id }`)}>
                        <ContentTableActionIconEdit />
                    </ContentTableItem>
                    <ContentTableItem onClick={() => remove(row)}>
                        <ContentTableActionIconRemove />
                    </ContentTableItem>
                </ContentTableAction>
            </>
        }

    ] 

    const parseRow = (item, customers, architects) => {
        return { 
            ...item,
        }
    }

    const init = async () => {
        setLoading(true)
        const result = await Read() 
        if(result && !exposeStrapiError(result)){
            if(result?.data?.length){
                const normalResult = normalizeStrapiList(result)
                setRows(normalResult?.map(parseRow))
            }else{
                setRows([])
            }
        }
        setLoading(false)
    }

    useEffect(() => { init() ;}, [])

    const remove = async row => {
        setLoading(true) 
        const result = await Delete(row?.id)
        if(result && !exposeStrapiError(result)){
            toast.success('Removido com sucesso') ;
        }
        init()
    }
    
    return {
        loading,
        columns,
        rows,
        navigate
    }
}   