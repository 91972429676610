import PasswordValidation from "components/Form/PasswordValidation";
import { statesOptions } from "./brazil";

import { 
    optionsAddressType,
    optionsBankAccount, 
    optionsBoolean, 
    optionsBuildingContractType, 
    optionsBuildingPeriod, 
    optionsBuildingStatus, 
    optionsBuildingType, 
    optionsCivilState, 
    optionsColaboratorSector, 
    optionsContractType, 
    optionsMaterialCategory, 
    optionsMaterialSubcategory, 
    optionsPeriod, 
    optionsPersonType, 
    optionsPix, 
    optionsRate, 
    optionsStatus, 
    optionsSupplierCategory, 
    optionsUserCategory
} from "./options";


export const formPersonalData = [
    { placeholder:"Nome do usuário  (obrigatório)", ref:"name" },
    { placeholder:"Obra", ref:"construction", options:[] },
    { placeholder:"E-mail", ref:"email"},
    { placeholder:"CPF", ref:"cpf", half:true, mask:"999.999.999-99"  },
    { placeholder:"Categoria do usuário", ref:"category", half:true, options:optionsUserCategory  },
]

export const formPersonalDataUser = [
    { placeholder:"CPF", ref:"cpf", mask:"999.999.999-99"  },
    { placeholder:"Nome do usuário  (obrigatório)", ref:"name" },
    { placeholder:"Categoria do usuário", ref:"category", options:optionsUserCategory  },

    { placeholder:"E-mail", ref:"email", quarter:true },
    { placeholder:"DDD+Celular", ref:"phone", quarter:true },
    { placeholder:"Cargo", ref:"jobrole", quarter:true },
    { placeholder:"Setor (Opcional)", ref:"sector", quarter:true },
]

export const formPersonalDataCustomer = [
    {
        placeholder: "Pessoa física",
        ref: "physical_person",
        type: "select-radio",
        full: true,
        options: optionsPersonType
    },

    { placeholder:"Nome do usuário  (obrigatório)", ref:"name", quarter:true },
    { placeholder:"Data de nascimento", ref:"birthDate", mask:"99/99/9999", quarter:true },
    { placeholder:"Profissão", ref:"profession", quarter:true },
    { placeholder:"Estado civil", ref:"marital_status", options:optionsCivilState, quarter:true },

    { placeholder:"Nome da obra (obrigatório)", ref:"build_name" },
    { placeholder:"CPF (obrigatório)", ref:"cpf", mask:"999.999.999-99"  },
    { placeholder:"RG (obrigatório)", ref:"rg"  },
]

export const formPersonalDataArchitect = [
    {
        placeholder: "Pessoa física",
        ref: "physical_person",
        type: "select-radio",
        full: true,
        options: optionsPersonType
    },

    { placeholder:"Nome do arquiteto", ref:"name" },
    { placeholder:"RG", ref:"rg"  },
    { placeholder:"CPF", ref:"cpf", mask:"999.999.999-99" },
    { placeholder:"E-mail", ref:"email", half:true },
    { placeholder:"DDD + Celular", ref:"phone", half:true, mask: "(99) 99999-9999" },
]

export const formPersonalDataSupplier = [
    {
        placeholder: "Pessoa física",
        ref: "physical_person",
        type: "select-radio",
        full: true,
        options: optionsPersonType
    },

    { placeholder:"Fornecedor", ref:"name", quarter:true },
    { placeholder:"Categoria do fornecedor (obrigatório)", ref:"category", options:optionsSupplierCategory, quarter:true },
    { placeholder:"DDD + Telefone", ref:"phone", quarter:true, mask: "(99) 99999-9999" },
    { placeholder:"Site", ref:"site", quarter:true },
    
    { placeholder:"Nome do contato", ref:"contact_name", twenty:true },
    { placeholder:"RG", ref:"rg", twenty:true },
    { placeholder:"CPF", ref:"cpf", mask:"999.999.999-99", twenty:true },
    { placeholder:"Status do fornecedor", ref:"status", options: optionsStatus, twenty:true },
    { placeholder:"Avaliação do fornecedor", ref:"evaluation", options: optionsRate, twenty:true }
]

export const formPersonalDataColaborator = [
    
    { placeholder:"Nome", ref:"name", twenty: true },
    { placeholder:"RG", ref:"rg", twenty: true },
    { placeholder:"CPF", ref:"cpf", mask:"999.999.999-99", twenty: true },
    { placeholder:"CTPS", ref:"ctps", twenty: true },
    {
        placeholder: "Status",
        ref: "status",
        twenty: true,
        options: optionsStatus
    },

    { placeholder:"Setor (Opcional)", ref:"sector", options:[], quarter:true },

    { placeholder:"Cargo", ref:"office", quarter:true },
    { placeholder:"E-mail", ref:"email", quarter:true },
    { placeholder:"DDD + Celular", ref:"phone", quarter:true, mask: "(99) 99999-9999" },

    { separator:true },
    
    { placeholder:"Telefone emergencial", ref:"emergencial_phone", mask: "(99) 99999-9999", half:true },
    { placeholder:"Nome do contato emergencial", ref:"emergencial_name", half:true },
    
    { separator:true },
    
    { placeholder:"Inicio do contrato", ref:"date_start", mask:"99/99/9999" },
    { placeholder:"Término do contrato", ref:"date_end", mask:"99/99/9999" },
    { placeholder:"Salário base", ref:"base_salary", mask:"99999999" },

]

export const formCommomAddress = [
    {
        placeholder: "CEP (obrigatório)",
        ref: "zipcode",
        mask: "99999-999"
    },
    {
        placeholder: "Endereço (obrigatório)",
        ref: "street"
    },
    {
        placeholder: "Número (obrigatório)",
        ref: "number",
    },
    
    {
        placeholder: "Complementos (obrigatório)",
        ref: "complement",
        quarter: true
    },
    {
        placeholder: "Estado (obrigatório)",
        ref: "state",
        options: statesOptions,
        quarter: true
    },
    {
        placeholder: "Cidade (obrigatório)",
        ref: "city",
        quarter: true
    },
    {
        placeholder: "Bairro (obrigatório)",
        ref: "neighborhood",
        quarter: true
    },
]

export const formAddress = [
    {
        full: true,
        type: "select-radio",
        placeholder: "Tipo de endereço",
        ref: "type",
        options: optionsAddressType
    },
    ...formCommomAddress
]

export const formContacts = [
    {
        placeholder: "DDD + Celular (obrigatório)",
        ref: "phone",
        mask: "(99) 99999-9999",
        quarter: true,
    },
    {
        placeholder: "DDD + Telefone",
        ref: "phone_fixed",
        mask: "(99) 99999-9999",
        quarter: true,
    },
    {
        placeholder: "DDD + Telefone comercial",
        ref: "phone_commercial",
        mask: "(99) 99999-9999",
        quarter: true,
    },
    {
        placeholder: "E-mail (obrigatório)",
        ref: "email",
        quarter: true,
    },

    { separator:true },

    {
        placeholder: "Observações",
        ref: "details",
        full: true
    },
]

export const formContactsArchitect = [
    {
        placeholder: "Contato",
        ref: "name",
    },
    {
        placeholder: "E-mail",
        ref: "email",
    },
    {
        placeholder: "DDD + Telefone",
        ref: "phone",
        mask: "(99) 99999-9999",
    },
    
]

export const formConstructions = [
    {
        placeholder: "Nome do cliente",
        ref: "customer",
        quarter:true,
        options: []
    },
    {
        placeholder: "Nome da obra",
        ref: "name",
        quarter:true
    },
    {
        placeholder: "Tipo de obra",
        ref: "type",
        quarter:true,
        options: optionsBuildingType
    },
    {
        placeholder: "Tipo de contrato",
        ref: "type_contract",
        quarter: true,
        options: optionsBuildingContractType
    },
    {
        placeholder: "Período",
        ref: "period",
        quarter: true,
        options: optionsBuildingPeriod
    },
    {
        placeholder: "Engenheiro",
        ref: "engineer",
        quarter: true
    },
    {
        placeholder: "Arquiteto",
        ref: "architect",
        quarter: true,
        options: []
    },
    {
        placeholder: "Encarregado",
        ref: "in_charge_name",
        quarter:true
    },
    {
        placeholder: "Data de início",
        ref: "date_start",
        quarter:true,
        mask: "99/99/9999"
    },
    {
        placeholder: "Data término",
        ref: "date_end",
        quarter:true,
        mask: "99/99/9999"
    },
    {
        placeholder: "Valor total",
        ref: "total",
        quarter:true,
        mask: "999999999"
    },
    {
        placeholder: "Status",
        ref: "status",
        quarter:true,
        options: optionsBuildingStatus
    }
]

export const formSupplier = [
    {
        placeholder: "Pessoa física",
        ref: "person_type",
        type: "select-radio",
        full: true,
        options: optionsPersonType
    },

    {
        placeholder: "Fornecedor",
        ref: "supplier_type",
        quarter: true
    },
    {
        placeholder: "Categoria do fornecedor (obrigatório)",
        ref: "supplier_category",
        options: optionsSupplierCategory,
        quarter: true
    },
    {
        placeholder: "DDD + Telefone",
        ref: "phone",
        mask: "(99) 99999-9999",
        quarter: true
    },
    {
        placeholder: "Site",
        ref: "website",
        quarter: true
    },
    
    {
        placeholder: "Nome do contato",
        ref: "contact_name",
        twenty: true
    },
    {
        placeholder: "RG",
        ref: "rg",
        twenty: true
    },
    {
        placeholder: "CPF",
        ref: "cpf",
        mask: "999.999.999-99",
        twenty: true
    },
    {
        placeholder: "Status do fornecedor",
        ref: "supplier_status",
        twenty: true,
        options: optionsStatus
    },
    {
        placeholder: "Avaliação do fornecedor",
        ref: "supplier_rate",
        twenty: true,
        options: optionsRate
    },
 
]


// to all bank form variations

const formBankPJ = [
    {
        placeholder: "Aceita cartão de crédito?",
        ref: "accepts_card",
        type: "select-radio",
        options: optionsBoolean
    },
    {
        placeholder: "Emite boleto?",
        ref: "issue_bill",
        type: "select-radio",
        options: optionsBoolean
    },
    {
        placeholder: "Emite nota fiscal?",
        ref: "issue_invoice",
        type: "select-radio",
        options: optionsBoolean
    },
]

const formBankObservations = [
    {
        placeholder: "Observações",
        ref: "notes",
        full: true
    },
]

const formBankPix = [

    {
        placeholder: "Tipo de chave pix",
        ref: "type_pix",
        half: true,
        options: optionsPix
    },
    {
        placeholder: "Chave pix",
        ref: "pix",
        half: true
    },

]

const formBankAccount = [
    {
        placeholder: "Código do banco",
        ref: "code",
        quarter: true
    },
    {
        placeholder: "Agência",
        ref: "agency",
        quarter: true
    },
    {
        placeholder: "Número da conta",
        ref: "account",
        quarter: true
    },
    {
        placeholder: "Tipo de conta",
        ref: "type",
        quarter: true,
        options: optionsBankAccount
    },
]

const formBankAccountPJ = [
    {
        placeholder: "Código do banco",
        ref: "code",
        twenty: true
    },
    {
        placeholder: "Agência",
        ref: "agency",
        twenty: true
    },
    {
        placeholder: "Número da conta",
        ref: "account",
        twenty: true
    },
    {
        placeholder: "Tipo de conta",
        ref: "type",
        twenty: true,
        options: optionsBankAccount
    },
    {
        placeholder: "PF ou PJ",
        ref: "type_account",
        twenty: true,
        options: optionsPersonType?.map( m => ({ ...m, title: m?.acron }))
    },
]

const formBankCommomPJ = [
    ...formBankAccountPJ,
    { separator:true },
    ...formBankPix
]

const formBankCommom = [
    ...formBankAccount,
    { separator:true },
    ...formBankPix
]


// adding visibility

export const formBank = [
    ...formBankCommom,
    { separator:true },
    ...formBankObservations
]

export const formBankColaborators = [
    ...formBankCommomPJ,
    { separator:true },
    ...formBankObservations
]

export const formBankSupplier = [
    ...formBankCommom,
    { separator:true },
    ...formBankPJ
]

//




export const formMaterial = [
    {
        placeholder: "Categoria do material / Serviço",
        ref: "service",
        half: true,
        options: []
    },
    {
        placeholder: "Subcategoria",
        ref: "sub_service",
        half: true,
        options: []
    },
    {
        placeholder: "Descrição",
        ref: "details",
        full: true
    },
]

export const formJuridic = [
    {
        placeholder: "Razão social",
        ref: "corporate_reason",
        half: true
    },
    {
        placeholder: "CNPJ",
        ref: "cnpj",
        mask: "99.999.999/9999-99",
        half: true
    },
]

export const formPassword = [
    {
        placeholder: "Nova senha",
        ref: "password",
        type:"password",
    },
    { custom: <></> },
    { custom: <></> },
    {
        placeholder: "Repita a nova senha",
        ref: "cpassword",
        type:"password"
    },
    { custom: <></> },
    { custom: <></> },
    { custom: <>
        <PasswordValidation />
    </> },
]



export const formContractBuilding = [
    {
        placeholder: "Nome do cliente",
        ref: "customer"
    },
    {
        placeholder: "Nome da obra",
        ref: "name"
    },
    {
        placeholder: "Tipo de obra",
        ref: "type",
        options: optionsBuildingType
    },
]

export const formBuyRequest = [
    {
        placeholder: "Nome da obra",
        ref: "name",
        quarter: true
    },
    {
        placeholder: "Data de entrega do material",
        ref: "date",
        mask: "99/99/9999",
        quarter: true
    },
    {
        placeholder: "Número da Ordem de compra",
        ref: "order_number",
        quarter: true
    },
    {
        placeholder: "Solicitante",
        ref: "requester",
        quarter: true
    },
]

export const formMapConcurrence = [
    {
        placeholder: "Serviço",
        ref: "service"
    },
    {
        placeholder: "Data",
        ref: "date",
        mask: "99/99/9999"
    },
    {
        placeholder: "Emissor",
        ref: "emissor"
    },
]



export const formBuyOrder = [
    {
        placeholder: "Cliente",
        ref: "customer",
        quarter: true
    },
    {
        placeholder: "Obra",
        ref: "construction",
        quarter: true
    },
    {
        placeholder: "Data do contrato",
        ref: "date",
        mask: "99/99/9999",
        quarter: true
    },
    {
        placeholder: "Número da Ordem de compra",
        ref: "buyOrder",
        quarter: true
    },
    { separator:true },
    ...formAddress,
    { separator:true },
    {
        options: optionsPersonType,
        placeholder: "RG / CNPJ",
        ref: "rgCnpj"
    },
    {
        placeholder: "CPF / CNPJ",
        ref: "cpfCnpj",
    },
    {
        placeholder: "Arquitetura",
        ref: "architecture"
    },
    {
        placeholder: "Contato",
        ref: "contact"
    },
    {
        placeholder: "DDD + Telefone",
        ref: "phone",
        mask: "(99) 99999-9999",
    },
    {
        placeholder: "E-mail",
        ref: "email"
    },

]

export const formBuyOrderDelivery = [
    ...formAddress.map(m => ({ ...m, ref: `delivery_${m.ref}`})),
    { separator:true },
    {
        placeholder: "Engenheiro responsável",
        ref: "responsible_engineer"
    },
    {
        placeholder: "CNO da Obra",
        ref: "cno"
    },
    {
        placeholder: "Responsável pela obra / Entrega",
        ref: "responsible_construction"
    },
]


export const formBuyOrderPayment = [
    ...formAddress.map(m => ({ ...m, ref: `payment_${m.ref}`})),
    { separator:true },
    {
        placeholder: "DDD + Telefone",
        ref: "payment_phone",
        mask: "(99) 99999-9999",
    },
    {
        placeholder: "Contato",
        ref: "payment_contact"
    },
    {
        placeholder: "Email",
        ref: "payment_email"
    },
]


export const formBuyOrderSupply = [
    ...formSupplier,
    { separator:true },
    // ...formAddress.map(m => ({ ...m, ref: `supplier_${m.ref}`})),
    // { separator:true },
    {
        placeholder: "Orçamento número",
        ref: "orderNumber",
        quarter: true
    },
    {
        placeholder: "DDD + Telefone",
        ref: "supplier_phone",
        mask: "(99) 99999-9999",
        quarter: true
    },
    {
        placeholder: "Contato",
        ref: "supplier_contact",
        quarter: true
    },
    {
        placeholder: "Email",
        ref: "supplier_email",
        quarter: true
    },
    { separator:true },


    {
        placeholder: "Nota fiscal",
        ref: "payment_invoice",
        options: optionsBoolean,
        quarter: true
    },
    {
        placeholder: "Retenção de Nota fiscal",
        ref: "payment_retain_invoice",
        options: optionsBoolean,
        quarter: true
    },
    {
        placeholder: "Emite boleto",
        ref: "payment_emit_invoice",
        options: optionsBoolean,
        quarter: true
    },
    {
        placeholder: "Cartão de crédito",
        ref: "payment_card",
        options: optionsBoolean,
        quarter: true
    },

    { separator:true },
    {
        placeholder: "Chave pix",
        ref: "payment_pix_key",
        half: true
    },
    {
        placeholder: "Tipo de chave pix",
        ref: "payment_type_pix_key",
        options: optionsPix,
        half: true
    },
]


export const formSupplyCondition = [
    {
        placeholder: "Prazo de entrega",
        ref: "delivery_deadline"
    },
    {
        placeholder: "forma de pagamento",
        ref: "payment_type"
    },
    {
        placeholder: "Forma de faturamento",
        ref: "invoice_type"
    },
]


export const formBuyOrderData = [
    {
        placeholder: "Data da medição",
        ref: "medition_date"
    },
    {
        placeholder: "Data de recebimento",
        ref: "receivement_date"
    },
    {
        placeholder: "Nota fiscal",
        ref: "invoice_number"
    },
]

export const formSupplierData = [
    {
        placeholder: "Razão social",
        ref: "supplier_name",
        twenty: true
    },
    {
        placeholder: "Nome fantasia",
        ref: "supplier_fantasyname",
        twenty: true
    },
    {
        placeholder: "CPF / CNPJ",
        ref: "supplier_document",
        twenty: true
    },
    {
        placeholder: "RG / IE",
        ref: "supplier_rg",
        twenty: true
    },
    {
        placeholder: "DDD + Telefone",
        ref: "supplier_phone",
        twenty: true
    },
]



export const formPaymentData = [
    {
        custom: "Dados de pagamento - TED",
        full: true
    },
    {
        placeholder: "Código do banco",
        ref: "ted_bank_code",
        quarter: true
    },
    {
        placeholder: "Agência",
        ref: "ted_account_agency",
        quarter: true
    },
    {
        placeholder: "Número da conta",
        ref: "ted_account_number",
        quarter: true
    },
    {
        placeholder: "Tipo de conta",
        ref: "ted_account_type",
        quarter: true
    },
    { separator:true },
    
    {
        custom: "Dados de pagamento - PIX",
        full: true
    },
    {
        placeholder: "Tipo de chave pix",
        ref: "pix_key_type",
        half: true
    },
    {
        placeholder: "Chave pix",
        ref: "pix_key",
        half: true
    },
    { separator:true },
    
    {
        custom: "Boleto bancário",
        full: true
    },
    {
        placeholder: "Boleto bancário",
        ref: "bank_invoice",
        options:optionsBoolean,
        half: true
    },
]

export const formPaymentInvoice = [
    {
        placeholder: "Nota fiscal",
        ref: "invoice_invoice",
        options:optionsBoolean,
        half: true
    },
    {
        placeholder: "Retenção de Nota fiscal",
        ref: "invoice_retention",
        options:optionsBoolean,
        half: true
    },
]

export const formSupplimentCondition = [
    {
        placeholder: "Ordem de compra",
        ref: "suppliement_order"
    },
    {
        placeholder: "Forma de pagamento",
        ref: "suppliement_payment"
    },
    {
        placeholder: "Forma de faturamento",
        ref: "suppliement_invoicing"
    },
    {
        placeholder: "Observações",
        ref: "suppliement_observation",
        full: true
    },
]

export const formContractFilter = [
    {
        placeholder: "Nome da obra",
        ref: "construction_name",
        quarter: true
    },
    {
        placeholder: "Data de",
        ref: "date_from",
        quarter: true
    },
    {
        placeholder: "Data até",
        ref: "date_until",
        quarter: true
    },
]