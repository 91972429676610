import { useCallback, useEffect, useMemo, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister, valid } from "utils";
import { toast } from "react-toastify";
import { formPersonalDataColaborator } from "utils/forms";

import { Create, ReadOne, Update, Delete } from "services/collaborators";
import { ReadAddressesByZipCode } from "services/api";

import { Read as ReadSector } from "services/sectors";
import { optionsStates } from "utils/options";
import { parseDatestringBRUS } from "utils/parsers";
import moment from "moment/moment";

export default function useController(){   

    const history = useHistory(); 
    const back = () => { history.goBack() ;}
    const navigate = to => history.push(`/${ to }`); 

    const { id } = useParams()

    const refForm = useRef()
    const refFormJuridic = useRef()
    const refFormBank = useRef()
    const refFormBank2 = useRef()
    const refFormAddress = useRef()
    
    const [register, setRegister] = useState(null)
    const [registerAddress, setRegisterAddress] = useState(null)
    const [registerBank, setRegisterBank] = useState(null)
    const [registerOtherBank, setRegisterOtherBank] = useState(null)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    const actions = [
        { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Salvar', action:() => save(), loadable:true }
    ]
    
    const fillAddress = async zipCode => {
        const result = await ReadAddressesByZipCode(zipCode?.replace(/\.|\-/g,''))
        if(result){
            let dinamicAddress = {}
            if(result?.bairro){ dinamicAddress = { ...dinamicAddress, neighborhood: result?.bairro } ;}
            if(result?.uf){ dinamicAddress = { ...dinamicAddress, state: result?.uf } ;}
            if(result?.localidade){ dinamicAddress = { ...dinamicAddress, city: result?.localidade } ;}
            if(result?.logradouro){ dinamicAddress = { ...dinamicAddress, street: result?.logradouro } ;}
            if(result?.cep){ dinamicAddress = { ...dinamicAddress, zipcode: result?.cep } ;}
            setRegister({
                ...register,
                ...refForm.current.getForm(),
                ...refFormJuridic.current.getForm(),
                address:{
                    ...register?.address,
                    ...refFormAddress.current.getForm(),
                    ...dinamicAddress
                },
                bank:{
                    ...register?.bank,
                    ...refFormBank.current.getForm(),
                },
                bank2:{
                    ...register?.bank2,
                    ...refFormBank2.current.getForm(),
                },
            })
        }
    }

    const [optionsSector, setOptionsSector] = useState([])

    const formData = useMemo(() => {
        return [
            ...formPersonalDataColaborator?.map(m => m?.ref === 'sector' ? ({
                ...m,
                options: optionsSector
            }) : m )
        ]
    }, [optionsSector])
    
    const init = useCallback(async () => {
        setLoading(true)

        const resSector = await ReadSector()
        const sectors = resSector?.data?.length ? normalizeStrapiList(resSector)?.map(m => ({ ...m, title: m?.name })) : []

        setOptionsSector(sectors)
        if(id){ 
            const result = await ReadOne(id)
            if(result?.data?.id){
                const normalResult = normalizeStrapiRegister(result)

                setRegister({
                    ...normalResult,

                    sector: normalResult?.sector?.id,
                    status: normalResult?.status ? 1 : 2,

                    date_start: moment(normalResult?.date_start)?.format('L'),
                    date_end: moment(normalResult?.date_end)?.format('L'),
                })

                setRegisterAddress({ ...normalResult?.address, state: optionsStates?.find(f => f.title === normalResult?.address?.state)?.id })
                setRegisterBank({ ...normalResult?.banks?.[0] })
                setRegisterOtherBank({ ...normalResult?.banks?.[1] })

            }
        }
        setLoading(false)
    }, [id])

    const save = async () => {
        const form = refForm.current.getForm()
        const formJuridic = refFormJuridic.current.getForm()
        const formBank = refFormBank.current.getForm()
        const formBank2 = refFormBank2.current.getForm()
        const formAddress = refFormAddress.current.getForm()
        
        const payload = {
            ...form,
            status: form?.status === 1,
            ...formJuridic,

            date_start: !form?.date_start ? null : parseDatestringBRUS(form?.date_start),
            date_end: !form?.date_end ? null : parseDatestringBRUS(form?.date_end),

            address:{
                ...formAddress,
                state: optionsStates?.find(f => f.id === formAddress?.state)?.title
            },
            banks:[
                { ...formBank },
                { ...formBank2},
            ]

        }

        delete payload.id

        if(!valid(payload, formPersonalDataColaborator)){ toast.error("Preencha todos os campos"); return ;}

        setSaving(true)
        const result = id ? await Update({data:payload}, id) : await Create({data:payload})

        if(result && !exposeStrapiError(result)){            
            toast.success("Salvo")
            history.goBack()
        } else {
            toast.error("Erro ao salvar, tente novamente mais tarde")
        }

        setSaving(false)
    }

    const remove = async row => {
        setLoading(true) 
        const result = await Delete(row?.id)
        if(result && !exposeStrapiError(result)){
            toast.success('Removido com sucesso') ;
        }
        history.goBack();
    }

    useEffect(() => { init() ;} , [id, init])

    return {
        back,
        navigate,

        refForm,
        refFormJuridic,
        refFormBank,
        refFormBank2,
        refFormAddress,
        
        fillAddress,
        register,
        loading,
        saving,

        actions,

        formData,
        registerAddress,
        registerBank,
        registerOtherBank,

        remove,
        id
    }
}