import React, { useEffect, useState } from "react";

import { Delete, Read } from "services/collaborators";

import { ContentTableAction, ContentTableActionIconEdit, ContentTableActionIconRemove, ContentTableItem } from "./styled";
import { StatusItem, StatusItemContent } from "ui/styled";

import { exposeStrapiError, normalizeStrapiList } from "utils";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import {  optionsStatus } from "utils/options";

import { Read as ReadSector } from "services/sectors";

export default function useController() {
 
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const [loading, setLoading] = useState(false) 
    const [rows, setRows] = useState([])

    const columns = [
        { title:'Nome', ref:'name' },
        { title:'Setor', ref:'sector' },
        { title:'Cargo', ref:'office' },
        { title:'CPF', ref:'cpf' },
        { title:'Telefone', ref:'phone' },
        { title:'E-mail', ref:'email' },
        { 
            title:'Status',  
            renderCell: ({ row }) => <>
                <StatusItemContent>
                    <StatusItem color={row?.status_color}>
                        { row?.status }
                    </StatusItem>
                </StatusItemContent>
            </>
        },        
        { 
            title:' ', 
            renderCell: ({ row }) => <>
                <ContentTableAction>
                    <ContentTableItem  onClick={() => navigate(`dashboard/register/colaborators/edit/${ row.id }`)}>
                        <ContentTableActionIconEdit />
                    </ContentTableItem>
                    <ContentTableItem onClick={() => remove(row)}>
                        <ContentTableActionIconRemove />
                    </ContentTableItem>
                </ContentTableAction>
            </>
        }
    ] 

    const parseRow = (item, sectors) => {

        return { 
            ...item,
            sector: sectors?.find(f => f?.id === item?.sector?.data?.id)?.name,
            status: optionsStatus?.find(f => item?.status ? f.id === 1 : f.id !== 1)?.title,
            status_color: optionsStatus?.find(f => item?.status ? f.id === 1 : f.id !== 1)?.color,
        }
    }

    const init = async () => {
        setLoading(true)
        const result = await Read() 
        if(result && !exposeStrapiError(result)){
            if(result?.data?.length){
                const normalResult = normalizeStrapiList(result) 
        
                const resSector = await ReadSector()
                const sectors = resSector?.data?.length ? normalizeStrapiList(resSector)?.map(m => ({ ...m, title: m?.name })) : []

                setRows(normalResult?.map(m => parseRow(m, sectors)))
            }else{
                setRows([])
            }
        }
        setLoading(false)
    }

    useEffect(() => { init() ;}, [])

    const remove = async row => {
        setLoading(true) 
        const result = await Delete(row?.id)
        if(result && !exposeStrapiError(result)){
            toast.success('Removido com sucesso') ;
        }
        init()
    }
    
    return {
        loading,
        columns,
        rows,
        navigate
    }
}   