import React from "react";  

import {  
    CardHomeChart
} from "./styled";

import { Chart } from "react-google-charts";

import { monthsOptions } from "utils/brazil";
import DashboardCardWrapper from "../Wrapper";
import { theme } from "ui/theme-color";
import { yearOptions } from "utils";
import { Load, LoadCenter } from "ui/styled";
import { Col, Row } from "reactstrap";

export default function DashboardCardChartReport({ loading, rows }){  

    const mockValues = monthsOptions.map((m,k) => [ m?.acron , (200 * (k % 3 === 0 ? k : 2)) , (200 * (k % 3 !== 0 ? k : 2)) ]) || []
    const data = [
        ["Mês", "Crédito", "Débito"],
        ...(rows||[])?.map(m => m.chart)
    ];
      
    const options = {
        curveType: "function",
        legend: { position: "none" },
        colors:[theme.palette.colors.chart_cyan, theme.palette.colors.chart_yellow],
        backgroundColor: theme.palette.colors.backgroundColor,
        tooltip: {trigger: 'selection'},
    };


    const pizzaData = [
        ["Tipo pagamento", "Qtd."],
        ["A Pagar", (rows||[])?.filter(m => !m.payed)?.length ],
        ["Pago", (rows||[])?.filter(m => !!m.payed)?.length ],
        // [" ", 11]
    ];
    
    const pizzaOptions = {
        title: " ",
        colors:[theme.palette.colors.chart_blue, theme.palette.colors.chart_yellow, theme.palette.colors.chart_green ],
    };

    return ( 
        <>
            <DashboardCardWrapper>
                <Row>
                    <Col>
                        <CardHomeChart>
                            {
                                loading ? 
                                    <LoadCenter> <Load /> </LoadCenter>
                                : 
                                <Chart
                                    chartType="LineChart"
                                    width="100%"
                                    height="300px"
                                    data={data}
                                    options={options}
                                        />
                            }
                        </CardHomeChart>
                    </Col>
                    <Col>
                        <CardHomeChart>
                            {
                                loading ? 
                                    <LoadCenter> <Load /> </LoadCenter>
                                : 
                                <Chart
                                    chartType="PieChart"
                                    width="100%"
                                    height="300px"
                                    data={pizzaData}
                                    options={pizzaOptions}
                                />
                            }
                        </CardHomeChart>
                    </Col>
                </Row>
            </DashboardCardWrapper>
        </>
    );
}