import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


import NotFound from 'screens/NotFound' 

import Login from 'screens/Authentication/Login' 
import Register from 'screens/Authentication/Register' 
import Forgot from 'screens/Authentication/Forgot' 
import CreatePassword from 'screens/Authentication/CreatePassword' 

import DashboardHome from 'screens/Dashboard/Home' 
import DashboardMe from 'screens/Dashboard/Me' 

////

import DashboardListUsers from "screens/Dashboard/List/Register/Users";
import DashboardListCustomers from "screens/Dashboard/List/Register/Customers";
import DashboardListMaterials from "screens/Dashboard/List/Register/Materials";
import DashboardListSuppliers from "screens/Dashboard/List/Register/Suppliers";
import DashboardListConstructions from "screens/Dashboard/List/Register/Constructions";
import DashboardListArchitects from "screens/Dashboard/List/Register/Architects";
import DashboardListColaborators from "screens/Dashboard/List/Register/Colaborators";

import DashboardListContracts from "screens/Dashboard/List/Construction/Contracts";
import DashboardListRequestMaterial from "screens/Dashboard/List/Construction/RequestMaterial";
import DashboardListRequestService from "screens/Dashboard/List/Construction/RequestService";
import DashboardListMap from "screens/Dashboard/List/Construction/Map";
import DashboardListOrders from "screens/Dashboard/List/Construction/Orders";
import DashboardListMeasurements from "screens/Dashboard/List/Construction/Measurements";
import DashboardListPayments from "screens/Dashboard/List/Construction/Payments";
import DashboardListTimeline from "screens/Dashboard/List/Construction/Timeline";
import DashboardListDaily from "screens/Dashboard/List/Construction/Daily";

import DashboardListSupplyOrders from "screens/Dashboard/List/Supply/Orders";
import DashboardListSupplyMap from "screens/Dashboard/List/Supply/Map";
import DashboardListRequestSupplyService from "screens/Dashboard/List/Supply/RequestService";
import DashboardListRequestSupplyMaterial from "screens/Dashboard/List/Supply/RequestMaterial";
import DashboardListSupplyContracts from "screens/Dashboard/List/Supply/Contracts";

import DashboardListFinanceMeasurements from "screens/Dashboard/List/Finance/Measurements";
import DashboardListFinancePayments from "screens/Dashboard/List/Finance/Payments";
import DashboardListFinanceControl from "screens/Dashboard/List/Finance/Control";

import DashboardListMaintainContracts from "screens/Dashboard/List/Maintain/Contracts";
import DashboardListMaintainRequestMaterial from "screens/Dashboard/List/Maintain/RequestMaterial";
import DashboardListMaintainRequestService from "screens/Dashboard/List/Maintain/RequestService";
import DashboardListMaintainMap from "screens/Dashboard/List/Maintain/Map";
import DashboardListMaintainOrders from "screens/Dashboard/List/Maintain/Orders";
import DashboardListMaintainPayments from "screens/Dashboard/List/Maintain/Payments";
import DashboardListMaintainTimeline from "screens/Dashboard/List/Maintain/Timeline";
import DashboardListMaintainDaily from "screens/Dashboard/List/Maintain/Daily";
import DashboardListMaintainControl from "screens/Dashboard/List/Maintain/Control";
import DashboardListMaintainManual from "screens/Dashboard/List/Maintain/Manual";

import DashboardListReportCenter from "screens/Dashboard/List/Report/Center";
import DashboardListReportAccounts from "screens/Dashboard/List/Report/Accounts";
import DashboardListReportCosts from "screens/Dashboard/List/Report/Cost";
import DashboardListReportRevenues from "screens/Dashboard/List/Revenues";
import DashboardListReportMoviment from "screens/Dashboard/List/Report/Moviment";
import DashboardListReportMonthly from "screens/Dashboard/List/Report/Monthly";
import DashboardListReportBudget from "screens/Dashboard/List/Report/Budget";

import DashboardListCustomerFinances from "screens/Dashboard/List/Customer/Finances";
import DashboardListCustomerTutorial from "screens/Dashboard/List/Customer/Tutorial";
import DashboardListCustomerPayments from "screens/Dashboard/List/Customer/Payments";
import DashboardListCustomerMaintain from "screens/Dashboard/List/Customer/Maintain";
import DashboardListCustomerManual from "screens/Dashboard/List/Customer/Manual";

////

import DashboardFormsRegisterUsers from "screens/Dashboard/Form/Register/Users";
import DashboardFormsRegisterCustomers from "screens/Dashboard/Form/Register/Customers";
import DashboardFormsRegisterMaterials from "screens/Dashboard/Form/Register/Materials";
import DashboardFormsRegisterSuppliers from "screens/Dashboard/Form/Register/Suppliers";
import DashboardFormsRegisterConstructions from "screens/Dashboard/Form/Register/Constructions";
import DashboardFormsRegisterArchitects from "screens/Dashboard/Form/Register/Architects";
import DashboardFormsRegisterColaborators from "screens/Dashboard/Form/Register/Colaborators";

////

import DashboardDetailsContructionContracts from "screens/Dashboard/Details/Construction/Contracts";
import DashboardDetailsContructionMaterials from "screens/Dashboard/Details/Construction/Materials";
import DashboardDetailsContructionServices from "screens/Dashboard/Details/Construction/Services";
import DashboardDetailsContructionMap from "screens/Dashboard/Details/Construction/Map";
import DashboardDetailsContructionPayments from "screens/Dashboard/Details/Construction/Payments";
import DashboardDetailsContructionTimeline from "screens/Dashboard/Details/Construction/Timeline";

import DashboardDetailsSupplyContracts from "screens/Dashboard/Details/Supply/Contracts";
import DashboardDetailsSupplyMap from "screens/Dashboard/Details/Supply/Map";
import DashboardDetailsSupplyMaterials from "screens/Dashboard/Details/Supply/Materials";
import DashboardDetailsSupplyServices from "screens/Dashboard/Details/Supply/Services";

import DashboardDetailsFinancePayments from "screens/Dashboard/Details/Finance/Payments";

import DashboardDetailsMaintainContracts from "screens/Dashboard/Details/Maintain/Contracts";
import DashboardDetailsMaintainMaterials from "screens/Dashboard/Details/Maintain/Materials";
import DashboardDetailsMaintainServices from "screens/Dashboard/Details/Maintain/Services";
import DashboardDetailsMaintainMap from "screens/Dashboard/Details/Maintain/Map";
import DashboardDetailsMaintainPayments from "screens/Dashboard/Details/Maintain/Payments";
import DashboardDetailsMaintainTimeline from "screens/Dashboard/Details/Maintain/Timeline";

////

import DashboardFormsConstructionContracts from "screens/Dashboard/Form/Construction/Contracts";
import DashboardFormsConstructionMaterials from "screens/Dashboard/Form/Construction/Materials";
import DashboardFormsConstructionServices from "screens/Dashboard/Form/Construction/Services";
import DashboardFormsConstructionMap from "screens/Dashboard/Form/Construction/Map";
import DashboardFormsConstructionOrders from "screens/Dashboard/Form/Construction/Orders";
import DashboardFormsConstructionMeasurements from "screens/Dashboard/Form/Construction/Measurements";
import DashboardFormsConstructionPayments from "screens/Dashboard/Form/Construction/Payments";
import DashboardFormsConstructionTimeline from "screens/Dashboard/Form/Construction/Timeline";
import DashboardFormsConstructionDaily from "screens/Dashboard/Form/Construction/Daily";

import DashboardFormsMaintainContracts from "screens/Dashboard/Form/Maintain/Contracts";
import DashboardFormsMaintainMaterials from "screens/Dashboard/Form/Maintain/Materials";
import DashboardFormsMaintainServices from "screens/Dashboard/Form/Maintain/Services";
import DashboardFormsMaintainMap from "screens/Dashboard/Form/Maintain/Map";
import DashboardFormsMaintainOrders from "screens/Dashboard/Form/Maintain/Orders";
import DashboardFormsMaintainPayments from "screens/Dashboard/Form/Maintain/Payments";
import DashboardFormsMaintainTimeline from "screens/Dashboard/Form/Maintain/Timeline";
import DashboardFormsMaintainDaily from "screens/Dashboard/Form/Maintain/Daily";
import DashboardFormsMaintainManual from "screens/Dashboard/Form/Maintain/Manual";
import DashboardFormsMaintainControl from "screens/Dashboard/Form/Maintain/Control";

import DashboardFormsSupplyContracts from "screens/Dashboard/Form/Supply/Contracts";
import DashboardFormsSupplyMaterials from "screens/Dashboard/Form/Supply/Materials";
import DashboardFormsSupplyServices from "screens/Dashboard/Form/Supply/Services";
import DashboardFormsSupplyMap from "screens/Dashboard/Form/Supply/Map";
import DashboardFormsSupplyOrders from "screens/Dashboard/Form/Supply/Orders";
import DashboardFormsFinanceMeasurements from "screens/Dashboard/Form/Finance/Measurements";
import DashboardFormsFinancePayments from "screens/Dashboard/Form/Finance/Payments";
import DashboardFormsFinanceControl from "screens/Dashboard/Form/Finance/Control";

import DashboardFormsCustomerPayments from "screens/Dashboard/Form/Customer/Payments";
import DashboardFormsCustomerMaintain from "screens/Dashboard/Form/Customer/Maintain";

import DashboardFormsUserPassword from "screens/Dashboard/Form/User/Password";
import DashboardFormsUserData from "screens/Dashboard/Form/User/Data";
import DashboardDetailsConstructionOrders from "screens/Dashboard/Details/Construction/Orders";
import DashboardDetailsConstructionMeasurements from "screens/Dashboard/Details/Construction/Measurements";
import DashboardDetailsConstructionDaily from "screens/Dashboard/Details/Construction/Daily";


export default function AppRouter() {
    return (
      <Router>  
        <div>
          <Switch>
            {/* <Route path="/" exact> <Landpage /> </Route>  */}
            
            <Route path="/" exact> <Login /> </Route> 
            <Route path="/login" exact> <Login /> </Route> 
            <Route path="/register" exact> <Register /> </Route> 
            <Route path="/forgot" exact> <Forgot /> </Route> 
            <Route path="/create-password" exact> <CreatePassword /> </Route> 
            
            <Route path="/dashboard" exact> <DashboardHome /> </Route> 
            
            <Route path="/dashboard/register/users" exact> <DashboardListUsers /> </Route> 
            <Route path="/dashboard/register/customers" exact> <DashboardListCustomers /> </Route> 
            <Route path="/dashboard/register/materials" exact> <DashboardListMaterials /> </Route> 
            <Route path="/dashboard/register/suppliers" exact> <DashboardListSuppliers /> </Route> 
            <Route path="/dashboard/register/constructions" exact> <DashboardListConstructions /> </Route> 
            <Route path="/dashboard/register/architects" exact> <DashboardListArchitects /> </Route> 
            <Route path="/dashboard/register/colaboratos" exact> <DashboardListColaborators /> </Route> 
            
            <Route path="/dashboard/register/users/create" exact> <DashboardFormsRegisterUsers /> </Route> 
            <Route path="/dashboard/register/users/edit/:id" exact> <DashboardFormsRegisterUsers /> </Route> 
            <Route path="/dashboard/register/customers/create" exact> <DashboardFormsRegisterCustomers /> </Route> 
            <Route path="/dashboard/register/customers/edit/:id" exact> <DashboardFormsRegisterCustomers /> </Route> 
            <Route path="/dashboard/register/materials/create" exact> <DashboardFormsRegisterMaterials /> </Route> 
            <Route path="/dashboard/register/materials/edit/:id" exact> <DashboardFormsRegisterMaterials /> </Route> 
            <Route path="/dashboard/register/suppliers/create" exact> <DashboardFormsRegisterSuppliers /> </Route> 
            <Route path="/dashboard/register/suppliers/edit/:id" exact> <DashboardFormsRegisterSuppliers /> </Route> 
            <Route path="/dashboard/register/constructions/create" exact> <DashboardFormsRegisterConstructions /> </Route> 
            <Route path="/dashboard/register/constructions/edit/:id" exact> <DashboardFormsRegisterConstructions /> </Route> 
            <Route path="/dashboard/register/architects/create" exact> <DashboardFormsRegisterArchitects /> </Route> 
            <Route path="/dashboard/register/architects/edit/:id" exact> <DashboardFormsRegisterArchitects /> </Route> 
            <Route path="/dashboard/register/colaborators/create" exact> <DashboardFormsRegisterColaborators /> </Route> 
            <Route path="/dashboard/register/colaborators/edit/:id" exact> <DashboardFormsRegisterColaborators /> </Route> 

            <Route path="/dashboard/construction/contracts" exact> <DashboardListContracts /> </Route> 
            <Route path="/dashboard/construction/request-material" exact> <DashboardListRequestMaterial /> </Route> 
            <Route path="/dashboard/construction/request-service" exact> <DashboardListRequestService /> </Route> 
            <Route path="/dashboard/construction/maps" exact> <DashboardListMap /> </Route> 
            <Route path="/dashboard/construction/orders" exact> <DashboardListOrders /> </Route> 
            <Route path="/dashboard/construction/measurements" exact> <DashboardListMeasurements /> </Route> 
            <Route path="/dashboard/construction/payments" exact> <DashboardListPayments /> </Route> 
            <Route path="/dashboard/construction/timeline" exact> <DashboardListTimeline /> </Route> 
            <Route path="/dashboard/construction/daily" exact> <DashboardListDaily /> </Route> 

            <Route path="/dashboard/details/construction/contracts/:id" exact> <DashboardDetailsContructionContracts /> </Route> 
            
            <Route path="/dashboard/details/construction/materials/:id" exact> <DashboardDetailsContructionMaterials /> </Route> 
            <Route path="/dashboard/details/construction/materials" exact> <DashboardDetailsContructionMaterials /> </Route> 

            <Route path="/dashboard/details/construction/services/:id" exact> <DashboardDetailsContructionServices /> </Route> 
            <Route path="/dashboard/details/construction/services" exact> <DashboardDetailsContructionServices /> </Route> 

            <Route path="/dashboard/details/construction/map" exact> <DashboardDetailsContructionMap /> </Route> 
            <Route path="/dashboard/details/construction/map/:id" exact> <DashboardDetailsContructionMap /> </Route> 

            <Route path="/dashboard/details/construction/orders/:id/:request_id/:supplier_id" exact> <DashboardDetailsConstructionOrders /> </Route> 
            <Route path="/dashboard/details/construction/orders/:id" exact> <DashboardDetailsConstructionOrders /> </Route> 
            <Route path="/dashboard/details/construction/orders" exact> <DashboardDetailsConstructionOrders /> </Route> 

            <Route path="/dashboard/details/construction/measurements" exact> <DashboardDetailsConstructionMeasurements /> </Route> 
            <Route path="/dashboard/details/construction/measurements/:id" exact> <DashboardDetailsConstructionMeasurements /> </Route> 
            <Route path="/dashboard/details/construction/measurements/:id/:order_id" exact> <DashboardDetailsConstructionMeasurements /> </Route> 
            
            <Route path="/dashboard/details/construction/payments" exact> <DashboardDetailsContructionPayments /> </Route> 
            <Route path="/dashboard/details/construction/payments/:id" exact> <DashboardDetailsContructionPayments /> </Route> 

            <Route path="/dashboard/details/construction/timeline" exact> <DashboardDetailsContructionTimeline /> </Route> 
            <Route path="/dashboard/details/construction/timeline/:id" exact> <DashboardDetailsContructionTimeline /> </Route> 

            <Route path="/dashboard/details/construction/daily" exact> <DashboardDetailsConstructionDaily /> </Route> 
            <Route path="/dashboard/details/construction/daily/:id" exact> <DashboardDetailsConstructionDaily /> </Route> 

            {/*  */}
            

            {/* 
              <Route path="/dashboard/forms/construction/contract/:id" exact> <DashboardFormsConstructionContracts /> </Route> 
              <Route path="/dashboard/forms/construction/materials/:id" exact> <DashboardFormsConstructionMaterials /> </Route> 
              <Route path="/dashboard/forms/construction/services/:id" exact> <DashboardFormsConstructionServices /> </Route> 
              <Route path="/dashboard/forms/construction/map/:id" exact> <DashboardFormsConstructionMap /> </Route> 
              <Route path="/dashboard/forms/construction/payments/:id" exact> <DashboardFormsConstructionPayments /> </Route> 
              <Route path="/dashboard/forms/construction/timeline/:id" exact> <DashboardFormsConstructionTimeline /> </Route> 
              <Route path="/dashboard/forms/construction/orders/:id" exact> <DashboardFormsConstructionOrders /> </Route> 
              <Route path="/dashboard/forms/construction/measurements/:id" exact> <DashboardFormsConstructionMeasurements /> </Route> 
              <Route path="/dashboard/forms/construction/daily/:id" exact> <DashboardFormsConstructionDaily /> </Route>  
            */}





            {/* <Route path="/dashboard/supply/contracts" exact> <DashboardListSupplyContracts /> </Route> 
            <Route path="/dashboard/supply/request-material" exact> <DashboardListRequestSupplyMaterial /> </Route> 
            <Route path="/dashboard/supply/request-service" exact> <DashboardListRequestSupplyService /> </Route> 
            <Route path="/dashboard/supply/maps" exact> <DashboardListSupplyMap /> </Route> 
            <Route path="/dashboard/supply/orders" exact> <DashboardListSupplyOrders /> </Route>  */}
            
            <Route path="/dashboard/supply/contracts" exact> <DashboardListContracts page={3} /> </Route> 
            <Route path="/dashboard/supply/request-material" exact> <DashboardListRequestMaterial page={3} /> </Route> 
            <Route path="/dashboard/supply/request-service" exact> <DashboardListRequestService page={3} /> </Route> 
            <Route path="/dashboard/supply/maps" exact> <DashboardListMap page={3} /> </Route> 
            <Route path="/dashboard/supply/orders" exact> <DashboardListOrders page={3} /> </Route> 



            {/* <Route path="/dashboard/details/supply/contracts/:id" exact> <DashboardDetailsSupplyContracts /> </Route> 
            <Route path="/dashboard/details/supply/materials/:id" exact> <DashboardDetailsSupplyMaterials /> </Route> 
            <Route path="/dashboard/details/supply/services/:id" exact> <DashboardDetailsSupplyServices /> </Route> 
            <Route path="/dashboard/details/supply/map/:id" exact> <DashboardDetailsSupplyMap /> </Route> 
            
            <Route path="/dashboard/forms/supply/contract/:id" exact> <DashboardFormsSupplyContracts /> </Route> 
            <Route path="/dashboard/forms/supply/materials/:id" exact> <DashboardFormsSupplyMaterials /> </Route> 
            <Route path="/dashboard/forms/supply/services/:id" exact> <DashboardFormsSupplyServices /> </Route> 
            <Route path="/dashboard/forms/supply/map/:id" exact> <DashboardFormsSupplyMap /> </Route> 
            <Route path="/dashboard/forms/supply/orders/:id" exact> <DashboardFormsSupplyOrders /> </Route>  */}









            {/* <Route path="/dashboard/finance/measurements" exact> <DashboardListFinanceMeasurements /> </Route> 
            <Route path="/dashboard/finance/payments" exact> <DashboardListFinancePayments /> </Route> 
            <Route path="/dashboard/finance/controls" exact> <DashboardListFinanceControl /> </Route>  */}
            
            <Route path="/dashboard/finance/measurements" exact> <DashboardListMeasurements page={4} subpage={"0"} /> </Route> 
            <Route path="/dashboard/finance/payments" exact> <DashboardListPayments page={4} subpage={1}  /> </Route> 
            <Route path="/dashboard/finance/controls" exact> <DashboardListFinanceControl /> </Route> 

            {/* <Route path="/dashboard/details/finance/payments/:id" exact> <DashboardDetailsFinancePayments /> </Route> 

            <Route path="/dashboard/forms/finance/measurements/:id" exact> <DashboardFormsFinanceMeasurements /> </Route> 
            <Route path="/dashboard/forms/finance/payments/:id" exact> <DashboardFormsFinancePayments /> </Route> 
            <Route path="/dashboard/forms/finance/control/:id" exact> <DashboardFormsFinanceControl /> </Route>  */}
            







            {/* <Route path="/dashboard/maintain/contracts" exact> <DashboardListMaintainContracts /> </Route> 
            <Route path="/dashboard/maintain/request-material" exact> <DashboardListMaintainRequestMaterial /> </Route> 
            <Route path="/dashboard/maintain/request-service" exact> <DashboardListMaintainRequestService /> </Route> 
            <Route path="/dashboard/maintain/maps" exact> <DashboardListMaintainMap /> </Route> 
            <Route path="/dashboard/maintain/orders" exact> <DashboardListMaintainOrders /> </Route> 
            <Route path="/dashboard/maintain/payments" exact> <DashboardListMaintainPayments /> </Route> 
            <Route path="/dashboard/maintain/timeline" exact> <DashboardListMaintainTimeline /> </Route> 
            <Route path="/dashboard/maintain/daily" exact> <DashboardListMaintainDaily /> </Route> 
            <Route path="/dashboard/maintain/control" exact> <DashboardListMaintainControl /> </Route> 
            <Route path="/dashboard/maintain/manual" exact> <DashboardListMaintainManual /> </Route>  */}

            <Route path="/dashboard/maintain/contracts" exact> <DashboardListContracts page={7} /> </Route> 
            <Route path="/dashboard/maintain/request-material" exact> <DashboardListRequestMaterial page={7} /> </Route> 
            <Route path="/dashboard/maintain/request-service" exact> <DashboardListRequestService page={7} /> </Route> 
            <Route path="/dashboard/maintain/maps" exact> <DashboardListMap page={7} /> </Route> 
            <Route path="/dashboard/maintain/orders" exact> <DashboardListOrders page={7} /> </Route> 

            <Route path="/dashboard/maintain/payments" exact> <DashboardListPayments page={7} subpage={5} /> </Route> 
            <Route path="/dashboard/maintain/timeline" exact> <DashboardListTimeline page={7} subpage={6} /> </Route> 
            <Route path="/dashboard/maintain/daily" exact> <DashboardListDaily page={7} subpage={7} /> </Route> 

            <Route path="/dashboard/maintain/control" exact> <DashboardListMaintainControl /> </Route> 
            {/* <Route path="/dashboard/maintain/manual" exact> <DashboardListMaintainManual /> </Route>  */}
            <Route path="/dashboard/maintain/manual" exact> <DashboardListCustomerManual page={7} subpage={9}  /> </Route> 

            {/* <Route path="/dashboard/details/maintain/contracts/:id" exact> <DashboardDetailsMaintainContracts /> </Route> 
            <Route path="/dashboard/details/maintain/materials/:id" exact> <DashboardDetailsMaintainMaterials /> </Route> 
            <Route path="/dashboard/details/maintain/services/:id" exact> <DashboardDetailsMaintainServices /> </Route> 
            <Route path="/dashboard/details/maintain/map/:id" exact> <DashboardDetailsMaintainMap /> </Route> 
            <Route path="/dashboard/details/maintain/payments/:id" exact> <DashboardDetailsMaintainPayments /> </Route> 
            <Route path="/dashboard/details/maintain/timeline/:id" exact> <DashboardDetailsMaintainTimeline /> </Route> 

            <Route path="/dashboard/forms/maintain/contract/:id" exact> <DashboardFormsMaintainContracts /> </Route> 
            <Route path="/dashboard/forms/maintain/materials/:id" exact> <DashboardFormsMaintainMaterials /> </Route> 
            <Route path="/dashboard/forms/maintain/services/:id" exact> <DashboardFormsMaintainServices /> </Route> 
            <Route path="/dashboard/forms/maintain/map/:id" exact> <DashboardFormsMaintainMap /> </Route> 
            <Route path="/dashboard/forms/maintain/orders/:id" exact> <DashboardFormsMaintainOrders /> </Route> 
            <Route path="/dashboard/forms/maintain/payments/:id" exact> <DashboardFormsMaintainPayments /> </Route> 
            <Route path="/dashboard/forms/maintain/timeline/:id" exact> <DashboardFormsMaintainTimeline /> </Route> 
            <Route path="/dashboard/forms/maintain/daily/:id" exact> <DashboardFormsMaintainDaily /> </Route> 
            <Route path="/dashboard/forms/maintain/manual/:id" exact> <DashboardFormsMaintainManual /> </Route> 
            <Route path="/dashboard/forms/maintain/control/:id" exact> <DashboardFormsMaintainControl /> </Route>  */}







            
            <Route path="/dashboard/report/center" exact> <DashboardListReportCenter /> </Route> 
            {/* <Route path="/dashboard/report/accounts" exact> <DashboardListReportAccounts /> </Route> 
            <Route path="/dashboard/report/costs" exact> <DashboardListReportCosts /> </Route> 
            <Route path="/dashboard/report/revenues" exact> <DashboardListReportRevenues /> </Route> 
            <Route path="/dashboard/report/moviment" exact> <DashboardListReportMoviment /> </Route> 
            <Route path="/dashboard/report/monthly" exact> <DashboardListReportMonthly /> </Route> 
            <Route path="/dashboard/report/budget" exact> <DashboardListReportBudget /> </Route>  */}
            <Route path="/dashboard/report/accounts" exact> <DashboardListReportCenter subpage={1} /> </Route> 
            <Route path="/dashboard/report/moviment" exact> <DashboardListReportCenter subpage={2} /> </Route> 
            <Route path="/dashboard/report/revenues" exact> <DashboardListReportCenter subpage={3} /> </Route> 
            <Route path="/dashboard/report/monthly" exact> <DashboardListReportCenter subpage={4} /> </Route> 
            <Route path="/dashboard/report/budget" exact> <DashboardListReportCenter subpage={5} /> </Route> 
            <Route path="/dashboard/report/costs" exact> <DashboardListReportCenter subpage={6} /> </Route> 


            
            <Route path="/dashboard/customer/tutorial" exact> <DashboardListCustomerTutorial /> </Route> 
            {/* <Route path="/dashboard/customer/finances" exact> <DashboardListCustomerFinances /> </Route>  */}
            <Route path="/dashboard/customer/finances" exact> <DashboardListMeasurements page={6} subpage={1} /> </Route> 
            {/* <Route path="/dashboard/customer/payments" exact> <DashboardListCustomerPayments /> </Route>  */}
            <Route path="/dashboard/customer/payments" exact> <DashboardListPayments page={6} subpage={2} /> </Route> 
            <Route path="/dashboard/customer/manual" exact> <DashboardListCustomerManual /> </Route> 
            {/* <Route path="/dashboard/customer/maintain" exact> <DashboardListCustomerMaintain /> </Route>  */}
            <Route path="/dashboard/customer/maintain" exact> <DashboardListRequestService page={6} subpage={4} /> </Route> 
            
            <Route path="/dashboard/forms/customer/payments/:id" exact> <DashboardFormsCustomerPayments /> </Route> 
            <Route path="/dashboard/forms/customer/maintain/:id" exact> <DashboardFormsCustomerMaintain /> </Route> 

            
            <Route path="/dashboard/forms/user/data" exact> <DashboardFormsUserData /> </Route> 
            <Route path="/dashboard/forms/user/password" exact> <DashboardFormsUserPassword /> </Route> 

            <Route path="/dashboard/me" exact> <DashboardMe /> </Route> 
            
            <Route path="*" exact> <NotFound /> </Route>
          </Switch>
        </div>
      </Router>
    );
}