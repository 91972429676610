import React from "react";  

import { 
    FormActions, UploadContentText, UploadContentTitle, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import useController from "./controller";
import { Col, Row } from "reactstrap";
import Button from "components/Form/Button";
import { DashboardIcon } from "ui/styled";
import UploadFile from "components/Form/UploadFile";
import { parseStrapiImage } from "utils";

export default function DashboardFormsRegisterConstructions(){   

    const {
        refForm,
        
        register,
        loading,
        saving,

        actions,
        formData,
        handleChangeFile,
        contracts,
        addendum,
        remove,
        id,

        rawAddendumFile,
        rawContractFile
    } = useController()

    return ( 
        <>
            <ContainerAuthenticated  page={1} subpage={2}>
                <DashboardCardWrapper backable title={"Obra - Nome da obra"} actions={actions} loading={loading}>

                    <DashboardCardWrapper closeable title={"Dados da obra"} subtitle={"Adicione as informações da obra relacionada ao orçamento"} white>
                        <DashboardFormsCore formItems={formData} register={register} ref={refForm} />
                    </DashboardCardWrapper>

                    <Row>
                        <Col md={6}>
                            <DashboardCardWrapper title={"Upload do contrato"} subtitle={"Insira arquivo do contrato"} white>
                                {/* contracts */}
                                {
                                    contracts?.[0]?.id ? <>
                                        <Button onClick={() => window.open( parseStrapiImage(contracts?.[0]?.url), "new")}> { contracts?.[0]?.name } </Button>
                                    </> : <>
                                        <UploadContentTitle>Insira arquivo do contrato</UploadContentTitle>
                                        <UploadContentText>Arquivo de orçamento (obrigatório)</UploadContentText>
                                        <UploadFile onRaw={rawContractFile} onChange={f => handleChangeFile(f, "contracts")}>
                                            <Button> <DashboardIcon icon={"upload"} /> Adicionar arquivo</Button>
                                        </UploadFile>
                                    </>
                                }
                            </DashboardCardWrapper>
                        </Col>
                        <Col md={6}>
                            <DashboardCardWrapper title={"Upload de adendo de contrato"} subtitle={"Insira arquivo do adendo"} white>
                                {/* addendum */}
                                {
                                    addendum?.[0]?.id ? <>
                                        <Button onClick={() => window.open( parseStrapiImage(addendum?.[0]?.url), "new")}> { addendum?.[0]?.name } </Button>
                                    </> : <>
                                        <UploadContentTitle>Insira arquivo do adendo</UploadContentTitle>
                                        <UploadContentText>Arquivo de adendo</UploadContentText>
                                        <UploadFile onRaw={rawAddendumFile} onChange={f => handleChangeFile(f, "addendum")}>
                                            <Button> <DashboardIcon icon={"upload"} /> Adicionar arquivo</Button>
                                        </UploadFile>
                                    </>
                                }
                            </DashboardCardWrapper>
                        </Col>
                    </Row>

                    <FormActions> 
                        <DashboardActions removeable={!id ? null : remove} actions={actions} loading={saving} />
                    </FormActions>

                </DashboardCardWrapper>
               
            </ContainerAuthenticated>
        </>
    );
}