import React, { useEffect, useState } from "react";

import { Read as ReadCustomers } from "services/customers";
import { Read as ReadConstructions } from "services/constructions";
import { Read as ReadContractsAddend } from "services/construction-contracts-addenda";

import { monthsOptions } from "utils/brazil";
import { normalizeStrapiRegister } from "utils";
import moment from "moment/moment";

export default function useController() {

    const [clients, setClients] = useState(0)
    const [constructions, setConstructions] = useState(0)
    const [constructionsDone, setConstructionsDone] = useState(0)
    const [contracts, setContracts] = useState(0)
    
    const [lastConstructions, setLastConstructions] = useState([])
    const [lastCustomers, setLastCustomers] = useState([])

    const [ordersToPay, setOrdersToPay] = useState( monthsOptions.map((m,k) => [ m?.acron , (200 * (k % 3 === 0 ? k : 2)) ]) || [] )

    const [filterYear, setFilterYear] = useState(false)

    const columns = [
        { title:'Nome', ref:'name' },
        { title:'Data', ref:'date' }
    ]

    const parseRow = item => {
        const normalItem = normalizeStrapiRegister(item)
        return { 
            name: normalItem?.name, 
            date: moment(normalItem?.createAt)?.format("L")
        }
    }

    const prepareLast = array => {
        return array?.sort((a, b) => a.id - b.id)?.slice(0, 5)?.map(parseRow)
    }

    const init = async () => {
        const result_customers = await ReadCustomers()
        const result_constructions = await ReadConstructions()
        const result_addends = await ReadContractsAddend()

        setClients( result_customers?.data?.length || 0 )
        setConstructions( result_constructions?.data?.length || 0 )
        setConstructionsDone( result_constructions?.data?.length || 0 )
        setContracts( result_addends?.data?.length || 0 )

        // console.log({ 
        //     result_customers,
        //     result_constructions,
        //     result_addends
        // })

        if( result_customers?.data?.length ){ 
            setLastCustomers(prepareLast(result_customers?.data))
        }

        if( result_constructions?.data?.length ){ 
            setLastConstructions(prepareLast(result_constructions?.data))
        }

    }

    useEffect(() => { init() ;}, [])
    
    return {    
        clients,
        constructions,
        constructionsDone,
        contracts,

        ordersToPay,

        lastConstructions,
        lastCustomers,

        columns,

        setFilterYear
    }   
}   