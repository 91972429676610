import React from "react";  

import { useHistory } from "react-router-dom";

import { 
    DashboardTitle,
    
    CardTutorial,
    CardTutorialImage,
    CardTutorialTitle,
    CardTutorialText
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import DashboardCardWrapper from "components/Cards/Wrapper";

import Button from "components/Form/Button";
import { BetweenCenter, StatusItem, StatusItemContent } from "ui/styled";


export default function DashboardListCustomerTutorial(){  

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`);  

    const tutorials = [
        { title:"Pagamentos", text:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", image:"/images/tutorial-1.svg", link:"dashboard/customer/finances" },
        { title:"Financeiro", text:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", image:"/images/tutorial-2.svg", link:"dashboard/customer/payments" },
        { title:"Manual de Obra", text:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", image:"/images/tutorial-3.svg", link:"dashboard/customer/manual" },
        { title:"Solicitar manutenção", text:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.", image:"/images/tutorial-4.svg", link:"dashboard/customer/maintain" },
    ]

    return ( 
        <>
            <ContainerAuthenticated page={6} subpage={0}> 
                <BetweenCenter spaced>
                    <DashboardTitle>Tutorial</DashboardTitle>
                    
                </BetweenCenter>
                <Row>  
                    <Col sm={12}>
                        <DashboardCardWrapper>
                            
                            <Row>
                                {
                                    tutorials?.map((item, key) => 
                                        <Col key={key}>
                                            <CardTutorial key={key}>
                                                <CardTutorialImage src={item?.image} />
                                                <CardTutorialTitle>{ item?.title }</CardTutorialTitle>
                                                <CardTutorialText>{ item?.text }</CardTutorialText>
                                                <Button secondary onClick={() => navigate(item?.link)}>Confira</Button>
                                            </CardTutorial>
                                        </Col>
                                    )
                                }
                            </Row>

                        </DashboardCardWrapper>
                    </Col> 
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}