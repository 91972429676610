import React from "react";  

import { 
    CardPhoto,
    CardPhotoAction,
    CardPhotoActionIcon,
    CardPhotoActionText,
    CardPhotoContent,
    CardPhotoHeader,
    CardPhotoImage,
    CardPhotoRow,
    CardPhotoRowItem,
    CardsWrapper,
    FormActions, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import { formAddress, formContacts, formPersonalDataCustomer } from "utils/forms";
import useController from "./controller";
import Input from "components/Form/Input";
import Select from "components/Form/Select";
import Button from "components/Form/Button";
import { BetweenCenter, ContentButton, Load, LoadCenter } from "ui/styled";
import CardDailyPhoto from "components/Cards/Daily";

export default function DashboardDetailsConstructionDaily(){   
    

    const { 
        
        id,
        refForm, 
        register,
        loading, 

        actions,
 
        openAdd,
        builds,
        registers,

        removeItem,
        init,

        changeConstruction

    } = useController()

    return ( 
        <>
        <ContainerAuthenticated noside page={2} subpage={8}>
            <DashboardCardWrapper backable title={`Diário - ${ register?.name }`} actions={actions} loading={loading}>
                {
                    loading ? 
                        <LoadCenter>
                            <Load color="primary" />
                        </LoadCenter> : <>
                        
                        <BetweenCenter topper>
                            <ContentButton>
                                <Select placeholder={"Obra"} options={builds} value={id} onChange={changeConstruction} />
                            </ContentButton>
                            <ContentButton>
                                <Button nospace primary outline onClick={openAdd}>Adicionar fotos</Button>
                            </ContentButton>
                        </BetweenCenter>

                        <CardsWrapper>
                            {
                                registers?.map((item, key) => 
                                    <CardDailyPhoto key={key} item={item} remove={() => removeItem(item?.id)} reload={init} /> 
                                )
                            }
                        </CardsWrapper>
                    </>
                }


            </DashboardCardWrapper>
        </ContainerAuthenticated>
        </>
    );
}