import React from "react";  

import { 
    FormActions, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import { formAddress, formContacts, formContractBuilding, formPersonalDataCustomer } from "utils/forms";
import useController from "./controller";
import { EmptyDiv, HorizontalScroll, HorizontalScrollContent, StatusItem, StatusItemContent } from "ui/styled";
import Button from "components/Form/Button";
import DashboardCardTable from "components/Cards/Table";
import DashboardCardTotal from "components/Cards/Total";

export default function DashboardDetailsContructionContracts(){   
    

    const { 

        refForm, 
        register,
        loading, 

        actions,

        active, 
        setActive, 
        
        constract_options,

        rows,
        columns

    } = useController()



    return ( 
        <>
        <ContainerAuthenticated noside page={2} subpage={0}>
            <DashboardCardWrapper backable title={`Contrato - ${ register?.name }`} loading={loading}>

                <HorizontalScroll>
                    <HorizontalScrollContent>
                        {
                            constract_options?.map((item, key) => 
                                <EmptyDiv key={key}>
                                    <Button nospace outline={key !== active} onClick={() => setActive(key)} primary>{ item.title}</Button>
                                </EmptyDiv>
                            )
                        }
                    </HorizontalScrollContent>
                </HorizontalScroll>

                <DashboardCardWrapper closeable title={"Dados do contrato"} white actions={actions}>
                    <DashboardFormsCore formItems={formContractBuilding} register={register} ref={refForm} />
                </DashboardCardWrapper>
                <DashboardCardWrapper closeable title={"Itens do contrato"} white>
                        
                    <DashboardCardTable title={"1.0 - Serviços preliminares"} rows={rows} columns={columns} blue />
                    <DashboardCardTable title={"1.2 - Projetos"} rows={rows} columns={columns} blue />

                    <DashboardCardTotal />
                </DashboardCardWrapper>
                
            </DashboardCardWrapper>
        </ContainerAuthenticated>
        </>
    );
}