import styled from 'styled-components'  


export const FormActions = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
`;

export const ContentTableAction = styled.div.attrs({ 
})`
    display: flex;
    gap: 0px;
    max-width: 100px;
    margin: 0 0px 0 auto;
    ${
        props => props.flat ? `
            margin: 0 0 0 auto;
        ` : ``
    }
    ${
        p => p.colored ? `
            background: ${ p.theme.palette.colors.subtotal };
        ` : ``
    }
`;

export const ContentTableItem = styled.div.attrs({ 
})`
    padding: 0 8px;
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }
`;


export const ContentTableActionIconEdit = styled.img.attrs({ 
    src:'/icons/edit.svg',
    width: 16
})`
`;

export const ContentTableActionIconDone = styled.img.attrs({ 
    src:'/icons/done.svg',
    width: 16
})`
`;

export const ContentTableActionIconRemove = styled.img.attrs({ 
    src:'/icons/trash.svg',
    width: 16
})`
`;



export const ContentTableInput = styled.div.attrs({ 
})`
    max-width: 80px;
    margin: 0 0 0 auto;
`;
