import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'

export const DashboardTitle = styled.div.attrs({ 
})`            
    font-size: 22px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.title };
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardText = styled.div.attrs({ 
})`            
    font-size: 16px;
    line-height: 26px;
    color: ${ props => props.theme.palette.colors.black };
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;



export const ContentTableAction = styled.div.attrs({ 
})`
    display: flex;
    gap: 0px;
    max-width: 100px;
    margin: 0 0px 0 auto;
    ${
        props => props.flat ? `
            margin: 0 0 0 auto;
        ` : ``
    }
`;


export const ContentTableItem = styled.div.attrs({ 
})`
    padding: 0 8px;
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }
`;


export const ContentTableActionIconEdit = styled.img.attrs({ 
    src:'/icons/see.svg',
    width: 16
})`
`;

export const ContentTableActionIconRemove = styled.img.attrs({ 
    src:'/icons/trash.svg',
    width: 16
})`
`;