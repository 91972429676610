import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister, valid } from "utils";
import { toast } from "react-toastify";
import { formPersonalData } from "utils/forms";

import { Read as ReadConstructions, ReadOne, Update } from "services/constructions";
import { ReadAddressesByZipCode } from "services/api";
import { CoreContext } from "context/CoreContext";
import { Delete, Read } from "services/daily-photos";

export default function useController(){   

    const history = useHistory(); 
    const back = () => { history.goBack() ;}
    const navigate = to => history.push(`/${ to }`); 

    const { setModal } = useContext(CoreContext)
    const { id } = useParams()
 
     
    const [builds, setBuilds] = useState([])
    const [registers, setRegisters] = useState([])

    const [register, setRegister] = useState(null)
    const [loading, setLoading] = useState(false) 
     
    const actions = [
        // { title:'Imprimir', outline:true, icon:'print', primary:true, action:() => null },
        // { title:'Baixar', outline:true, icon:'download', primary:true, action:() => null },
        // { title:'Cancelar', outline:true, action:() => history.goBack() },
        // { title:'Salvar', action:() => history.goBack(), loadable:true }
    ]

    const removeItem = async id => {
        setLoading(true)
        const result = await Delete(id)
        if(result && !exposeStrapiError(result)){
            toast.success("Imagem removida com sucesso")
            init()
        }else{
            setLoading(false)
        }
    }

    const init = useCallback(async () => {
        setLoading(true)
        const result = await ReadOne(id)
        if(result?.data?.id){
            const normalResult = normalizeStrapiRegister(result)
            console.log("normalResult", normalResult)
            setRegister({ ...normalResult, customer: normalResult?.customer?.name }) 
        }

        const result_photos = await Read(id)
        // console.log("result_photos", result_photos)
        setRegisters( result_photos?.data?.length ? normalizeStrapiList(result_photos)?.map(m => ({ ...m, image: normalizeStrapiRegister(m?.image) }) ) : [])
        
        const result_builds = await ReadConstructions(id)
        // console.log("result_builds", result_builds)
        setBuilds( result_builds?.data?.length ? normalizeStrapiList(result_builds)?.map(m => ({ ...m, title: m?.name }) ) : [])


        setLoading(false)
    }, [id])


    const openAdd = () => {
        setModal({
            type: "daily-photo",
            construction: id,
            init
        })
    }

    const changeConstruction = (nid) => {
        console.log("wanna change to ", nid)
        navigate(`dashboard/details/construction/daily/${nid}`)
    }
    
    useEffect(() => { if(id){ init() ;} }, [id, init])

    return { 

        id,
        register,
        loading, 

        actions,
 
        openAdd,
        builds,
        registers,

        removeItem,
        init,
        changeConstruction
    }
}