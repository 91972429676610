import React from "react";  

import { 
    FormActions, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import useController from "./controller";
import { formMaterial } from "utils/forms";

export default function DashboardFormsRegisterMaterials(){   

    const {
        refForm,
        
        register,
        loading,
        saving,

        actions,

        formData,
        
        remove,
        id
    } = useController()

    return ( 
        <>
            <ContainerAuthenticated page={1} subpage={6}>
                <DashboardCardWrapper backable title={"Novo material"} actions={actions} loading={loading}>

                    <DashboardCardWrapper closeable title={"Dados do material"} white>
                        <DashboardFormsCore formItems={formData} register={register} ref={refForm} />
                    </DashboardCardWrapper>

                    <FormActions> 
                        <DashboardActions removeable={!id ? null : remove} actions={actions} loading={saving} />
                    </FormActions>

                </DashboardCardWrapper>
            </ContainerAuthenticated>
        </>
    );
}