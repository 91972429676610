import React, { useState } from "react";  

import {  
    
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import DashboardCardStatus from "components/Cards/Status";
import DashboardCardChart from "components/Cards/Chart";
import DashboardCardTable from "components/Cards/Table";
import useController from "./controller";

export default function DashboardHome(){  

    const {    
        clients,
        constructions,
        constructionsDone,
        contracts,

        ordersToPay,

        lastConstructions,
        lastCustomers,

        columns,

        setFilterYear
    } = useController()

    return ( 
        <>
            <ContainerAuthenticated page={0}> 
                <Row> 
                    <Col sm={12} md={12}>
                        <DashboardCardStatus items={{ clients, constructions, constructionsDone, contracts }} />
                        <DashboardCardChart items={ordersToPay} filterYear={setFilterYear} />
                    </Col>
                </Row>
                <Row>  
                    <Col sm={12} md={6}>
                        <DashboardCardTable title={"Últimas Obras cadastradas"} rows={lastConstructions} columns={columns} />
                    </Col> 
                    <Col sm={12} md={6}>
                        <DashboardCardTable title={"Últimos clientes cadastrados"} rows={lastCustomers} columns={columns} />
                    </Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}