import React from "react";  

import {  
    
    CardHomeStatus,
    CardHomeStatuItem

} from "./styled";

import { monthsOptions } from "utils/brazil";
import DashboardCardWrapper from "../Wrapper";

export default function DashboardCardStatus({ items }){  
    
    const cards = [
        { title:"Clientes", value: items?.clients },
        { title:"Obras em andamento", value: items?.constructions },
        { title:"Obras finalizadas", value: items?.constructionsDone },
        { title:"Contratos", value: items?.contracts },

        // ...(register?.events?.data||[])?.map(m => {
        
        //     const reg = normalizeStrapiRegister(m)
            
        //     return ({
        //         title: reg?.name,
        //         value: reg?.codes?.length
        //     })
        // }),

    ]

    return ( 
        <>
            <DashboardCardWrapper title={"Dashboard"} selectable={true ? null : { options:monthsOptions, placeholder:"Mês" }}>
                <CardHomeStatus>
                    {
                        cards.map((item, key) => 
                            <CardHomeStatuItem key={key} limited={item.limited}>
                                <b>{ item.title }</b>
                                <p>{ item.value }</p>
                            </CardHomeStatuItem>
                        )
                    }
                </CardHomeStatus>
            </DashboardCardWrapper>
        </>
    );
}