import React from "react";  

import { 
    FormActions,
    ObservationContent,
    ObservationTitle, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import { formAddress, formBuyOrder, formBuyOrderDelivery, formBuyOrderPayment, formBuyOrderSupply, formContacts, formPersonalDataCustomer, formSupplyCondition } from "utils/forms";
import useController from "./controller";
import DashboardCardTable from "components/Cards/Table";
import BasicTable from "components/Form/Table";

export default function DashboardDetailsConstructionOrders(){   
    

    const { 

        refForm, 
        register,
        loading, 

        actions,

        active, 
        setActive,  

        rows,
        columns,

        columnsTotal,
        rowsTotal,

        formActions,

        registerSupplier,
        registerRequest, 

        refFormDelivery, 
        refFormPayment, 

        refFormCondition,

        formDelivery,
        formPayment

    } = useController()


    return ( 
        <>
        <ContainerAuthenticated noside page={2} subpage={4}>
            <DashboardCardWrapper backable title={`Ordens - ${ register?.name }`} actions={formActions} loading={loading}>

                 <DashboardCardWrapper closeable title={"Dados do cliente"} white actions={actions}>
                    <DashboardFormsCore formItems={formBuyOrder} register={register} ref={refForm} />
                </DashboardCardWrapper>
                 
                 <DashboardCardWrapper closeable title={"Local de entrega / Obras"} white>
                    <DashboardFormsCore formItems={formDelivery} register={register} ref={refFormDelivery} />
                </DashboardCardWrapper>
                 
                <DashboardCardWrapper closeable title={"Dados de cobrança"} white>
                    <DashboardFormsCore formItems={formPayment} register={register} ref={refFormPayment} />
                </DashboardCardWrapper>
                 
                <DashboardCardWrapper closeable title={"Dados do fornecedor"} white>
                    <DashboardFormsCore formItems={formBuyOrderSupply} register={registerSupplier} ref={refForm} />
                </DashboardCardWrapper>

                <DashboardCardWrapper closeable title={"Lista de itens"} white>
                    <BasicTable rows={rows} columns={columns} blue />
                </DashboardCardWrapper>

                <DashboardCardWrapper closeable title={"Total"} white>
                    <BasicTable rows={rowsTotal} columns={columnsTotal} noHeader />
                </DashboardCardWrapper>

                <DashboardCardWrapper closeable title={"Condição de fornecimento"} white>
                    <DashboardFormsCore formItems={formSupplyCondition} register={register} ref={refFormCondition} />
                </DashboardCardWrapper>

                <DashboardCardWrapper closeable title={"Observações importantes"} white>
                    <ObservationTitle>Observações</ObservationTitle>
                    <ObservationContent>
                        <p>1. Esta OC aprova a contratação de serviços onde estão inclusos Materiais, Mão de Obra de Aplicação, Encargos, Impostos e demais despesas c/Equipe;</p>
                        <p>2. Todos os serviços técnicos deverão obrigatoriamente fornecer A.R.T (Anotação de responsabilidade técnica);</p>
                        <p>3. A Étimo Engenharia não se responsabiliza pelo extravio ou não recebimento do boleto, desta forma se isenta de multa, juros e custas de Cartório;</p>
                        <p>4. A Étimo Engenharia não autoriza desconto de Títulos em Instituições Financeiras (Bancos ou Factoring) em nenhuma hipótese;</p>
                        <p>5. A Étimo Engenharia não se responsabiliza por materiais entregues fora das Normas da ABNT, danificados ou diferente das características apresentadas acima citadas;</p>
                        <p>6. Os materiais e serviços serão inspecionados de acordo com as especificações e caso sejam reprovados serão devolvidos ou descontados do total contratado;</p>                                                                                                                                                                                                                                                                  
                        <p>7. É obrigatório a apresentação de nota fiscal para todos os serviços e materiais;</p>
                        <p>8. Os dados de conta devem corresponder com os dados da empresa contratada e emitente da Nota Fiscal;</p>
                        <p>9. Todas as medições devem ser feitas e aprovadas com o engenheiro responsável pela obra;</p>
                        <p>10. O Pagamento do saldo só será feito após o término do serviço e aceite pelo responsável da obra.</p>
                        <p>11. É proibido a executação de qualquer serviço sem a aprovação dos engenheiros responsáveis da obra e a emissão da ordem de compra.</p>
                        <p>12. As medições serão liberadas quando o serviço executado tiver porcentagem maior que o valor pago de sinal inicial.</p>
                    </ObservationContent>
                </DashboardCardWrapper>

                <FormActions> 
                    <DashboardActions actions={formActions} />
                </FormActions> 
                
                {/**/}

            </DashboardCardWrapper>
        </ContainerAuthenticated>
        </>
    );
}