import React from "react";  

import { 
    FormActions, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import useController from "./controller";

export default function DashboardFormsRegisterUsers(){   

    const {
        refForm,
        refFormAccess,
        
        register,
        loading,
        saving,

        actions,
        accessItems,
        
        formData
    } = useController()

    return ( 
        <>
            <ContainerAuthenticated page={1} subpage={0}>
                <DashboardCardWrapper backable title={"Cadastro da usuários"} actions={actions} loading={loading}>

                    <DashboardCardWrapper closeable title={"Dados pessoais"} white>
                        <DashboardFormsCore formItems={formData} register={register} ref={refForm} />
                    </DashboardCardWrapper>
                    
                    <DashboardCardWrapper closeable title={"Acesso ao sistema"} white>
                        <DashboardFormsCore formItems={accessItems} register={register?.access} ref={refFormAccess} />
                    </DashboardCardWrapper>

                    <FormActions> 
                        <DashboardActions actions={actions} loading={saving} />
                    </FormActions>

                </DashboardCardWrapper>
            </ContainerAuthenticated>
        </>
    );
}