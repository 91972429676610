import React, { useContext, useState } from "react";   
import { useHistory } from "react-router-dom";
 
import { 
    DashboardHeaderContainer,
    DashboardHeaderAction,
    DashboardHeaderActionIcon,
    DashboardHeaderLogo,

    DashboardUserProfileContainer,
    DashboardUserProfileImage,
    DashboardUserProfile,
    DashboardUserProfileTitle,
    DashboardUserProfileText,

    HeaderMenuContainer,
    HeaderMenuItem,
    HeaderMenuItemText,
    HeaderMenuItemIcon,
    HeaderMenuItemSeparator
} from "./styled"; 
import { CoreContext } from "context/CoreContext";
import { DoLogout } from "services/authentication";

export default function DashboardHeader(){  
    
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user, opened, setOpened } = useContext(CoreContext) 
    const [userOpen, setUserOpen] = useState(false)

    const options = [
        // { title:"Meus dados", action: () => navigate("dashboard/forms/user/data") },
        // { title:"Senha", action: () => navigate("dashboard/forms/user/password") },
        { title:"Sair", action: () => exit() },
    ]

    const exit = async () => {
        await DoLogout()
        navigate('login')
    }

    return ( 
        <> 
            <DashboardHeaderContainer>
                <DashboardHeaderAction onClick={() => setOpened(!opened)}>
                    <DashboardHeaderLogo opened={opened} src={`/icons/logo${ !opened ? `-icon` : '' }.svg`} alt="logo-icon" />
                    <DashboardHeaderActionIcon src={`/icons/side.svg`} alt="side-icon" opened={opened} />
                </DashboardHeaderAction> 
                {/*  */}

                <DashboardUserProfileContainer onClick={() => setUserOpen(!userOpen)}>
                    <DashboardUserProfileImage />
                    <DashboardUserProfile>
                        <DashboardUserProfileTitle>Bem vindo(a),</DashboardUserProfileTitle>
                        <DashboardUserProfileText> { user?.name } </DashboardUserProfileText>
                    </DashboardUserProfile>
                </DashboardUserProfileContainer>


            </DashboardHeaderContainer> 
            {
                !userOpen ? null :
                    <HeaderMenuContainer>

                        <HeaderMenuItem opened onClick={() => setUserOpen(false)}>
                            <HeaderMenuItemText>{ user?.name }</HeaderMenuItemText>
                            <HeaderMenuItemIcon opened />
                        </HeaderMenuItem>

                        <HeaderMenuItemSeparator />
                        
                        {
                            options?.map((item, key) => 
                                <HeaderMenuItem key={key} onClick={item.action}>
                                    <HeaderMenuItemText>{ item?.title }</HeaderMenuItemText>
                                    <HeaderMenuItemIcon />
                                </HeaderMenuItem>
                            )
                        }

                    </HeaderMenuContainer>
            }
        </>
    );
}