import React, { useEffect, useState } from "react";

import { Delete, Read } from "services/customers";


import { ContentTableAction, ContentTableActionIconEdit, ContentTableActionIconRemove, ContentTableItem, DashboardTitle } from "./styled";

import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister } from "utils";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

export default function useController() {
 
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const [loading, setLoading] = useState(false) 
    const [rows, setRows] = useState([]) 

    const columns = [
        { title:'Nome do cliente', ref:'name' },
        { title:'Nome do contato', ref:'contact_name' },
        { title:'Telefone', ref:'phone' },
        { title:'E-mail', ref:'email' },
        { 
            title:' ', 
            renderCell: ({ row }) => <>
                <ContentTableAction>
                    <ContentTableItem  onClick={() => navigate(`dashboard/register/customers/edit/${ row.id }`)}>
                        <ContentTableActionIconEdit />
                    </ContentTableItem>
                    <ContentTableItem onClick={() => remove(row)}>
                        <ContentTableActionIconRemove />
                    </ContentTableItem>
                </ContentTableAction>
            </>
        }

    ] 

    const parseRow = item => {
        return { 
            ...item,
            name: item?.build_name, 
            email: item?.contact?.email,
            phone: item?.contact?.phone,
            contact_name: item?.name
            // date: moment(normalItem?.createAt)?.format("L"),
        }
    }

    const init = async () => {
        setLoading(true)
        const result = await Read() 
        if(result && !exposeStrapiError(result)){
            if(result?.data?.length){
                const normalResult = normalizeStrapiList(result)
                console.log("normalResult", normalResult)
                setRows(normalResult?.map(parseRow))
            }else{ setRows([]) ;}
        }
        setLoading(false)
    }

    useEffect(() => { init() ;}, [])


    const remove = async row => {
        setLoading(true) 
        const result = await Delete(row?.id)
        if(result && !exposeStrapiError(result)){
            toast.success('Removido com sucesso') ;
        }
        init()
    }
    
    return {
        loading,
        columns,
        rows,
        navigate
    }
}   