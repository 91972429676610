import styled from 'styled-components'  


export const FormActions = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
`;


export const CommomTableRow = styled.div.attrs({ 
})`
    display: flex;
    overflow: auto;
`;

export const CommomTable = styled.div.attrs({ 
})`
    max-width: 60vw;
    min-width: 60vw;
`;

export const CommomDay = styled.div.attrs({ 
})`
    width: width: 100%;
    height: 20px; 

    position: relative;
`;

export const CommomDayDecoration = styled.div.attrs({ 
})`
    position: absolute;
    inset: 0 0 0 0;
    z-index: 10;
    margin: -4px -6px;

    background: ${ p => p.active ? ( p.sub ? p.theme.palette.colors.subcyan : p.theme.palette.colors.cyan ) : 'transparent' };
`;

