import { useCallback, useEffect, useMemo, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { exposeStrapiError, normalizeStrapiRegister, valid } from "utils";
import { toast } from "react-toastify";
import { formPersonalData } from "utils/forms";

import { Create, ReadOne, Update } from "services/constructions";
import { ReadAddressesByZipCode } from "services/api";

export default function useController(){   

    const history = useHistory(); 
    const back = () => { history.goBack() ;}
    const navigate = to => history.push(`/${ to }`); 

    const { id } = useParams()

    const refForm = useRef() 
    
    const [contract, setContract] = useState(null)
    const [addendum, setAddendum] = useState([])

    const [register, setRegister] = useState(null)
    const [loading, setLoading] = useState(false) 
    
    const [active, setActive] = useState(0)

    const columns = [
        { title:'ID', ref:'id' },
        { title:'Item', ref:'item' },
        { title:'Escopo do serviço', ref:'service_scope' },
        { title:'Qt.', ref:'quantity' },
        { title:'Un.', ref:'unit' },
        { title:'Custo Un. Mão de obra', ref:'unitJob' },
        { title:'Custo total Mão de obra', ref:'totalJob' },
        { title:'Custo Un. Material', ref:'unitMaterial' },
        { title:'Custo total Material', ref:'totalMaterial' },
        { title:'Verba com RT', ref:'rtBudget' },
        { title:'Valor sem RT', ref:'rtWithout' },
    ] 

    const [rows, setRows] = useState([ ])

    const actions = [
        { title:'Imprimir', outline:true, icon:'print', primary:true, action:() => null },
        { title:'Baixar', outline:true, icon:'download', primary:true, action:() => null },
        // { title:'Cancelar', outline:true, action:() => history.goBack() },
        // { title:'Salvar', action:() => save(), loadable:true }
    ]

    const constract_options = useMemo(() => {
        return [
            { title:"Contrato principal" },
            ...(addendum?.map((m,k) => ({ title:`A${k+1}` })))
        ] 
    }, [ addendum ])

    const parseRow = item => {
        return ({ 
            id:"1234", 
            item:"1.1", 
            service_scape:"Equipamentos gerais de obra de locação", 
            quantity:"1", 
            unit:"Mês", 
            unitJob:"R$ 6.123,12", 
            totalJob:"R$ 6.123,12", 
            unitMaterial:"R$ 6.123,12", 
            totalMaterial:"R$ 6.123,12", 
            rtBudget:"R$ 6.123,12", 
            rtWithout:"R$ 6.123,12" 
        })
    }
    
    const init = useCallback(async () => {
        setLoading(true)
        const result = await ReadOne(id)
        if(result?.data?.id){
            const normalResult = normalizeStrapiRegister(result)
            console.log("normalResult", normalResult)
            setRegister({ ...normalResult, customer: normalResult?.customer?.name })

            setAddendum(normalResult?.addendum)
            setContract(normalResult?.contracts?.[0])
        }
        setLoading(false)
    }, [id])
    
    useEffect(() => { if(id){ init() ;} }, [id, init])

    return {
        refForm, 
        register,
        loading, 

        actions,

        active, 
        setActive, 
        
        constract_options,

        columns,
        rows
    }
}