import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { ContentTableAction, ContentTableActionIconDone, ContentTableActionIconEdit, ContentTableActionIconRemove, ContentTableInput, ContentTableItem } from "./styled";
import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister, valid } from "utils";
import { toast } from "react-toastify";

import { Read, ReadOne } from "services/constructions";

import Input from "components/Form/Input";
import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import { parseDatestringBRUS, parseOptions } from "utils/parsers";
import { Create } from "services/requests";
import { formBuyRequest } from "utils/forms";

export default function useController(){   

    const history = useHistory(); 
    const back = () => { history.goBack() ;}
    const navigate = to => history.push(`/${ to }`); 

    const { id } = useParams()

    const refForm = useRef() 
     
    const [register, setRegister] = useState(null)
    const [loading, setLoading] = useState(false) 
    
    const [editing, setEditing] = useState(null) 

    const { setModal } = useContext(CoreContext)

    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 
    
    const [ constructionOptions, setContructionOptions ] = useState([])

    const columns = [
        { 
            title:'Descrição', 
            renderCell: ({ row }) => <>
                { (row?.form && (!editing || editing?.id)) ? null : !((row?.form && editing) || editing?.id === row?.id) ? row?.details : <Button nospace onClick={() => updateItemMaterial(row)}>{ row?.details }</Button> }
            </>
        },
        { 
            title:'Item', 
            renderCell: ({ row }) => <>
                { (row?.form && (!editing || editing?.id)) ? null : !((row?.form && editing) || editing?.id === row?.id) ? row?.item : <Button nospace onClick={() => addContractItem(row)}>{ row?.item }</Button> }
            </>
        },
        { 
            title:'Qt.', 
            renderCell: ({ row }) => <>
                { (row?.form && (!editing || editing?.id)) ? null : !((row?.form && editing) || editing?.id === row?.id) ? row?.quantity : 
                    <ContentTableInput>
                        <Input value={formValue('quantity')} onChange={e => changeForm(e.target.value, 'quantity')} /> 
                    </ContentTableInput>
                }
            </>
        },
        { 
            title:'Un.', 
            renderCell: ({ row }) => <>
                {(row?.form && (!editing || editing?.id)) ? null : !((row?.form && editing) || editing?.id === row?.id) ? row?.unit : 
                    <ContentTableInput>
                        <Input value={formValue('unit')} onChange={e => changeForm(e.target.value, 'unit')} /> 
                    </ContentTableInput>
                }
            </>
        },

        { 
            title:'Verba com RT', 
            color:"backgroundgrey",
            renderCell: ({ row }) => <>
                {(row?.form && (!editing || editing?.id)) ? null : row?.valueRt  }
            </>
        },
        { 
            title:'Valor', 
            color:"backgroundgrey",
            renderCell: ({ row }) => <>
                {(row?.form && (!editing || editing?.id)) ? null : row?.value  }
            </>
        },
        // { 
        //     title:'Saldo (em R$)', 
        //     color:"backgroundgrey",
        //     renderCell: ({ row }) => <>
        //         {(row?.form && (!editing || editing?.id)) ? null : row?.balance  }
        //     </>
        // },
        // { 
        //     title:'Saldo (%)', 
        //     color:"backgroundgrey",
        //     renderCell: ({ row }) => <>
        //         {(row?.form && (!editing || editing?.id)) ? null : row?.balancePercent  }
        //     </>
        // },

        
        { 
            title:' ', 
            color: (editing) ? "subtotal" : null,
            renderCell: ({ row }) => (row?.form && (!editing || editing?.id)) ? null : <> 
                <ContentTableAction>
                    {
                        (editing?.id === row?.id) || (row?.form && editing && !editing?.id) ? 
                            <ContentTableItem onClick={() => saveRow(row)}>
                                <ContentTableActionIconDone />
                            </ContentTableItem>
                                :
                            <ContentTableItem onClick={() => openEdit(row)}>
                                <ContentTableActionIconEdit />
                            </ContentTableItem>
                    }
                    <ContentTableItem onClick={() => remove(row)}>
                        <ContentTableActionIconRemove />
                    </ContentTableItem>
                </ContentTableAction>
            </>
        }

    ] 

    const [rows, setRows] = useState([ ])

    const actions = [
        { title:'Imprimir', small:true, outline:true, icon:'print', primary:true, action:() => null },
        { title:'Baixar', small:true, outline:true, icon:'download', primary:true, action:() => null },
    ]

    const actionsForm = [
        { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Salvar', action:() => save(), loadable:true }
    ]

    const formData = useMemo(() => {
        if( !!id ) return formBuyRequest;
        return [
            ...formBuyRequest?.map(m => m?.ref === 'name' ? ({
                ...m,
                options: constructionOptions
            }) : m ) 
        ]
    }, [constructionOptions])
    
    const save = async () => {
        setLoading(true)

        const form = refForm?.current?.getForm()

        const payload = {
            type: 'buy',
            delivery_date: parseDatestringBRUS(form?.date),
            order_number: form?.order_number,
            requester: form?.requester,
            construction: id ? id : form?.name,
            RequestItem:[
                ...rows?.map(m => ({
                    unit: m.unit,
                    quantity: m.quantity,
                    contract_item: m?.contractItem?.id,
                    material: m?.id
                }))
            ]
        }

        console.log("Request payload", payload)

        const result = await Create({ data:payload })

        if(result && !exposeStrapiError(result)){
            console.log("result", result)
            toast.success("Solicitação enviada com sucesso!")
            history.goBack()
        }

        setLoading(false)
    }

    const saveRow = async (row) => {
        setLoading(true)

            setRows([
                ...rows?.map(m => m.id === row.id ? {
                    ...m,
                    ...form
                } : m)
            ])

            setForm({ })

        setEditing(false)
        setLoading(false)
    }
    
    const init = useCallback(async () => {
        setLoading(true)
        if(id){
            const result = await ReadOne(id)
            if(result?.data?.id){
                const normalResult = normalizeStrapiRegister(result)
                setRegister({ ...normalResult, customer: normalResult?.customer?.name })
            } 
        } else {
            const result = await Read()
            if(result?.data?.length){
                const normalResult = normalizeStrapiList(result)
                setContructionOptions(normalResult?.map(parseOptions))
            } 
        }
        setLoading(false)
    }, [id])
    
    useEffect(() => {  init() ;}, [id, init])

    const remove = async row => {
        setRows([
            ...rows?.filter(m => m.id !== row.id )
        ])
    }

    const addContractItem = (row) => {
        setModal({
            contract_id: id ? id : form?.name,
            req_type: 'material',
            
            selected:[ row?.contractItem ],
            type: "contract-items",
            row,
            single: true,
            confirm: (a,b) => addSuccessContract(a,b)
        })
    } 

    const addItem = () => {
        setModal({
            selected: rows,
            type: "materials",
            confirm: val => addSuccess(val)
        })
    } 

    const updateItemMaterial = (row) => {
        setModal({
            selected: [row],
            type: "materials",
            row,
            single: true,
            confirm: (a,b) => addSuccessMaterial(a,b)
        })
    } 

    const addSuccess = (selected) => {
        setRows([ ...selected ])
    }

    const addSuccessMaterial = (selected, row) => {

        const currentItem = selected?.[0]  

        if(!!currentItem){
            setRows([
                ...rows?.map(m => m.id === row.id ? {
                    ...m,
                    ...currentItem
                } : m)
            ])
        }

    }

    const addSuccessContract = (selected, row) => {

        const currentItem = selected?.[0] 
        if(!!currentItem){
            setRows([
                ...rows?.map(m => m.id === row.id ? {
                    ...m,
                    contractItem: currentItem,
                    item: currentItem?.item,
                    valueRt: currentItem?.value,
                } : m)
            ])
        }

    }

    const openEdit = (row) => { 
        setEditing(row); 
        setForm({ unit: row.unit, quantity: row.quantity }) 
    }

    return {
        refForm, 
        register,
        loading, 

        actions,
        actionsForm,
 
        columns,
        rows,

        addItem,
        formData
    }
}