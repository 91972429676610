import styled from 'styled-components'  


export const FormActions = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
`;


export const ObservationTitle = styled.div.attrs({ 
})`
    font-size: 14px;
    color: ${ p => p.theme.palette.colors.text };
    margin: 12px 6px;
`;

export const ObservationContent = styled.div.attrs({ 
})`
    font-size: 14px;
    color: ${ p => p.theme.palette.colors.black };
    padding: 12px 16px;
    background: ${ p => p.theme.palette.colors.white };
    border: 1px solid ${ p => p.theme.palette.colors.bordershadow };
`;

