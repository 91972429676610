import { hexToRgb } from 'ui/styled';
import styled from 'styled-components'  


export const ModalHeader = styled.div.attrs({ 
})`
    padding: 12px 0;
    display: flex;
    align-items: center;
`;

export const ModalSearchContent = styled.div.attrs({ 
})`
    padding: 0 0 12px 0;
    margin: 0 0 12px 0;
    border-bottom: 1px solid ${ props => props.theme.palette.colors.shadow };

    display: flex;
    align-items: center;
    justify-content: space-between;
`;


export const ModalHeaderTitle = styled.div.attrs({ 
})`
    font-size: 22px;
    font-weight: 700;
    color: ${ props => props.theme.palette.colors.text };
    flex:1;
`;


export const ModalHeaderIconClose = styled.img.attrs({ 
    src:'/icons/close.svg'
})`
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.02);
    }
`;

export const ModalHeaderActions = styled.div.attrs({ 
})`
    display: flex;
    gap: 12px;
    justify-content: flex-end;
`;


export const ModalCard = styled.div.attrs({ 
})`
    border-radius: 12px;
    padding: 16px;
    background: ${ props => props.theme.palette.colors.white };
`;


export const ModalBody = styled.div.attrs({ 
})`
    padding: 12px 0;
`;

