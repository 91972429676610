import { hexToRgb } from 'ui/styled';
import styled from 'styled-components'  


export const ModalHeader = styled.div.attrs({ 
})`
    padding: 12px 0;
    display: flex;
    align-items: center;
`;

export const ModalSearchContent = styled.div.attrs({ 
})`
    padding: 0 0 12px 0;
    margin: 0 0 12px 0;
    border-bottom: 1px solid ${ props => props.theme.palette.colors.shadow };

    display: flex;
    align-items: center;
    justify-content: space-between;
`;


export const ModalHeaderTitle = styled.div.attrs({ 
})`
    font-size: 22px;
    font-weight: 700;
    color: ${ props => props.theme.palette.colors.text };
    flex:1;
`;


export const ModalHeaderIconClose = styled.img.attrs({ 
    src:'/icons/close.svg'
})`
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.02);
    }
`;

export const ModalHeaderActions = styled.div.attrs({ 
})`
    display: flex;
    gap: 12px;
    justify-content: flex-end;
`;


export const ModalCard = styled.div.attrs({ 
})`
    border-radius: 12px;
    padding: 16px;
    background: ${ props => props.theme.palette.colors.white };
`;


export const ModalBody = styled.div.attrs({ 
})`
    padding: 12px 0;
`;



export const PhotoContent = styled.div.attrs({ 
})`
    width: 100%;
    aspect-ratio: 1 / 1 ;
    ${
        p => p.photo ? `
            background: url(${ p.photo }) no-repeat center center / cover;
        ` : ``
    }
`;



export const UploadContent = styled.div.attrs({ 
})`
    height: 56px;
    border: 1px solid ${ props => props.theme.palette.colors.shadow };
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
    color: ${ props => props.theme.palette.colors.grey };
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.03);
    }
    margin-top: 12px;
    padding: 0 12px;
    ${
        p => p.download ? `
            color: ${ p.theme.palette.colors.secondary };
        ` : ``
    }
`;


export const UploadContentIcon = styled.img.attrs({ 
    src: '/icons/upload.svg'
})`
    ${
        p => p.download ? `
            transform: rotate(180deg)
        ` : ``
    }
`;









export const WrapperPaymentMethod = styled.div.attrs({ 
})`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 20px;
`;
export const PaymentMethodItem = styled.div.attrs({ 
})`
    padding: 12px;
    background: ${ p => p.theme.palette.colors.white };
    border: 1px solid ${ p => p.theme.palette.colors.shadow };
    border-radius: 4px;
`;
export const PaymentMethodContentInfo = styled.div.attrs({ 
})`
`;
export const PaymentMethodContentInfoText = styled.div.attrs({ 
})`
`;