export const optionsStates = [
    { id:1, title:'AC', name:'Acre' },
    { id:2, title:'AL', name:'Alagoas' },
    { id:3, title:'AP', name:'Amapa' },
    { id:4, title:'AM', name:'Amazonas' },

    { id:5, title:'BA', name:'Bahia' },
    { id:6, title:'CE', name:'Ceara' },
    { id:7, title:'DF', name:'Distrito Federal' },
    { id:8, title:'ES', name:'Espirito Santo' },
    { id:9, title:'GO', name:'Goias' },
    { id:10, title:'MA', name:'Maranhão' },

    { id:11, title:'MT', name:'Mato Grosso' },
    { id:12, title:'MS', name:'Mato Grosso do Sul' },
    { id:13, title:'MG', name:'Minas Gerias' },
    { id:14, title:'PA', name:'Pará' },
    { id:15, title:'PB', name:'Paraíba' },
    
    { id:16, title:'PR', name:'Paraná' },
    { id:17, title:'PE', name:'Pernambuco' },
    { id:18, title:'PI', name:'Piauí' },
    { id:19, title:'RJ', name:'Rio de Janeiro' },
    
    { id:20, title:'RN', name:'Rio Grande do Norte' },
    { id:21, title:'RS', name:'Rio Grande do Sul'  },
    { id:22, title:'RO', name:'Rondônia'  },
    { id:23, title:'RR', name:'Roraima' },
    
    { id:24, title:'SC', name:'Santa Catarina' },
    { id:25, title:'SP', name:'São Paulo' },
    { id:26, title:'SE', name:'Sergipe' },
    { id:27, title:'TO', name:'Tocântins' },
]

export const optionsBoolean = [
    { id:1, title:'Sim' },
    { id:2, title:'Não' }
]

export const optionsPix = [
    { id:'cpf', title:'CPF' },
    { id:'phone', title:'Celular' },
    { id:'email', title:'E-mail' },
    { id:"outro", title:'Chave aleatória' }
]

export const optionsBankAccount = [
    { id:'chain', title:'Corrente' },
    { id:'savings', title:'Poupança' }
]

export const optionsPersonType = [
    { id:'pf', acron:"PF", title:'Pessoa física' },
    { id:'pj', acron:"PJ", title:'Pessoa jurídica' }
]

export const optionsRate = [
    { id:'good', title:'Bom', color:"green" },
    { id:'neutral', title:'Normal', color:"yellow" },
    { id:'terrible', title:'Ruim', color:"red" }
]


export const optionsStatus = [
    { id:1, title:'Ativo', color:"green" },
    { id:2, title:'Inativo', color:"red" }
]

export const optionsPeriod = [
    { id:1, title:'Diurno' },
    { id:2, title:'Noturno' }
]

export const optionsContractType = [
    { id:1, title:'Hibrido' }
]

export const optionsSupplierCategory = [
    { id:'eletric', title:'Elétrica' },
    { id:'hidraulic', title:'Hidraulica' },
    { id:'maintain', title:'Manutenção' },
]

export const optionsColaboratorSector = [
    { id:1, title:'Suprimentos' },
]

export const optionsCivilState = [
    { id:'single', title:'Solteiro' },
    { id:'married', title:'Casado' },
    { id:'widower', title:'Viuvo' },
    { id:'divorced', title:'Divorciado' }
]

export const optionsMaterialCategory = [
    { id:1, title:'Gesso' }
]

export const optionsMaterialSubcategory = [
    { id:1, title:'Forro' },
    { id:2, title:'Parede' }
]



/////



export const optionsUserCategory = [
    { title:'Engenheiro', id:"engineer" },
]

export const optionsBuildingType = [
    { title:'Residêncial', id:"residential" },
]

export const optionsAddressType = [
    { title:'Residêncial', id:"residential" },
    { title:'Comercial', id:"commercial" },
]

export const optionsBuildingContractType = [
    { title:'Híbrido', id:"hybrid" },
]

export const optionsBuildingPeriod = [
    { title:'Diurno', id:"daytime" },
]

export const optionsBuildingStatus = [
    { title:'Em andamento', id:"progress" },
    { title:'Finalizada', id:"finished" }
]

export const optionsDailyPhotoCategory = [
    { title:'Vistoria', id:"vistory" },
    { title:'Outros', id:"others" }
]
