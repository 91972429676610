import React from "react";  

import { 
    FormActions, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import { formAddress, formBuyOrderData, formBuyOrderPayment, formBuyOrderSupply, formContacts, formPaymentData, formPaymentInvoice, formPersonalDataCustomer, formSupplierData, formSupplimentCondition } from "utils/forms";
import useController from "./controller";
import DashboardCardTable from "components/Cards/Table";
import BasicTable from "components/Form/Table";
import { ContentRow, EmptyDiv, HorizontalScroll, HorizontalScrollContent } from "ui/styled";
import Button from "components/Form/Button";

export default function DashboardDetailsConstructionMeasurements(){   
    

    const { 

        changeActive,
        order_id,
        
        refForm, 
        register,
        loading, 

        actions,

        active, 
        setActive,  
        rows,
        columns,
        formActions,


        columnsTotals,
        rowsSubtotal,
        rowsTotal,

        orders_options,


        refFormSupplier,
        refFormPayment,
        refFormInvoice,
        refFormCondition,

    } = useController()


    return ( 
        <>
        <ContainerAuthenticated noside page={2} subpage={5}>
            <DashboardCardWrapper backable title={`Medição - ${ register?.name }`} actions={formActions} loading={loading}>

                <HorizontalScroll>
                    <HorizontalScrollContent>
                        {
                            orders_options?.map((item, key) => 
                                <EmptyDiv key={key}>
                                    <Button outline={`${item.id}` !== order_id} onClick={() => changeActive(item)} primary>{ item.title }</Button>
                                </EmptyDiv>
                            )
                        }
                    </HorizontalScrollContent>
                </HorizontalScroll>

                 <DashboardCardWrapper closeable title={"Dados da ordem de compra"} white actions={actions}>
                    <DashboardFormsCore formItems={formBuyOrderData} register={register} ref={refForm} />
                </DashboardCardWrapper>

                 <DashboardCardWrapper closeable title={"Listagem de item"} white>
                    <DashboardCardTable title={"1.0 - Serviços preliminares"} rows={rows} columns={columns} blue />

                    <ContentRow>
                        <BasicTable noHeader columns={columnsTotals} rows={rowsSubtotal} />
                        <BasicTable noHeader columns={columnsTotals} rows={rowsTotal} />
                    </ContentRow>

                </DashboardCardWrapper>


                <DashboardCardWrapper closeable title={"Dados do fornecedor"} white>
                    <DashboardFormsCore formItems={formSupplierData} register={register} ref={refFormSupplier} />
                </DashboardCardWrapper>

                 <DashboardCardWrapper closeable title={"Formas de pagamento"} white>
                    <DashboardFormsCore formItems={formPaymentData} register={register} ref={refFormPayment} />
                </DashboardCardWrapper>

                 <DashboardCardWrapper closeable title={"Nota fiscal"} white>
                    <DashboardFormsCore formItems={formPaymentInvoice} register={register} ref={refFormInvoice} />
                </DashboardCardWrapper>

                 <DashboardCardWrapper closeable title={"Condição de fornecimento"} white>
                    <DashboardFormsCore formItems={formSupplimentCondition} register={register} ref={refFormCondition} />
                </DashboardCardWrapper>

                {/*<DashboardCardWrapper closeable title={"Endereço do cliente"} white>
                    <DashboardFormsCore formItems={formAddress} register={register} ref={refFormAddress} />
                </DashboardCardWrapper>
                <DashboardCardWrapper closeable title={"Contatos"} white>
                    <DashboardFormsCore formItems={formContacts} register={register} ref={refFormContact} />
                </DashboardCardWrapper>*/}

                <FormActions> 
                    <DashboardActions actions={formActions} />
                </FormActions> 

            </DashboardCardWrapper>
        </ContainerAuthenticated>
        </>
    );
}