import React, { useContext, useEffect, useRef, useState } from "react";

import { 
    ModalBody, 
    ModalCard, 
    ModalHeader, 
    ModalHeaderActions, 
    ModalHeaderIconClose, 
    ModalHeaderTitle, 
    PaymentMethodContentInfo, 
    PaymentMethodContentInfoText, 
    PaymentMethodItem, 
    PhotoContent, 
    UploadContent, 
    UploadContentIcon, 
    WrapperPaymentMethod
} from "./styled";

import ModalWrapper from "../Wrapper";

import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import { ButtonContent } from "ui/styled";

import { exposeStrapiError, normalizeStrapiRegister, parseStrapiDate, parseStrapiImage } from "utils";

import FormCore from "components/Dashboard/FormCore";
import UploadFile from "components/Form/UploadFile";
import { ReadOne, Read, Create } from "services/medition-payments";

import { Create as CreateChorno } from "services/chronograms";

import { toast } from "react-toastify";
import { optionsDailyPhotoCategory } from "utils/options";
import { parseDatestringBRUS } from "utils/parsers";
import Radio from "components/Form/Radio";
import moment from "moment/moment";

export default function ModalPayment(){

    const { modal, setModal } = useContext(CoreContext)

    const [loading, setLoading] = useState(false)

    const refForm = useRef() 
     
    const [register, setRegister] = useState(null)

    const [photo, setPhoto] = useState(null)
    const [photoInvoice, setPhotoInvoice] = useState(null)

    const [methodSelected, setMethodSelected] = useState(0)

    const availablePaymentMethods = [
        {
            title:"Pix",
            type:'pix',
            infos:[
                { text:"Tipo de chave: CPF" },
                { text:"Chave: (11) 98765-4321" },
            ]
        },
        {
            title:"TED",
            type:'ted',
            infos:[
                { text:"Código do banco: 1234" },
                { text:"Agência: 1234" },
                { text:"Número da conta: 123456-7" },
                { text:"Tipo de conta: Corrente" },
            ]
        },
        {
            title:"Boleto",
            type:'invoice',
            infos:[
                { text:"Tipo de chave: CPF" },
                { 
                    children: <>
                        <UploadFile onChange={setPhoto}>
                            { 
                                photo ? 
                                <UploadContent download >
                                    <UploadContentIcon download />
                                    { photo?.name }
                                </UploadContent>
                                :
                                <UploadContent>
                                    <UploadContentIcon />
                                    Upload de nota fiscal
                                </UploadContent>
                            }
                        </UploadFile>
                    </>    
                },
            ]
        },
    ]
 
    const close = () => {
        setModal(null)
    }

    const save = async () => {
        setLoading(true)

        const payload = {
            construction: modal?.construction,

            medition: modal?.row?.id,
            payment_method:  availablePaymentMethods?.[methodSelected]?.type,
            invoice: photo?.id,
            bank_invoice: photoInvoice?.id,

        }

        const saved = await Create({ data:payload })

        const date1 = moment(modal?.row?.item?.date_medition);
        const date2 = moment(modal?.row?.item?.date_receive);

        const diffInDays = date2.diff(date1, 'days');
        
        
        const payloadChrono = {
            construction: modal?.construction,

            tasks:[
                {
                    title: modal?.row?.services,
                    responsible: modal?.row?.supplier,
                    progress: 0,
                    date_start: modal?.row?.item?.date_medition,
                    date_end: modal?.row?.item?.date_receive,
                    days: diffInDays,
                    value: modal?.row?.raw_value,
                }
            ]
        }
        
        // console.log("payloadChrono", payloadChrono)
        const savedChrono = await CreateChorno({ data:payloadChrono })


        if( savedChrono && !exposeStrapiError(savedChrono)){
            toast.success("Task criada")
        }

        if( saved && !exposeStrapiError(saved)){
            toast.success("Salvo com sucesso")
            await modal?.init()
            close()
        }
        
        setLoading(false)
    } 

    const confirm = async () => {
        await save()
    } 


    const init = async () => {
        setLoading(true)

        const result = await Read(modal?.row?.id)

        console.log(result, modal?.row?.id)
        if(result?.data?.[0]){
            const sresult = result?.data?.[0]
    
            const normalResult = normalizeStrapiRegister(sresult)
            const parsedResult = {
                ...normalResult,
                invoice: !normalResult?.invoice ? null : normalizeStrapiRegister(normalResult?.invoice),
                bank_invoice: !normalResult?.bank_invoice ? null : normalizeStrapiRegister(normalResult?.bank_invoice),
                construction: !normalResult?.construction ? null : normalizeStrapiRegister(normalResult?.construction),
                medition: !normalResult?.medition ? null : normalizeStrapiRegister(normalResult?.medition),
            }
    
            if(parsedResult?.payment_method){
                setPhoto(parsedResult?.invoice)
                setPhotoInvoice(parsedResult?.bank_invoice)
        
                const keySeleted = availablePaymentMethods?.findIndex( f => f.type === parsedResult?.payment_method)
        
                setMethodSelected(keySeleted)
                setRegister(parsedResult)
            }

            console.log("Founded parsedResult", parsedResult)
        }


        setLoading(false)

    }

    useEffect(() => {
        if(modal?.row?.id){
            init()
        }
    }, [modal])
    
    return (
        <>
            <ModalWrapper>
                <ModalCard>
                    <ModalHeader>
                        <ModalHeaderTitle>Dados de pagamento</ModalHeaderTitle>
                        <ModalHeaderIconClose onClick={close} />
                    </ModalHeader> 
                    {
                        register ? 
                            <ModalBody>

                                <WrapperPaymentMethod>


                                    {
                                        availablePaymentMethods?.filter(f => f.type === register?.payment_method )?.map((item, key) => 
                                            <PaymentMethodItem key={key}>
                                                <Radio label={ item?.title } checked={methodSelected === key} onChange={ () => setMethodSelected(key) } />
                                                <PaymentMethodContentInfo>
                                                    {
                                                        item?.infos?.map((m,k) =>
                                                            <PaymentMethodContentInfoText key={`${key}-${k}`}>{ m?.text || m?.children }</PaymentMethodContentInfoText>
                                                        )
                                                    }
                                                </PaymentMethodContentInfo>
                                            </PaymentMethodItem>
                                        )
                                    }

                                    {/* 
                                    <UploadContent download >
                                        <UploadContentIcon download />
                                        Baixar boleto bancário
                                    </UploadContent>

                                    <UploadContent download >
                                        <UploadContentIcon download />
                                        Baixar Nota fiscal
                                    </UploadContent> 
                                    */}

                                </WrapperPaymentMethod>

                                <UploadFile onChange={setPhotoInvoice}>
                                    {
                                        photoInvoice ? 
                                        <UploadContent download >
                                            <UploadContentIcon download />
                                            { photoInvoice?.name }
                                        </UploadContent>
                                            :
                                        <UploadContent>
                                            <UploadContentIcon />
                                            Upload de compovante de pagamento
                                        </UploadContent>
                                    }
                                </UploadFile>

                            </ModalBody>
                                :
                            <ModalBody>

                                <WrapperPaymentMethod>

                                    {
                                        availablePaymentMethods?.map((item, key) => 
                                            <PaymentMethodItem key={key}>
                                                <Radio label={ item?.title } checked={methodSelected === key} onChange={ () => setMethodSelected(key) } />
                                                <PaymentMethodContentInfo>
                                                    {
                                                        item?.infos?.map((m,k) =>
                                                            <PaymentMethodContentInfoText key={`${key}-${k}`}>{ m?.text || m?.children }</PaymentMethodContentInfoText>
                                                        )
                                                    }
                                                </PaymentMethodContentInfo>
                                            </PaymentMethodItem>
                                        )
                                    }

                                </WrapperPaymentMethod>

                                <UploadFile onChange={setPhotoInvoice}>
                                    {
                                        photoInvoice ? 
                                        <UploadContent download >
                                            <UploadContentIcon download />
                                            { photoInvoice?.name }
                                        </UploadContent>
                                        :
                                        <UploadContent>
                                            <UploadContentIcon />
                                            Upload de nota fiscal
                                        </UploadContent>
                                    }
                                </UploadFile>

                            </ModalBody>
                    }
                    <ModalHeaderActions>
                        <ButtonContent>
                            <Button secondary outline onClick={close}>Cancelar</Button>
                        </ButtonContent>
                        <ButtonContent>
                            <Button secondary loading={loading} onClick={register ? close : confirm}>Salvar</Button>
                        </ButtonContent>
                    </ModalHeaderActions>
                </ModalCard>
            </ModalWrapper>
        </>
    )
}