import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister, valid } from "utils";
import { toast } from "react-toastify";
import { formContractFilter, formPersonalData } from "utils/forms";

import { Read, Create, ReadOne, Update } from "services/constructions";
import { ReadAddressesByZipCode } from "services/api";
import Button from "components/Form/Button";
import { Read as ReadMedition } from "services/meditions";
import { ReadOne as ReadOneRequest } from "services/requests";
import { parseCurrency, parseDatestringUSBR } from "utils/parsers";
import { CoreContext } from "context/CoreContext";

export default function useController(){   

    const history = useHistory(); 
    const back = () => { history.goBack() ;}
    const navigate = to => history.push(`/${ to }`); 

    const { setModal } = useContext(CoreContext)
    const { id } = useParams()

    const refForm = useRef() 
     
    const [registers, setRegisters] = useState([])

    const [register, setRegister] = useState(null)
    const [loading, setLoading] = useState(false) 
    
    const [active, setActive] = useState(0)

    const [selected, setSelected] = useState([ ])

    const columns = [
        { title:'Vencimento', ref:'expires' },
        { title:'Fornecedor', ref:'supplier' },
        { title:'CNPJ', ref:'cnpj' },
        { title:'Parc.', ref:'parc' },
        { title:'Serviços', ref:'services' },
        { title:'Valor', ref:'value' },
        { title:'N. da NF', ref:'nnf' },
        { title:'Dado de pgto.', ref:'pgto_data' },
        { title:' ', renderCell: ({ row }) => <Button 
            onClick={() => setModal({ 
                type: "payment",
                construction: id,
                id: row?.id,
                row,
                init: () => init()
            })} 
            link nospace primary>
            Ver dados
        </Button> }
    ] 

    const columnsTwo = [
        { title:' ', ref:'ref' },
        { title:'Descrição', ref:'description' },
        { title:'Valor', ref:'value' },
        { title:'%', ref:'percent' }
    ] 

    const [rows, setRows] = useState([ ])
    const [rowsTwo, setRowsTwo] = useState([ ])

    const actions = [
        // { title:'Imprimir', outline:true, icon:'print', primary:true, action:() => null },
        // { title:'Baixar', outline:true, icon:'download', primary:true, action:() => null },
        // { title:'Cancelar', outline:true, action:() => history.goBack() },
        // { title:'Salvar', action:() => save(), loadable:true }
    ]

    const formActions = [
        // { title:'Cancelar', outline:true, action:() => history.goBack() }, 
        // { title:'Salvar', action:() => history.goBack(), loadable:true }
    ]

    const poptions = [
        { title:'Guia de pagamento' },
        { title:'Resumo da guia' }
    ]

    const formCTFilter = useMemo(() => {
        return formContractFilter?.map(item => ( item.ref === 'construction_name' ? {
            ...item,
            options: registers,
            onBlur: () => changeBuild()
        } : item ))
    }, [registers])

    const changeBuild = () => {
        const form = refForm?.current?.getForm()
        navigate(`dashboard/details/construction/payments/${form?.construction_name}`)
    }

    const save = async () => {
        setLoading(true)
        // const result = await 
        setLoading(false)
    }
 
    const parseRow = async item => {

        const resultRequest = await ReadOneRequest(item?.order?.data?.attributes?.request?.data?.id)
        const parsedRequest = normalizeStrapiRegister(resultRequest)

        console.log("parsedRequest", parsedRequest)

        const currentMode = parsedRequest?.type === 'service' ? `contract_item` : `material`

        const requestItems = parsedRequest?.RequestItem?.map(m => {

            const nitem = {
                ...m,
                ...normalizeStrapiRegister(m?.[currentMode]),
                id: m?.id
            }
    
            console.log("nitem", nitem)
    
            // const percent = (100 * nitem?.quantity) / nitem?.contract_item?.quantity

            return ({
                id: nitem?.id, 
                item: nitem?.item || nitem?.id, 
                service_scope: nitem?.title || nitem?.details, 
                quantity: nitem?.quantity || 1, 
                unit: nitem?.unit || 1, 
                unitJob:  parseCurrency(nitem?.value), 
                totalValue: parseCurrency(nitem?.value * (nitem?.quantity || 1)), 
                raw_unitJob:  (nitem?.value), 
                raw_totalValue: (nitem?.value * (nitem?.quantity || 1)), 
                nitem
            })

        })

        console.log("requestItems", requestItems)

        const accTotal = requestItems?.reduce((p, c) => p + c?.raw_totalValue, 0 )

        return ({ 
            expires: parseDatestringUSBR(item?.order?.data?.attributes?.request?.data?.attributes?.delivery_date),
            supplier: item?.order?.data?.attributes?.supplier?.data?.attributes?.name,
            cnpj: item?.order?.data?.attributes?.supplier?.data?.attributes?.cpf, 
            parc: "1", 
            services: requestItems?.[0]?.service_scope,
            value: parseCurrency(accTotal||0), 
            raw_value: (accTotal||0), 
            nnf: item?.invoice, 
            id: item?.id, 
            pgto_data: "Pix",
            item
        })
    } 
    
    const init = useCallback(async () => {
        setLoading(true)

        const all = await Read()
        const normalAll = normalizeStrapiList(all)
        setRegisters( normalAll?.map(m => ({ ...m, title: m?.name })) )

        const result = await ReadOne(id)
        if(result?.data?.id){
            const normalResult = normalizeStrapiRegister(result)
            
            console.log("normalResult", normalResult)
            
            setRegister({ 
                ...normalResult, 
                customer: normalResult?.customer?.name, 
                construction_name: id,
                date_from: parseDatestringUSBR(normalResult?.date_start),
                date_until: parseDatestringUSBR(normalResult?.date_end),
            })

            const resultMedition = await ReadMedition(id)
            const normalMeditions = normalizeStrapiList(resultMedition)
            console.log("normalMeditions", normalMeditions)

            const promises = normalMeditions.map(parseRow)
            const nextRows = await Promise.all(promises)
            
            setRows(nextRows)

            setRowsTwo([
                { ref:" ", description:"Contrato por empreita", value:"R$ 123.456.123,12", percent:"100%" },
                { ref:".", description:" ", value:" ", percent:" " },
                
                { ref:" ", description:"Enviado para pagamento", value:"R$ 123.456.123,12", percent:"2%" },
                { ref:" ", description:"Saldo à pagar empreita", value:"R$ 123.456.123,12", percent:"98%" },
                { ref:".", description:" ", value:" ", percent:" " },
                
                { ref:" ", description:"Contrato por administração (Valor estimado)", value:"R$ 123.456.123,12", percent:"100%" },
                { ref:".", description:" ", value:" ", percent:" " },
                
                { ref:" ", description:"Enviado para pagamento", value:"R$ 123.456.123,12", percent:"0%" },
                { ref:" ", description:"Saldo à pagar Administração (Valor estimado)", value:"R$ 123.456.123,12", percent:"100%" },
                { ref:".", description:" ", value:" ", percent:" " },
                
                { ref:" ", description:"Total geral dos contratos", value:"R$ 123.456.123,12", percent:"99%" },
                { ref:" ", description:"Valor total dos contratos enviados", value:"R$ 123.456.123,12", percent:"1%" },
                { ref:".", description:" ", value:" ", percent:" " },
                
                { ref:" ", description:"Total a pagar dos contratos", value:"R$ 123.456.123,12", percent:"98%" },
            ])
        }
        setLoading(false)
    }, [id])
    
    useEffect(() => { if(id){ init() ;} }, [id, init])

    return {
        refForm, 
        register,
        loading, 

        actions,

        active, 
        setActive, 

        columns,
        rows,
        formActions,


        columnsTwo,
        rowsTwo,
        poptions,

        formCTFilter,

        selected, setSelected
    }
}