import { GET, POST, PUT, DELETE } from './api'

const table = "requests"

export const Create = async (params) => {
    return await POST(`/${ table }`, params, true);
}

export const ReadAll = async () => {
    return await GET(`/${ table }?populate[0]=RequestItem.contract_item&populate[1]=RequestItem.material&populate[3]=construction`, true);
}

export const Read = async (id) => {
    return await GET(`/${ table }?filters[construction]=${id}&populate[0]=RequestItem.contract_item&populate[1]=RequestItem.material`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/${ table }/${id}?populate[0]=RequestItem.contract_item&populate[1]=RequestItem.material`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/${ table }/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/${ table }/${ id }`, true);
}