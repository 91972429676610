import { useCallback, useEffect, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { exposeStrapiError, normalizeStrapiRegister, valid } from "utils";
import { toast } from "react-toastify";
import { formPersonalData } from "utils/forms";

import { Create, ReadOne, Update } from "services/users";
import { ReadAddressesByZipCode } from "services/api";

export default function useController(){   

    const history = useHistory(); 
    const back = () => { history.goBack() ;}
    const navigate = to => history.push(`/${ to }`); 


    const { id } = useParams()

    const refForm = useRef()
    const refFormAddress = useRef()
    const refFormContact = useRef()
    
    const [register, setRegister] = useState(null)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    const actions = [
        { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Salvar', action:() => save(), loadable:true }
    ]
    
    const fillAddress = async zipCode => {
        const result = await ReadAddressesByZipCode(zipCode?.replace(/\.|\-/g,''))
        if(result){
            let dinamicAddress = {}
            if(result?.bairro){ dinamicAddress = { ...dinamicAddress, neighborhood: result?.bairro } ;}
            if(result?.uf){ dinamicAddress = { ...dinamicAddress, state: result?.uf } ;}
            if(result?.localidade){ dinamicAddress = { ...dinamicAddress, city: result?.localidade } ;}
            if(result?.logradouro){ dinamicAddress = { ...dinamicAddress, street: result?.logradouro } ;}
            if(result?.cep){ dinamicAddress = { ...dinamicAddress, zipcode: result?.cep } ;}
            setRegister({
                ...register,
                ...refForm.current.getForm(),
                address:{
                    ...register?.address,
                    ...refFormAddress.current.getForm(),
                    ...dinamicAddress
                },
                contact:{
                    ...register?.contact,
                    ...refFormContact.current.getForm(),
                },
            })
        }
    }
    
    
    const init = useCallback(async () => {
        setLoading(true)
        const result = await ReadOne(id)
        if(result?.data?.id){
            const normalResult = normalizeStrapiRegister(result)
            setRegister({
                ...normalResult
            })
        }
        setLoading(false)
    }, [id])

    const save = async () => {
        const form = refForm.current.getForm()
        const formAddress = refFormAddress.current.getForm()
        const formContact = refFormContact.current.getForm()
        
        const payload = {
            ...form,
            address:{
                ...formAddress,
            },
            contact:{
                ...formContact,
            }
        }

        delete payload.id

        if(!valid(payload, formPersonalData)){ toast.error("Preencha todos os campos"); return ;}

        setSaving(true)
        const result = id ? await Update({data:payload}, id) : await Create({data:payload})

        if(result && !exposeStrapiError(result)){            
            toast.success("Salvo")
            history.goBack()
        } else {
            toast.error("Erro ao salvar, tente novamente mais tarde")
        }

        setSaving(false)
    }

    useEffect(() => { if(id){ init() ;} }, [id, init])

    return {
        back,
        navigate,

        refForm,
        refFormAddress,
        refFormContact,
        
        fillAddress,
        
        register,
        loading,
        saving,

        actions,
    }
}