import { useCallback, useEffect, useMemo, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { exposeStrapiError, normalizeStrapiRegister, valid } from "utils";
import { toast } from "react-toastify";
import { formBuyOrderDelivery, formBuyOrderPayment, formPersonalData } from "utils/forms";

import { ReadOne } from "services/constructions";

import { ReadOne as ReadOneSupply } from "services/suppliers";
import { ReadOne as ReadOneRequest } from "services/requests";
import { parseCurrency, parseDatestringUSBR } from "utils/parsers";
import { optionsRate, optionsStates } from "utils/options";
import { Create } from "services/orders";
import { ReadAddressesByZipCode } from "services/api";

export default function useController(){   

    const history = useHistory(); 
    const back = () => { history.goBack() ;}
    const navigate = to => history.push(`/${ to }`); 

    const { id, request_id, supplier_id } = useParams()

    const refForm = useRef() 
    const refFormDelivery = useRef() 
    const refFormPayment = useRef() 
    const refFormCondition = useRef() 
     
    const [registerSupplier, setRegisterSupplier] = useState(null)
    const [registerRequest, setRegisterRequest] = useState(null)

    const [register, setRegister] = useState(null)
    const [loading, setLoading] = useState(false) 
    
    const [active, setActive] = useState(0)

    const columns = [
        { title:'Item', ref:'item' },
        { title:'Descrição', ref:'description' },
        { title:'Qd.', ref:'qtd' },
        { title:'Un.', ref:'unit' },
        { title:'Custo Unitário', ref:'unitCost' },
        { title:'Desconto Unitário', ref:'unitDisc' },
        { title:'Valor total', ref:'total', color: 'value' }
    ] 

    const columnsTotal = [
        { title:' ', ref:'nbsp' }, 
        { title:' ', ref:'nbsp' },
        { title:' ', ref:'nbsp' },
        { title:' ', ref:'title' },
        { title:' ', ref:'value', color: 'subtotal' }
    ] 

    const [rows, setRows] = useState([ ])

    const [rowsTotal, setRowsTotal] = useState([ ])

    const actions = [
        // { title:'Imprimir', outline:true, icon:'print', primary:true, action:() => null },
        // { title:'Baixar', outline:true, icon:'download', primary:true, action:() => null },
        // { title:'Cancelar', outline:true, action:() => history.goBack() },
        // { title:'Salvar', action:() => save(), loadable:true }
    ]

    const formActions = [
        // { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Salvar', outline:false, action:() => save() },
        // { title:'Finalizar', action:() => navigate('dashboard/construction/orders'), loadable:true }
    ]

    const formDelivery = useMemo(() => {
        return formBuyOrderDelivery?.map(m => m.ref === `delivery_zipcode` ? { ...m, onBlur: () => fillAddress(refFormDelivery, 'delivery') } : m)
    }, [refFormPayment, refFormDelivery, refFormCondition, refForm])

    const formPayment = useMemo(() => {
        return formBuyOrderPayment?.map(m => m.ref === `payment_zipcode` ? { ...m, onBlur: () => fillAddress(refFormPayment, 'payment') } : m)
    }, [refFormPayment, refFormDelivery, refFormCondition, refForm])
 
    const parseRow = (item, creq) => {

        const currentMode = creq?.type === 'service' ? `contract_item` : `material`

        const nitem = {
            ...item,
            ...item?.[currentMode]
        }

        return ({ 

            item: nitem?.item || nitem?.id, 
            description: nitem?.title || nitem?.details, 
            qtd: nitem?.quantity || 1, 
            unit: nitem?.unit || 1, 
            unitDisc: "R$ 0,00",
            unitCost: parseCurrency(nitem?.value), 
            total: parseCurrency(nitem?.value * (nitem?.quantity || 1)), 
            raw_unitCost: (nitem?.value||0), 
            raw_total: ((nitem?.value||0) * (nitem?.quantity || 1)), 

        })
    }
    
    const save = async () => {
        setLoading(true)

        const formDelivery = refFormDelivery?.current?.getForm()
        const formPayment = refFormPayment?.current?.getForm()
        const formCondition = refFormCondition?.current?.getForm()

        const payload = {
            supplier: supplier_id,
            construction: id,
            orderAddress:[
                {
                    zipcode: formPayment?.payment_zipcode?.replace(/\ |\-/g, ''),
                    street: formPayment?.payment_street,
                    number: parseInt(formPayment?.payment_number || 0),
                    complement: formPayment?.payment_complement,
                    state: optionsStates?.find(f => f.id === formPayment?.payment_state)?.title,
                    city: formPayment?.payment_city,
                    neighborhood: formPayment?.payment_neighborhood,
                }
            ],
            cno: formDelivery?.cno,
            responsible_engineer: formDelivery?.responsible_engineer,
            responsible: formDelivery?.responsible_construction,
            deliveryAddress:[
                {
                    zipcode: formDelivery?.delivery_zipcode?.replace(/\ |\-/g, ''),
                    street: formDelivery?.delivery_street,
                    number: parseInt(formDelivery?.delivery_number ||0),
                    complement: formDelivery?.delivery_complement,
                    state: optionsStates?.find(f => f.id === formDelivery?.payment_state)?.title,
                    city: formDelivery?.delivery_city,
                    neighborhood: formDelivery?.delivery_neighborhood,
                }
            ],
            request: request_id,

            prompt: formCondition?.delivery_deadline,
            payment_method: formCondition?.payment_type,
            invoice_method: formCondition?.invoice_type
        }

        const result = await Create({
            data:payload
        })

        if(result && !exposeStrapiError(result)){
            toast.success("Salvo com sucesso")
            navigate(`dashboard/details/construction/measurements/${id}`)
        }

        setLoading(false)
    }

    const fillAddress = async (formRef, mode) => {

        const zipCode = formRef?.current?.getForm()?.[`${mode}_zipcode`]
        const result = await ReadAddressesByZipCode(zipCode?.replace(/\.|\-/g,''))
    
        if(result){
            let dinamicAddress = {}
            if(result?.bairro){ dinamicAddress = { ...dinamicAddress, [`${mode}_neighborhood`]: result?.bairro } ;}
            if(result?.uf){ dinamicAddress = { ...dinamicAddress, [`${mode}_state`]: optionsStates?.find(f => f.title === result?.uf)?.id } ;}
            if(result?.localidade){ dinamicAddress = { ...dinamicAddress, [`${mode}_city`]: result?.localidade } ;}
            if(result?.logradouro){ dinamicAddress = { ...dinamicAddress, [`${mode}_street`]: result?.logradouro } ;}
            if(result?.cep){ dinamicAddress = { ...dinamicAddress, [`${mode}_zipcode`]: result?.cep } ;}
            setRegister({
                ...register,
                ...refForm.current.getForm(),
                
                ...refFormDelivery?.current?.getForm(),
                ...refFormPayment?.current?.getForm(),
                ...refFormCondition?.current?.getForm(),


                ...dinamicAddress,
                
            })
        }
    }

    const init = useCallback(async () => {
        setLoading(true)
        const result = await ReadOne(id)


        const resultRequest = await ReadOneRequest(request_id)
        const resultSupply = await ReadOneSupply(supplier_id)

        const parsedRequest = normalizeStrapiRegister(resultRequest)
        const parsedSupply = normalizeStrapiRegister(resultSupply)

        setRegisterSupplier({
            ...parsedSupply,
            // phone
            // contact_name
            // rg
            // cpf
        
        
            person_type: parsedSupply?.physical_person ? "pf" : "pj",
            supplier_type: "",
            supplier_category: parsedSupply?.category,
            website: parsedSupply?.site,
            supplier_status: parsedSupply?.status ? 1 : 2,
            supplier_rate: parsedSupply?.evaluation,
        
        
            // supplier
            orderNumber: "",
            supplier_phone: parsedSupply?.phone,
            supplier_contact: parsedSupply?.contact_name,
            supplier_email: parsedSupply?.email,
            payment_invoice: parsedSupply?.issue_invoice ? 1 : 2,
            payment_retain_invoice: "",
            payment_emit_invoice: parsedSupply?.issue_bill ? 1 : 2,
            payment_card: parsedSupply?.accepts_card ? 1 : 2,
            payment_pix_key: parsedSupply?.bank?.pix,
            payment_type_pix_key: parsedSupply?.bank?.type_pix,

            delivery_deadline: "",
            payment_type: "",
            invoice_type: "",

        })
        setRegisterRequest(parsedRequest)

        
        
        if(result?.data?.id){
            const normalResult = normalizeStrapiRegister(result)
            console.log("normalResult", normalResult)
            setRegister({ 
                ...normalResult, 
                
                // customer
                customer: normalResult?.customer?.name,
                construction: normalResult?.name,
                date: parseDatestringUSBR(normalResult?.date_start),
                buyOrder: parsedRequest?.order_number,

                zipcode: normalResult?.customer?.address?.zipcode,
                street: normalResult?.customer?.address?.street,

                number:normalResult?.customer?.address?.number,
                complement:normalResult?.customer?.address?.complement,
                state: optionsStates?.find(f => f.title === normalResult?.customer?.address?.state)?.id,
                city:normalResult?.customer?.address?.city,
                neighborhood:normalResult?.customer?.address?.neighborhood,
                type:normalResult?.customer?.address?.type,

                rgCnpj: 'pf',
                cpfCnpj: normalResult?.architect?.cpf,
                architecture: normalResult?.architect?.name,
                contact: normalResult?.architect?.name,
                phone: normalResult?.architect?.phone,
                email: normalResult?.architect?.email,
                
                // delivery
                delivery_zipcode:"",
                delivery_street:"",
                delivery_number:"",
                delivery_complement:"",
                delivery_state:"",
                delivery_city:"",
                delivery_neighborhood:"",

                responsible_engineer: "",
                cno: "",
                responsible_construction: "",

                // charge
                payment_zipcode:"",
                payment_street:"",
                payment_number:"",
                payment_complement:"",
                payment_state:"",
                payment_city:"",
                payment_neighborhood:"",

                payment_phone: "",
                payment_contact: "",
                payment_email: "",


            })
        }

        const nbsp = `
            \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 
            \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 
            \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 
            \u00A0 \u00A0 \u00A0 \u00A0 \u00A0
        `


        // read order list items, then


        const nxtRows = parsedRequest?.RequestItem?.map((m, k) => ({
            ...m,
            contract_item: normalizeStrapiRegister(m?.contract_item),
            material: normalizeStrapiRegister(m?.material)
        }))

        const nextRows = (nxtRows||[]).map((item) => parseRow(item, parsedRequest))

        setRows(nextRows)

        const subtotal = nextRows?.reduce((p, c) => p + (c?.[`raw_total`]||0),0)
        const taxrate = parsedRequest?.type === 'service' ? .17 : .35
        const taxes = taxrate * subtotal

        const shippingrate = .01
        const shippings = shippingrate * subtotal

        const discountrate = .05
        const discounts = discountrate * subtotal

        const total = ((taxes + shippings + subtotal) - discounts)

        setRowsTotal([
            { title:"Subtotal", value: parseCurrency(subtotal) , nbsp },
            { title:"Impostos", value: parseCurrency(taxes), nbsp },
            { title:"Desconto geral", value: parseCurrency(discounts), nbsp },
            { title:"Fretes", value: parseCurrency(shippings), nbsp },
            { title:"Total geral", value: parseCurrency(total), nbsp },
        ])


        console.log("Beginnig with", parsedRequest, parsedSupply)

        setLoading(false)
    }, [id])
    
    useEffect(() => { if(id){ init() ;} }, [id, init])

    return {
        refForm, 
        refFormDelivery, 
        refFormPayment, 
        
        register,
        loading, 

        actions,

        active, 
        setActive, 

        columns,
        rows,

        columnsTotal,
        rowsTotal,

        formActions,

        registerSupplier,
        registerRequest,

        refFormCondition,

        formDelivery,
        formPayment
    }
}