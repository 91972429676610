import styled from 'styled-components' 
import ReactLoading from 'react-loading';
import Lottie from 'react-lottie';

export const hexToRgb = (hex) => {
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return [(c>>16)&255, (c>>8)&255, c&255].join(',') ;
    }
    return `255,255,255`
}  

export const Touch = styled.div.attrs({ 
})`         
    cursor: pointer;
    &:hover{
        box-shadow: 0px 1px 3px ${ props => props.theme.palette.colors.shadow };
    }
`;

export const Load = styled(ReactLoading).attrs( p=> ({ 
    type:'spin',
    color: p.color ? p.theme.palette.colors?.[p.color] : '#ffffff',
    height:20,
    width:20
}))`          
`;
 
export const Animation = styled(Lottie).attrs( props => ({  
    options:{
        loop: true,
        autoplay: true, 
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
        animationData: props.animationData
    }, 
    width: props.width ? props.width : 320
}))`        
    max-width: 100%;
`;  

export const EmptyMessage = styled.div.attrs({ 
})`         
    padding: 32px; 
    text-align: center;
    font-size: 14px;
    color: ${ props => props.theme.palette.colors.black };
`;

export const LoadCenter = styled.div.attrs({ 
})`         
    width: 20px;
    margin: 32px auto; 
    display: flex;
    justify-content: center;
`;

export const ButtonCenter = styled.div.attrs({ 
})`         
    width: 100%;
    max-width: 200px;
    margin: 32px auto; 
    display: flex;
    justify-content: center;
`;

export const ButtonContent = styled.div.attrs({ 
})`         
    width: 100%;
    max-width: 200px;
`;

export const InputContent = styled.div.attrs({ 
})`         
    width: 100%;
    max-width: 200px;
`;

export const WrapContent = styled.div.attrs({ 
})`         
    display: flex;
    width: 100%;
    max-width: 420px;
    gap: 20px;
`;

export const DecoratedScroll = styled.div.attrs({ 
})`

    overflow: auto; 

    ::-webkit-scrollbar-thumb {
        background-color: ${ props => props.theme.palette.secondary.main };
        outline: 0 solid ${ props => props.theme.palette.secondary.main };
    }

    ::-webkit-scrollbar {
        width: 1px;
        height: .2rem;
    }

    ::-webkit-scrollbar-track {  
    }
`;










export const StatusItemContent = styled.div.attrs({ 
})`
    display: flex;
`;
export const StatusItem = styled.div.attrs({ 
})`
    padding: 2px 25px;
    min-width: 101px;
    border-radius: 8px;
    background: ${ p => p.theme?.palette?.colors?.[p.color]};
    margin: 0 0 0 auto;

    font-size: 14px;
    text-align:center;
    color: ${ props => props.theme.palette.colors.white };

    ${
         p => p.blacked ? `
            margin: 0;
            color: ${ p.theme.palette.colors.black };
         ` : ``
    }
    ${
         p => p.card ? `
            margin: 12px 0 0 ;     
         ` : ``
    }
`;

export const StatusItemAction = styled.div.attrs({ 
})`
    padding: 2px 25px;
    min-width: 101px;
    border-radius: 4px;
    background: ${ p => p.theme?.palette?.secondary?.main };
    margin: 0 0 0 auto;

    font-size: 14px;
    text-align:center;
    color: ${ props => props.theme.palette.colors.white };

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.03);
    }
`;









export const EmptyDiv = styled.div.attrs({ 
})`
`;

export const StartCenter = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
`;
export const CenterCenter = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
`;

export const EndCenter = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
`;

export const BetweenCenter = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
    ${
        p => p.headed ? `
            padding: 0 18px;
        ` : ``
    }
    ${
        p => p.topper ? `
            padding: 18px 0 0;
        ` : ``
    }
    ${
        p => p.spaced ? `
            padding: 0 0 18px;
        ` : ``
    }
`;

export const DashboardIcon = styled.img.attrs(p => ({ 
    src: `/icons/${ p.icon }.svg`,
    width: 24
}))`
    margin: 0 6px 0 0;
    ${ p => p.nospace ? `
        margin:0;
    ` : ``}
`;



export const HorizontalScroll = styled.div.attrs(p => ({ 
}))`
    overflow-x: auto;
    padding-bottom: 6px;
    margin-bottom: 6px;


    ::-webkit-scrollbar-thumb {
        background-color: ${ props => props.theme.palette.secondary.main };
        outline: 0 solid ${ props => props.theme.palette.secondary.main };
    }

    ::-webkit-scrollbar {
        width: 1px;
        height: .08rem;
    }

    ::-webkit-scrollbar-track {  
    }
`;

export const HorizontalScrollContent = styled.div.attrs(p => ({ 
}))`
    display: flex;
    gap: 12px;
`;







export const WhiteBg = styled.div.attrs({ 
})`
    background: ${ props => props.theme.palette.colors.white };
    display: flex;
    width: 20px;
    height: 20px;
    overflow: hidden;
    border-radius: 3px;
`;

export const CheckContainer = styled.div.attrs({ 
})`
    margin-top: -11px;

    width: 30px;
    height: 30px;

`;

export const ContentRow = styled.div.attrs({ 
})`
    display: flex;
    gap: 16px;
`;

export const ContentButton = styled.div.attrs({ 
})`
    width: 100%;
    max-width: 240px;
    margin-bottom: 16px;
`;