import React from "react";  

import { useHistory } from "react-router-dom";

import { ContentTableAction, ContentTableActionIconEdit, ContentTableActionIconRemove, ContentTableItem, DashboardTitle } from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import DashboardCardTable from "components/Cards/Table";
import DashboardCardWrapper from "components/Cards/Wrapper";

import Button from "components/Form/Button";
import { BetweenCenter, EmptyDiv, StatusItem, StatusItemContent } from "ui/styled";
import DashboardListActions from "components/Dashboard/ListActions";
import useController from "./controller";

export default function DashboardListContracts({ page }){  

    const  {
        loading,
        columns,
        rows,
        navigate
    } = useController()

    return ( 
        <>
            <ContainerAuthenticated page={ page || 2} subpage={0}> 
                <BetweenCenter spaced>
                    <DashboardTitle>Contratos e adendos</DashboardTitle>
                    {/* 
                    <EmptyDiv>
                        <Button secondary nospace>Novo</Button>
                    </EmptyDiv>
                    */}
                </BetweenCenter>
                <Row>  
                    <Col sm={12}>
                        <DashboardCardWrapper custom={ <DashboardListActions /> }>
                            <DashboardCardTable loading={loading} rows={rows} columns={columns} blue />
                        </DashboardCardWrapper>
                    </Col> 
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}