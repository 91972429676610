import React, { useEffect, useState } from "react";  

import { useHistory } from "react-router-dom";

import { ContentTableAction, ContentTableActionIconEdit, ContentTableActionIconRemove, ContentTableItem, DashboardTitle } from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import DashboardCardTable from "components/Cards/Table";
import DashboardCardWrapper from "components/Cards/Wrapper";

import Button from "components/Form/Button";
import { BetweenCenter, EmptyDiv, StatusItem, StatusItemContent } from "ui/styled";
import DashboardListActions from "components/Dashboard/ListActions";
import { ReadAll } from "services/requests";
import { normalizeStrapiList, normalizeStrapiRegister } from "utils";
import moment from "moment/moment";
import { parseCurrency, parseDatestringUSBR } from "utils/parsers";

export default function DashboardListMaintainControl(){  

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
 
    const columns = [
        { title:'Nome da obra', ref:'construction_name' },
        
        { title:'Solicitação', ref:'solicitation' },
        { title:'Categoria', ref:'category' },
        { title:'Descrição do serviço', ref:'description' },
        { title:'Requirente', ref:'supplier' },
        { title:'S. Visita', ref:'guest' },
        { title:'A. orçamento', ref:'budget' },
        { title:'Prog. exec.', ref:'prog' },
        { title:'Execução', ref:'executation' },
        { 
            title:'Status',  
            renderCell: ({ row }) => <>
                <StatusItemContent>
                    <StatusItem color={row?.status_color}>
                        { row?.status }
                    </StatusItem>
                </StatusItemContent>
            </>
        },        
        { 
            title:' ', 
            renderCell: ({ row }) => <>
                <ContentTableAction>
                    {/* <ContentTableItem  onClick={() => navigate(`dashboard/maintain/manual/edit/${ row.id }`)}>
                        <ContentTableActionIconEdit />
                    </ContentTableItem> */}
                    {/* <ContentTableItem onClick={() => remove(row)}>
                        <ContentTableActionIconRemove />
                    </ContentTableItem> */}
                </ContentTableAction>
            </>
        }
    ] 

    const [rows, setRows] = useState([
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Novo", status_color:"blue" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Novo", status_color:"blue" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Novo", status_color:"blue" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Novo", status_color:"blue" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Novo", status_color:"blue" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Novo", status_color:"blue" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Novo", status_color:"blue" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Novo", status_color:"blue" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Atendido", status_color:"green" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Atendido", status_color:"green" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Atendido", status_color:"green" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Atendido", status_color:"green" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Atendido", status_color:"green" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Atendido", status_color:"green" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Atendido", status_color:"green" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Atendido", status_color:"green" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Atendido", status_color:"green" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Atendido", status_color:"green" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Em aberto", status_color:"red" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Em aberto", status_color:"red" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Em aberto", status_color:"red" },
        // { construction_name:"Obra Alpha", solicitation:"01/02/2023", category:"Civil", description:"Descrição texto", supplier:"Nome do fornecedor", guest:"01/02/2023", budget:"01/02/2023", prog:"01/02/2023", executation:"01/02/2023", status:"Em aberto", status_color:"red" },
    ])
    

    // const [loading, setLoading] = useState(false)
    const remove = async row => {
        // setLoading(true)
        // const user = await ReadByEmail(row?.email)
        // if(user?.[0]?.id){
        //     await DeleteUser(user?.[0]?.id)
        // }
        // const result = await Delete(row?.id)
        // if(result && !exposeStrapiError(result)){
        //     toast.success('Removido com sucesso') ;
        // }
        // init()
    }

    const parseRowRequest = (item, creq) => {

        const currentMode = creq?.type === 'service' ? `contract_item` : `material`

        const nitem = {
            ...item,
            ...item?.[currentMode]
        }

        return ({ 

            item: nitem?.item || nitem?.id, 
            description: nitem?.title || nitem?.details, 
            qtd: nitem?.quantity || 1, 
            unit: nitem?.unit || 1, 
            unitDisc: "R$ 0,00",
            unitCost: parseCurrency(nitem?.value), 
            total: parseCurrency(nitem?.value * (nitem?.quantity || 1)), 
            raw_unitCost: (nitem?.value||0), 
            raw_total: ((nitem?.value||0) * (nitem?.quantity || 1)), 

        })
    }

    const parseRow = item => {

        const statuses = {
            'done': {
                status:"Atendido", 
                status_color:"green" 
            },
            'new': {
                status:"Novo", 
                status_color:"blue" 
            },
            'opened': {
                status:"Em aberto", 
                status_color:"red"
            }
        }

        const construction = !item?.construction ? null : normalizeStrapiRegister(item?.construction);

        const requestItems = item?.RequestItem?.map(m => ({
            ...m,
            material: !m?.material ? null : normalizeStrapiRegister(m?.material),
            contract_item: !m?.contract_item ? null : normalizeStrapiRegister(m?.contract_item),
        }))?.map(m => parseRowRequest(m, { item }))

        console.log(requestItems)

        return ({ 
            construction_name: construction?.name, 
            solicitation: moment(item?.createdAt)?.format('L'), 
            category: item?.type === 'buy' ? "Compra" : "Serviço", 
            description: requestItems?.find(f => f.description)?.description, 
            supplier: item?.requester, 
            guest:  moment(item?.updatedAt)?.format('L'), 
            budget: item?.order_number, 
            prog: parseDatestringUSBR(item?.delivery_date), 
            executation: parseDatestringUSBR(item?.delivery_date), 
            status:"Novo", 
            status_color:"blue" 
        })
    }


    const init = async () => {
        const result = await ReadAll()
        const normalResult = normalizeStrapiList(result)

        console.log("normalResult", normalResult)

        // const promises = normalResult.map( parseRow )
        // const nextRows = await Promise.all(promises)
        setRows(normalResult?.map(parseRow))
    }

    useEffect(() => {
        init()
    }, [])

    return ( 
        <>
            <ContainerAuthenticated page={7} subpage={8}> 
                <BetweenCenter spaced>
                    <DashboardTitle>Controle de manutenção</DashboardTitle>
                    {/* <EmptyDiv>
                        <Button secondary nospace>Novo</Button>
                    </EmptyDiv> */}
                </BetweenCenter>
                <Row>  
                    <Col sm={12}>
                        <DashboardCardWrapper custom={
                            <DashboardListActions />
                        }>
                            <DashboardCardTable rows={rows} columns={columns} blue />
                        </DashboardCardWrapper>
                    </Col> 
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}