import React from "react";  

import { 
    FormActions, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import { formAddress, formContacts, formPersonalDataCustomer } from "utils/forms";
import useController from "./controller";

export default function DashboardFormsRegisterCustomers(){   
    

    const {
        refForm,
        refFormAddress,
        refFormContact,
        
        fillAddress,
        register,
        registerContact,
        loading,
        saving,

        actions,
        remove,
        id
    } = useController()


    return ( 
        <>
        <ContainerAuthenticated page={1} subpage={1}>
            <DashboardCardWrapper backable title={"Novo cliente"} actions={actions} loading={loading}>

                <DashboardCardWrapper closeable title={"Dados do cliente"} white>
                    <DashboardFormsCore formItems={formPersonalDataCustomer} register={register} ref={refForm} />
                </DashboardCardWrapper>
                <DashboardCardWrapper closeable title={"Endereço do cliente"} white>
                    <DashboardFormsCore formItems={formAddress} register={register} ref={refFormAddress} />
                </DashboardCardWrapper>
                <DashboardCardWrapper closeable title={"Contatos"} white>
                    <DashboardFormsCore formItems={formContacts} register={registerContact} ref={refFormContact} />
                </DashboardCardWrapper>

                <FormActions> 
                    <DashboardActions removeable={!id ? null : remove} actions={actions} loading={saving} />
                </FormActions>

            </DashboardCardWrapper>
        </ContainerAuthenticated>
        </>
    );
}