import styled from 'styled-components'   
  

export const DashboardMenuContainer = styled.div.attrs({ 
})`           
    position: fixed;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: ${ props => props.theme.palette.colors.shadow };
`;

export const DashboardMenu = styled.div.attrs({ 
    className:'menu-contant'
})`           
    min-width: 252px;
    max-width: 252px;
    background: ${ props => props.theme.palette.colors.white };
    width: 100%;
    min-height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    overflow: auto;
    transition: all .3s ease;
    ${
        p => p.opened ? `` : `
            min-width: 64px;
            max-width: 64px;
        `
    }
`;

export const DashboardMenuHeader = styled.div.attrs({ 
})`           
    height: 60px;
    width: 100%; 
    display: flex;
    align-items: center;
    padding: 0 20px;

    text-transform: uppercase;
    font-size: 15px;
    color: ${ props => props.theme.palette.colors.white };
    cursor: pointer;

    background: ${ props => props.theme.palette.primary.main };
    background: linear-gradient(48deg, rgba(${props => props.theme.palette.primary.main},1) 0%, rgba(${props => props.theme.palette.primary.main},.9) 21%, rgba(${props => props.theme.palette.primary.main},.75) 49%, rgba(${props => props.theme.palette.primary.main},.6) 87%, rgba(${props => props.theme.palette.primary.main},.45) 100%);
`;

export const DashboardMenuHeaderIcon = styled.img.attrs({ 
})`           
    margin-right: 20px;
    cursor: pointer;
`;

export const DashboardMenuHeaderUserContent = styled.div.attrs({ 
})`           
    padding: 27px 25px;
    margin-bottom: 28px;
    background: ${ props => props.theme.palette.primary.main } ;
`;

export const DashboardMenuHeaderUserImage = styled.div.attrs({ 
})`           
    width: 160px;
    height: 160px; 
    border-radius: 80px; 
    background: ${ props => props.theme.palette.colors.grey } url(/logo1024.png) no-repeat center center / cover;
    margin: 0 auto 12px;
    overflow: hidden;
`;

export const DashboardMenuHeaderUserText = styled.div.attrs({ 
})`           
    font-size: 15px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.grey };
    margin-bottom: 12px; 
`;

export const DashboardMenuOption = styled.div.attrs({ 
})`           
    padding: 8px;
    font-size: 15px;
    color: ${ props => props.theme.palette.colors.grey };
    cursor: pointer; 

    &:hover{
        text-decoration: underline;
    }
    
    ${
        props => props.active ? `
            background: ${ props.theme.palette.primary.main };
            font-size: 15px;
            font-weight: bold;
            color: ${ props.theme.palette.colors.white };
        ` : ``
    }


    margin: 0 0 8px;
    border-radius: 8px;
    
    ${
        p => p.active ? `
            background: ${ p.theme.palette.secondary.main };
            color: ${ p.theme.palette.colors.white };
        ` : `
            background: ${ p.theme.palette.colors.white };
            color: ${ p.theme.palette.primary.main };
        `
    }

    font-size: 14px;
    font-weight: 600;

    display: flex;
    align-items: center;
    gap: 12px;
`;

export const DashboardMenuOptionIcon = styled.img.attrs(p => ({ 
    width: 24,
    src:`/icons/${ p.icon }-${ p.active ? 'on': 'off'}.svg`
}))`

`;

export const DashboardMenuContent = styled.div.attrs({ 
})` 
    flex:1;
    padding: 12px;
`;

export const DashboardMenuFooter = styled.div.attrs({ 
})`
    transition: all .3s ease;
    padding: 20px;
    ${
        p => !p.opened ? `
            padding: 0px;
        ` : ``
    }
`;

export const DashboardVersionContent = styled.div.attrs({ 
})` 
    margin: 24px 0;
`;

export const DashboardVersionText = styled.div.attrs({ 
})`
    font-size: 15px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.grey };
    text-align: center; 
`;





export const BackItem = styled.div.attrs({ 
})` 
    display: flex;
    align-items: center;
    padding: 12px 0;
    margin: 0 12px ;
    gap: 12px;

    border-bottom: 1px solid ${ props => props.theme.palette.colors.bordershadow };

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.02);
    }
`;

export const BackItemIcon = styled.img.attrs({ 
    width: 24,
    src:`/icons/chevron.svg`
})` 
`;

export const BackItemText = styled.div.attrs({ 
})` 
    font-size: 14px;
    font-weight: 500;
    color: ${ props => props.theme.palette.colors.text };
`;

export const PageItem = styled.div.attrs({ 
})` 
    display: flex;
    align-items: center;
    padding: 12px 0;
    margin: 0 12px ;
    gap: 12px;
    border-bottom: 1px solid ${ props => props.theme.palette.colors.lightshadow };
`;

export const PageItemIcon = styled.img.attrs(p => ({ 
    width: 24,
    src:`/icons/${ p.icon }-off.svg`
}))`
`;

export const PageItemText = styled.div.attrs({ 
})` 
    font-size: 16px;
    font-weight: 600;
    color: ${ props => props.theme.palette.primary.main };
`;
