import React from "react";  

import { 
    CommomTable,
    CommomTableRow,
    FormActions, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import { formAddress, formContacts, formPersonalDataCustomer } from "utils/forms";
import useController from "./controller";
import BasicTable from "components/Form/Table";
import Button from "components/Form/Button";
import { CenterCenter, ContentButton, StartCenter } from "ui/styled";

export default function DashboardDetailsContructionTimeline(){   

    const { 

        refForm, 
        register,
        loading, 

        actions,

        active, 
        setActive,  
        rows,
        columns,
        
        formActions,

        rowsTwo,
        rowsThree,

        columnsMonth

    } = useController()



    return ( 
        <>
        <ContainerAuthenticated noside page={2} subpage={7}>
            <DashboardCardWrapper backable title={`Cronograma - ${ register?.name }`} actions={formActions} loading={loading}>

                 <DashboardCardWrapper closeable title={"Serviços"} white actions={actions}>
                    {/* <DashboardFormsCore formItems={formContacts} register={register} ref={refForm} /> */}
                    <CommomTableRow>
                        <CommomTable>
                            <BasicTable blue columns={columns} rows={rows} />
                        </CommomTable>
                        <CommomTable>
                            <BasicTable blue columns={columnsMonth} rows={rows} centred />
                        </CommomTable>
                    </CommomTableRow>

                </DashboardCardWrapper>
              
              
                {/* <DashboardCardWrapper closeable title={"Diversos"} white> 
                    <CommomTableRow>
                        <CommomTable>
                            <BasicTable blue columns={columns} rows={rowsTwo} />
                        </CommomTable>
                        <CommomTable>
                            <BasicTable blue columns={columnsMonth} rows={rows} centred />
                        </CommomTable>
                    </CommomTableRow>
                </DashboardCardWrapper>
                <DashboardCardWrapper closeable title={"Alvenaria"} white> 
                    <CommomTableRow>
                        <CommomTable>
                            <BasicTable blue columns={columns} rows={rowsThree} />
                        </CommomTable>
                        <CommomTable>
                            <BasicTable blue columns={columnsMonth} rows={rows} centred />
                        </CommomTable>
                    </CommomTableRow>
                </DashboardCardWrapper> */}

                {/* <CenterCenter>
                    <ContentButton>
                        <Button outline secondary>Adicionar tarefa</Button>
                    </ContentButton>
                </CenterCenter> */}

                <FormActions> 
                    <DashboardActions actions={formActions}  />
                </FormActions> {/**/}

            </DashboardCardWrapper>
        </ContainerAuthenticated>
        </>
    );
}