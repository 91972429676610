import React, { useMemo } from "react";  

import { 
    FormActions, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import { formAddress, formContacts, formContractFilter, formPersonalDataCustomer } from "utils/forms";
import useController from "./controller";
import Button from "components/Form/Button";
import BasicTable from "components/Form/Table";
import { EmptyDiv, HorizontalScroll, HorizontalScrollContent } from "ui/styled";

export default function DashboardDetailsContructionPayments(){   
    


    const { 

        refForm, 
        register,
        loading, 

        actions,

        active, 
        setActive,  
        rows,
        columns,
        formActions,


        columnsTwo,
        rowsTwo,
        poptions,

        formCTFilter,

        selected, setSelected

    } = useController()

    const filterButton = useMemo(() => {
        return {
            custom: <Button outline primary nospace>
                Filtrar
            </Button>,
            quarter: true
        }
    }, [])

    return ( 
        <>
        <ContainerAuthenticated noside page={2} subpage={6}>
            <DashboardCardWrapper backable title={`Guias de pagamento - ${ register?.name }`} actions={formActions} loading={loading}>


                <HorizontalScroll>
                    <HorizontalScrollContent>
                        {
                            poptions?.map((item, key) => 
                                <EmptyDiv key={key}>
                                    <Button outline={active !== key} onClick={() => setActive(key)} primary>{ item.title }</Button>
                                </EmptyDiv>
                            )
                        }
                    </HorizontalScrollContent>
                </HorizontalScroll>


                <DashboardCardWrapper closeable title={"Dados da empreita"} white actions={actions}>
                    <DashboardFormsCore formItems={[ ...formCTFilter]} register={register} ref={refForm} />
                </DashboardCardWrapper>

                <DashboardCardWrapper closeable title={"Pagamentos"} white>
                    <BasicTable  selectable={!!active ? null : setSelected} selected={!!active ? null : selected} rows={rows?.filter(f => !active || ( !!active && selected?.map(m => m?.id)?.includes(f?.id) ) )} columns={columns} blue />
                </DashboardCardWrapper>

                {/* <DashboardCardWrapper closeable title={"Administração"} white>
                    <BasicTable rows={rows} columns={columns} blue />
                </DashboardCardWrapper> */}

                {
                    !active || true ? null : 
                    <DashboardCardWrapper closeable title={"Resumo financeiro"} white>
                        <BasicTable rows={rowsTwo} columns={columnsTwo} blue />
                    </DashboardCardWrapper> 
                }
                
                <FormActions> 
                    <DashboardActions actions={formActions}  />
                </FormActions>

            </DashboardCardWrapper>
        </ContainerAuthenticated>
        </>
    );
}