import React, { useEffect, useState } from "react";

import { Delete, Read } from "services/constructions";

import { ContentTableAction, ContentTableActionIconEdit, ContentTableActionIconRemove, ContentTableItem } from "./styled";
import { StatusItem, StatusItemContent } from "ui/styled";

import { exposeStrapiError, normalizeStrapiList } from "utils";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { optionsBuildingContractType, optionsBuildingStatus } from "utils/options";

import { Read as ReadCustomer } from "services/customers";
import { Read as ReadArchitect } from "services/architects";
import { parseCurrency } from "utils/parsers";

export default function useController() {
 
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const [loading, setLoading] = useState(false) 
    const [rows, setRows] = useState([])

    const columns = [
        { title:'Nome da obra', ref:'name' },
        { title:'Nome do cliente', ref:'customer' },
        { title:'Arquiteto', ref:'architect' },
        { title:'Tipo de obra', ref:'type_contract' },
        { title:'Data início', ref:'date_start' },
        { title:'Data término', ref:'date_end' },
        { title:'Valor total', ref:'total' },
        { 
            title:'Status',  
            renderCell: ({ row }) => <>
                <StatusItemContent>
                    <StatusItem color={row?.status_color}>
                        { row?.status }
                    </StatusItem>
                </StatusItemContent>
            </>
        },
        { 
            title:' ', 
            renderCell: ({ row }) => <>
                <ContentTableAction>
                    <ContentTableItem onClick={() => navigate(`dashboard/details/construction/materials/${ row.id }`)}>
                        <ContentTableActionIconEdit />
                    </ContentTableItem>
                    <ContentTableItem onClick={() => remove(row)}>
                        <ContentTableActionIconRemove />
                    </ContentTableItem>
                </ContentTableAction>
            </>
        }

    ] 

    const parseRow = (item, customers, architects) => {

        return { 
            ...item,
            customer: customers?.find(f => f?.id === item?.customer?.data?.id)?.name,
            architect: architects?.find(f => f?.id === item?.architect?.data?.id)?.name,

            date_start: moment(item?.date_start)?.format("L"),
            date_end: moment(item?.date_end)?.format("L"),
            
            total: parseCurrency(item?.total || 0),

            type_contract: optionsBuildingContractType?.find(f => f.id === item?.type_contract)?.title,
            status: optionsBuildingStatus?.find(f => f.id === item?.status)?.title,
            status_color: item?.status === 'progress' ? "green" : "red"
        }
    }

    const init = async () => {
        setLoading(true)
        const result = await Read() 
        if(result && !exposeStrapiError(result)){
            if(result?.data?.length){
                const normalResult = normalizeStrapiList(result)

                const resCustomers = await ReadCustomer()
                const customers = resCustomers?.data?.length ? normalizeStrapiList(resCustomers)?.map(m => ({ ...m, title: m?.name })) : []
        
                const resArchitect = await ReadArchitect()
                const architects = resArchitect?.data?.length ? normalizeStrapiList(resArchitect)?.map(m => ({ ...m, title: m?.name })) : []
                setRows(normalResult?.map(m => parseRow(m, customers, architects)))
            }else{
                setRows([])
            }
        }
        setLoading(false)
    }

    useEffect(() => { init() ;}, [])

    const remove = async row => {
        setLoading(true) 
        const result = await Delete(row?.id)
        if(result && !exposeStrapiError(result)){
            toast.success('Removido com sucesso') ;
        }
        init()
    }
    
    return {
        loading,
        columns,
        rows,
        navigate
    }
}   