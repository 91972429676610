import styled from 'styled-components'  


export const CardHomeHeaderActions = styled.div.attrs({ 
})`
    width: 100%;
    max-width: 210px;
    ${ p => p.small ? `max-width: 120px;` : ``}
    ${ p => p.started ? `margin: 0 auto 0 0;` : ``}
`;

export const HeaderActions = styled.div.attrs({ 
})`
    display: flex;
    gap: 20px;
    width: 100%;
    max-width: 440px;
    justify-content: flex-end;
`;
