import React, { useState } from "react";  

import { 
    CardPhoto,
    CardPhotoAction,
    CardPhotoActionIcon,
    CardPhotoActionText,
    CardPhotoContent,
    CardPhotoHeader,
    CardPhotoImage,
    CardPhotoRow,
    CardPhotoRowItem,
    CardsWrapper,
    FormActions, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import { formAddress, formContacts, formPersonalDataCustomer } from "utils/forms";
import useController from "./controller";
import Input from "components/Form/Input";
import { optionsDailyPhotoCategory } from "utils/options";
import { parseDatestringUSBR } from "utils/parsers";
import { exposeStrapiError, parseStrapiImage } from "utils";
import { Update } from "services/daily-photos";
import { toast } from "react-toastify";

export default function CardDailyPhoto({ item, remove, reload }){   

    const [ form, setForm ] = useState({ 
        ...item, 
        category: optionsDailyPhotoCategory?.find(f => f.id === item?.category)?.title,
        date: parseDatestringUSBR(item?.date)
    })
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }); } 

    const updateRegister = async () => {
        const payload = {
            title: form?.title
        }

        const result = await Update({
            data: payload
        }, item?.id)

        if(result && !exposeStrapiError(result)){
            toast.success("Atualizado com sucesso")
            reload()
        }
    }

    return ( 
        <> 
            <CardPhoto>
                <CardPhotoHeader>
                    <Input placeholder="Nome" value={formValue("title")} onChange={e => changeForm(e.target.value, 'title')} onBlur={updateRegister} />
                    <CardPhotoRow>
                        <CardPhotoRowItem>
                            <Input placeholder="Tipo" disabled value={formValue("category")} onChange={e => changeForm(e.target.value, "category")}  />
                        </CardPhotoRowItem>
                        <CardPhotoRowItem>
                            <Input mask={"99/99/9999"} disabled placeholder="Data" value={formValue("date")} onChange={e => changeForm(e.target.value, "date")} />
                        </CardPhotoRowItem>
                    </CardPhotoRow>
                </CardPhotoHeader>
                <CardPhotoImage image={item?.image?.url ? parseStrapiImage(item?.image?.url) : null} />
                <CardPhotoContent>
                    <CardPhotoAction>
                        <CardPhotoActionText>{ item?.image?.name }</CardPhotoActionText>
                        <CardPhotoActionIcon onClick={ remove }/>
                    </CardPhotoAction>
                </CardPhotoContent>
            </CardPhoto> 
        </>
    );
}