import React, { useState } from "react";  

import {  
    
    CardHome,
    CardHomeHeader,
    CardHomeHeaderTitle,
    CardHomeBody,
    CardHomeHeaderActions,
    BackButton,
    CardHomeHeaderButton,
    ChevronButton

} from "./styled";

import Select from "components/Form/Select";
import { useHistory } from "react-router-dom";
import DashboardActions from "components/Actions";

export default function DashboardCardWrapper({ children, title, selectable, backable, closeable, actions, white, custom, loading }){  
    const history = useHistory()

    const [open, setOpen] = useState(true)

    return ( 
        <>
            <CardHome white={white}>
                {
                    (!title && !closeable && !backable && !selectable && !actions && !custom) ? null :
                        <CardHomeHeader>
                            <CardHomeHeaderButton>
                                { !closeable ? null : <ChevronButton open={open} onClick={() => setOpen(!open)} /> }
                                { !backable ? null : <BackButton onClick={() => history.goBack()} /> }
                                <CardHomeHeaderTitle>{ title }</CardHomeHeaderTitle>
                            </CardHomeHeaderButton>
                            {
                                !selectable ? null :
                                <CardHomeHeaderActions>
                                    <Select options={selectable?.options || []} { ...selectable } />
                                </CardHomeHeaderActions>
                            }
                            {
                                !actions ? null :
                                <DashboardActions actions={actions} loading={loading} />
                            }
                            {/* { !custom ? null : custom  } */}
                        </CardHomeHeader>
                }
                {
                    !open ? null : 
                    <CardHomeBody>
                        { children }
                    </CardHomeBody>
                }
            </CardHome>
        </>
    );
}