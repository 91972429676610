import styled from 'styled-components'  


export const FormActions = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
`;













export const CardsWrapper = styled.div.attrs({ 
})`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding: 24px;
`;


export const CardPhoto = styled.div.attrs({ 
})`
    max-width: 300px;
    background: ${ p => p.theme.palette.colors.white };
`;

export const CardPhotoHeader = styled.div.attrs({ 
})`
    padding: 6px;
`;

export const CardPhotoRow = styled.div.attrs({ 
})`
    display: flex;
    margin-top: 6px;
    gap: 6px;
`;

export const CardPhotoRowItem = styled.div.attrs({ 
})`
`;

export const CardPhotoImage = styled.div.attrs({ 
})`
    width: 100%;
    height: 180px;
    background: ${ p => p.theme.palette.colors.lightgrey };
`;

export const CardPhotoContent = styled.div.attrs({ 
})`
    padding: 6px 12px;
`;

export const CardPhotoAction = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    transition: all .3s ease;
    &:hover{
        transform: scale(1.02);
    }
`;
export const CardPhotoActionText = styled.div.attrs({ 
})`
`;
export const CardPhotoActionIcon = styled.img.attrs({ 
    src: '/icons/close.svg'
})`
`;
