import React from "react";  

import { 
    FormActions, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import useController from "./controller";
import { formBank, formContacts, formContactsArchitect, formPersonalDataArchitect } from "utils/forms";

export default function DashboardFormsRegisterArchitects(){   

    const {
        refForm,
        refFormBank,
        refFormBank2,
        refFormContact,
        
        register,
        registerFirst,
        registerSecond,
        registerContact,
        loading,
        saving,

        actions,
        remove,
        id
    } = useController()

    return ( 
        <>
        <ContainerAuthenticated page={1} subpage={4}>
            <DashboardCardWrapper backable title={"Arquitetos - Nome do arquiteto"} actions={actions} loading={loading}>

                <DashboardCardWrapper closeable title={"Dados pessoais"} white>
                    <DashboardFormsCore formItems={formPersonalDataArchitect} register={register} ref={refForm} />
                </DashboardCardWrapper>
                <DashboardCardWrapper closeable title={"Contatos"} white>
                    <DashboardFormsCore formItems={formContactsArchitect} register={registerContact} ref={refFormContact} />
                </DashboardCardWrapper>
                <DashboardCardWrapper closeable title={"Dados bancários 1 ( Principal)"} white>
                    <DashboardFormsCore formItems={formBank} register={registerFirst} ref={refFormBank} />
                </DashboardCardWrapper>
                <DashboardCardWrapper closeable title={"Dados bancários 2"} white>
                    <DashboardFormsCore formItems={formBank} register={registerSecond} ref={refFormBank2} />
                </DashboardCardWrapper>

                <FormActions> 
                    <DashboardActions removeable={!id ? null : remove} actions={actions} loading={saving} />
                </FormActions>

            </DashboardCardWrapper>
        </ContainerAuthenticated>
        </>
    );
}