import React from "react";  

import { 
    FormActions, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import { formPassword } from "utils/forms";
import useController from "./controller";

export default function DashboardFormsUserPassword(){   
    

    const {
        refForm,

        register,
        loading,
        saving,

        actions,
    } = useController()


    return ( 
        <>
        <ContainerAuthenticated page={0}>
            <DashboardCardWrapper backable title={"Senha e seguraça"} actions={actions} loading={loading}>

               <DashboardCardWrapper closeable title={"Senha"} white>
                    <DashboardFormsCore formItems={formPassword} register={register} ref={refForm} />
                </DashboardCardWrapper>

                <FormActions> 
                    <DashboardActions actions={actions} loading={saving} />
                </FormActions> 

            </DashboardCardWrapper>
        </ContainerAuthenticated>
        </>
    );
}