import { GET, POST, PUT, DELETE } from './api'

const table = "constructions"

export const Create = async (params) => {
    return await POST(`/${ table }`, params, true);
}

export const Read = async () => {
    return await GET(`/${ table }?populate=*`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/${ table }/${id}?populate[0]=customer.address&populate[1]=architect.contact&populate[2]=contracts.file&populate[3]=addendum.file`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/${ table }/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/${ table }/${ id }`, true);
}