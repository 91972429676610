import React from "react";  

import Input from "components/Form/Input";
import Button from "components/Form/Button";
import { BetweenCenter, DashboardIcon, EmptyDiv } from "ui/styled";

export default function DashboardListActions(){   

    return ( 
        <> 

            <BetweenCenter headed>
                <BetweenCenter>
                    <EmptyDiv>
                        <Input placeholder="Pesquisar" search />
                    </EmptyDiv>
                    <EmptyDiv>
                        <Button nospace primary outline> <DashboardIcon icon={"print"} /> Imprimir</Button>
                    </EmptyDiv>
                </BetweenCenter> 
                <BetweenCenter> 
                    <EmptyDiv>
                        <Button nospace primary outline> <DashboardIcon icon={"doc"} /> Exportar</Button>
                    </EmptyDiv>
                    <EmptyDiv>
                        <Button nospace primary outline> <DashboardIcon icon={"settings"} /> Filtro</Button> 
                    </EmptyDiv>
                </BetweenCenter> 
            </BetweenCenter> 
                             
        </>
    );
}