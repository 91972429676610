import { useCallback, useEffect, useMemo, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister, valid } from "utils";
import { toast } from "react-toastify";
import { formPersonalData } from "utils/forms";

import { ReadOne, Update } from "services/constructions";
import { Read, ReadOne as ReadOneOrder } from "services/orders";
import { ReadOne as ReadOneRequest } from "services/requests";

import { ReadAddressesByZipCode } from "services/api";
import moment from "moment/moment";
import { parseCurrency, parseDatestringBRUS, parseDatestringUSBR } from "utils/parsers";
import { Create } from "services/meditions";

export default function useController(){   

    const history = useHistory(); 
    const back = () => { history.goBack() ;}
    const navigate = to => history.push(`/${ to }`); 

    const { id, order_id } = useParams()

    const refForm = useRef() 
    const refFormSupplier = useRef() 
    const refFormPayment = useRef() 
    const refFormInvoice = useRef() 
    const refFormCondition = useRef() 
     
    const [orders_options, setOrdersOptions] = useState([])
    const [register, setRegister] = useState(null)
    const [loading, setLoading] = useState(false) 
    
    const [active, setActive] = useState(0)

    const columns = [
        { title:'Item', ref:'item' },
        { title:'Descrição do serviço', ref:'service_scope' },
        { title:'Qt.', ref:'quantity' },
        { title:'Un.', ref:'unit' },
        { title:'Custo unitário', ref:'unitJob' },
        { title:'Valor total', ref:'totalValue' },
        
        { title:'Qt. medida Atual', ref:'qtdMeansureCurent', color:"value" },
        { title:'Qt. medida Anterior', ref:'qtdMeansurePrevious', color:"value" },
        { title:'Qt. medida Acumulado', ref:'qtdMeansureAccumulated', color:"value" },
        { title:'Valor Atual', ref:'currentValue' }
    ] 

    const columnsTotals = [
        { title:'Titulo', ref:'title' }, 
        { title:'Valor', ref:'value' }, 
    ] 

    const [rowsSubtotal, setRowsSubtotal]= useState([ ])

    const [rowsTotal, setRowsTotal] = useState([ ])

    const [rows, setRows] = useState([ ])

    const actions = [
        // { title:'Imprimir', outline:true, icon:'print', primary:true, action:() => null },
        // { title:'Baixar', outline:true, icon:'download', primary:true, action:() => null },
        // { title:'Cancelar', outline:true, action:() => history.goBack() },
        // { title:'Salvar', action:() => save(), loadable:true }
    ]

    const formActions = [
        // { title:'Cancelar', outline:true, action:() => history.goBack() }, 
        { title:'Salvar', action:() => save(), loadable:true }
    ]

    const changeActive = item => {
        navigate(`dashboard/details/construction/measurements/${ id }/${ item?.id }`)
    }
 
    const save = async () => {
        setLoading(true)


        const form = refForm?.current?.getForm()
        const formSupplier = refFormSupplier?.current?.getForm()
        const formPayment = refFormPayment?.current?.getForm()
        const formInvoice = refFormInvoice?.current?.getForm()
        const formCondition = refFormCondition?.current?.getForm()

        
        // form?.medition_date
        // form?.receivement_date
        // form?.invoice_number

        // formSupplier?.supplier_name
        // formSupplier?.supplier_fantasyname
        // formSupplier?.supplier_document
        // formSupplier?.supplier_rg
        // formSupplier?.supplier_phone

        // formPayment?.ted_bank_code
        // formPayment?.ted_account_agency
        // formPayment?.ted_account_number
        // formPayment?.ted_account_type
        // formPayment?.pix_key_type
        // formPayment?.pix_key
        // formPayment?.bank_invoice

        // formInvoice?.invoice_invoice
        // formInvoice?.invoice_retention

        // formCondition?.suppliement_order
        // formCondition?.suppliement_payment
        // formCondition?.suppliement_invoicing
        // formCondition?.suppliement_observation


        const payload = {
            construction: id,
            order: order_id,
            date_medition: parseDatestringBRUS(form?.medition_date),
            date_receive: parseDatestringBRUS(form?.receivement_date),
            invoice: form?.invoice_number,
            payment_data:{
                code: formPayment?.ted_bank_code,
                agency: formPayment?.ted_account_agency,
                account: formPayment?.ted_account_number,
                type: formPayment?.ted_account_type,
                type_pix: formPayment?.pix_key_type,
                pix: formPayment?.pix_key,
                notes: "",
                type_account: "pf",
            },
            bank_invoice: ( formPayment?.bank_invoice === 1 ),  
            emit_invoice: ( formInvoice?.invoice_invoice === 1 ),  
            retention_invoice: ( formInvoice?.invoice_retention === 1 ),  
            observations: formCondition?.suppliement_observation,  
        }

        const result = await Create({
            data:payload
        })

        if(result && !exposeStrapiError(result)){
            toast.success("Salvo com sucesso")
            navigate(`dashboard/details/construction/payments/${id}`)
        }

        setLoading(false)
    }


    const parseRow = (item, creq) => {

        const currentMode = creq?.type === 'service' ? `contract_item` : `material`

        const nitem = {
            ...item,
            ...item?.[currentMode]
        }

        console.log("nitem", nitem)

        const percent = (100 * nitem?.quantity) / nitem?.contract_item?.quantity
                        

        return ({ 
            id: nitem?.id, 
            item: nitem?.item || nitem?.id, 
            service_scope: nitem?.title || nitem?.details, 
            quantity: nitem?.quantity || 1, 
            unit: nitem?.unit || 1, 
            unitJob:  parseCurrency(nitem?.value), 
            totalValue: parseCurrency(nitem?.value * (nitem?.quantity || 1)), 
            raw_unitJob:  (nitem?.value), 
            raw_totalValue: (nitem?.value * (nitem?.quantity || 1)), 

            qtdMeansureCurent: `${ percent || 0 }%`, 
            qtdMeansurePrevious:"%",  
            qtdMeansureAccumulated:"%",  
            currentValue: parseCurrency(nitem?.value * (nitem?.quantity || 1)), 
        })
    }
    
    const init = useCallback(async () => {
        setLoading(true)
        
        setRegister({})

        const result = await ReadOne(id)
        const resultOrders = await Read(id)
        const normalOrders = normalizeStrapiList(resultOrders)

        console.log("normalOrders", normalOrders)

        setOrdersOptions((normalOrders||[])?.map( m => ({
            ...m,
            title: `Medição ${ m?.id }`
        })))

        const resultOrder = !order_id ? null : await ReadOneOrder(order_id)
        const normalOrder = !resultOrder ? null : normalizeStrapiRegister(resultOrder)


        const resultRequest = !normalOrder?.request?.id ? null : await ReadOneRequest(normalOrder?.request?.id)
        const parsedRequest = !resultRequest ? null : normalizeStrapiRegister(resultRequest)

        console.log("normalOrder", normalOrder)

        if(result?.data?.id){
            const normalResult = normalizeStrapiRegister(result)
            console.log("normalResult", normalResult)
            setRegister({ 
                ...normalResult, 
                customer: normalResult?.customer?.name,

                medition_date: moment(normalOrder?.createdAt)?.format("L"),
                receivement_date: !normalOrder?.request?.delivery_date ? "" : parseDatestringUSBR(normalOrder?.request?.delivery_date),
                invoice_number: normalOrder?.request?.order_number,

                supplier_name: normalOrder?.supplier?.name,
                supplier_fantasyname: normalOrder?.supplier?.name,
                supplier_document: normalOrder?.supplier?.cpf,
                supplier_rg: normalOrder?.supplier?.rg,
                supplier_phone: normalOrder?.supplier?.phone,

                ted_bank_code: normalOrder?.supplier?.bank?.code,
                ted_account_agency: normalOrder?.supplier?.bank?.agency,
                ted_account_number: normalOrder?.supplier?.bank?.account,
                ted_account_type: normalOrder?.supplier?.bank?.type,

                pix_key_type: normalOrder?.supplier?.bank?.type_pix,
                pix_key: normalOrder?.supplier?.bank?.pix,
                
                bank_invoice: normalOrder?.supplier?.issue_bill ? 1 : 2,
                invoice_invoice: normalOrder?.supplier?.issue_invoice ? 1 : 2,
                invoice_retention: normalOrder?.supplier?.issue_invoice ? 1 : 2,

                suppliement_order: normalOrder?.cno,
                suppliement_payment: normalOrder?.payment_method,
                suppliement_invoicing: normalOrder?.invoice_method,
                suppliement_observation: "",

            })

            const nxtRows = parsedRequest?.RequestItem?.map((m, k) => ({
                ...m,
                contract_item: normalizeStrapiRegister(m?.contract_item),
                material: normalizeStrapiRegister(m?.material)
            }))
            
            console.log("nxtRows", nxtRows)

            const nextRows = (nxtRows||[]).map((item) => parseRow(item, parsedRequest))
    
            setRows(nextRows)

            const accTotal = nextRows?.reduce((p, c) => p + c?.raw_totalValue, 0 )
            const amount = normalResult?.total - accTotal

            const percent = ((100 * accTotal) / amount)

            setRowsSubtotal([
                { title:"Valor total do contrato", value: parseCurrency(normalResult?.total || 0) },
                { title:"Saldo", value: parseCurrency(amount || 0) },
            ])

            setRowsTotal([
                { title:"Total acumulado", value: parseCurrency(accTotal || 0) },
                { title:"Porcentagem acumulada", value:`${ parseFloat(percent || 0).toFixed(2) }%` },
            ])

        }

        setLoading(false)
    }, [id, order_id])
    
    useEffect(() => { if(id){ init() ;} }, [id, order_id, init])

    return {

        changeActive,
        order_id,

        refForm, 
        register,
        loading, 

        actions,

        active, 
        setActive, 

        columns,
        rows,
        formActions,

        columnsTotals,
        rowsSubtotal,
        rowsTotal,
        orders_options,

        refFormSupplier,
        refFormPayment,
        refFormInvoice,
        refFormCondition,
    }
}