import { useCallback, useEffect, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { exposeStrapiError, normalizeStrapiRegister, valid } from "utils";
import { toast } from "react-toastify";

import { Create, ReadOne, Update, Delete } from "services/suppliers";

export default function useController(){   

    const history = useHistory(); 
    const back = () => { history.goBack() ;}
    const navigate = to => history.push(`/${ to }`); 


    const { id } = useParams()

    const refForm = useRef()
    const refFormBank = useRef()
    
    const [register, setRegister] = useState(null)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    const actions = [
        { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Salvar', action:() => save(), loadable:true }
    ]
    
    const init = useCallback(async () => {
        setLoading(true)
        const result = await ReadOne(id)
        if(result?.data?.id){
            const normalResult = normalizeStrapiRegister(result)
            setRegister({
                ...normalResult,

                physical_person: normalResult?.physical_person ? 'pf' : 'pj',
                status: normalResult?.status ? 1 : 2,
                accepts_card: normalResult?.accepts_card ? 1 : 2,
                issue_bill: normalResult?.issue_bill ? 1 : 2,
                issue_invoice: normalResult?.issue_invoice ? 1 : 2,

                ...normalResult?.bank
            })
        }
        setLoading(false)
    }, [id])

    const save = async () => {
        const form = refForm.current.getForm()
        const formBank = refFormBank.current.getForm()
                
        const payload = {
            ...form,

            physical_person: form?.physical_person === 1,
            status: form?.status === 1,
            
            accepts_card: formBank?.accepts_card === 1,
            issue_bill: formBank?.issue_bill === 1,
            issue_invoice: formBank?.issue_invoice === 1,
             

            bank:{
                ...formBank,
            }
        }

        // console.log("payload", payload)

        delete payload.id

        // if(!valid(payload, formPersonalDataSupplier)){ toast.error("Preencha todos os campos"); return ;}

        setSaving(true)
        const result = id ? await Update({data:payload}, id) : await Create({data:payload})

        if(result && !exposeStrapiError(result)){            
            toast.success("Salvo")
            history.goBack()
        } else {
            toast.error("Erro ao salvar, tente novamente mais tarde")
        }

        setSaving(false)
    }

    const remove = async row => {
        setLoading(true) 
        const result = await Delete(row?.id)
        if(result && !exposeStrapiError(result)){
            toast.success('Removido com sucesso') ;
        }
        history.goBack();
    }

    useEffect(() => { if(id){ init() ;} }, [id, init])

    return {
        back,
        navigate,

        refForm,
        refFormBank,
        
        register,
        loading,
        saving,

        actions,

        remove,
        id
    }

}