import React, { useEffect, useState } from "react";  

import { useHistory } from "react-router-dom";

import { ContentTableAction, ContentTableActionIconEdit, ContentTableActionIconRemove, ContentTableItem, DashboardTitle } from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import DashboardCardTable from "components/Cards/Table";
import DashboardCardWrapper from "components/Cards/Wrapper";

import Button from "components/Form/Button";
import { BetweenCenter, EmptyDiv, StatusItem, StatusItemContent } from "ui/styled";
import DashboardListActions from "components/Dashboard/ListActions";
import DashboardCardChart from "components/Cards/Chart";
import DashboardCardChartReport from "components/Cards/ChartReport";
import DashboardCardTotal from "components/Cards/Total";
import { normalizeStrapiList, normalizeStrapiRegister } from "utils";

import { Read, ReadAll } from "services/medition-payments";

import { ReadOne as ReadMedition } from "services/meditions";
import { ReadOne as ReadOrder } from "services/orders";
import { ReadOne as ReadOneSupply } from "services/suppliers";
import { ReadOne as ReadOneRequest } from "services/requests";
import { parseCurrency, parseDatestringUSBR } from "utils/parsers";
import moment from "moment/moment";


export default function DashboardListReportCenter({ page , subpage }){  

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const columns = [
        { title:'Vencimento', ref:'expired_at' },
        { title:'NF', ref:'nf' },
        { title:'Descrição', ref:'description' },
        // { title:'Favorecido', ref:'receiver' },
        { title:'Categoria', ref:'category' },
        { title:'Valor a pagar', ref:'value' },
        { 
            title:'Status',  
            renderCell: ({ row }) => <>
                <StatusItemContent>
                    <StatusItem color={row?.status_color}>
                        { row?.status }
                    </StatusItem>
                </StatusItemContent>
            </>
        }
    ] 

    // const rows = [
    //     { expired_at:"01/02/2023", nf:"123456789", description:"Texto descritivo", receiver:"Mariana da Fonseca da Silva", category:"Texto descritivo", value:"R$ 123.456.123,12", status:"Andamento", status_color:"green" },
    //     { expired_at:"01/02/2023", nf:"123456789", description:"Texto descritivo", receiver:"Mariana da Fonseca da Silva", category:"Texto descritivo", value:"R$ 123.456.123,12", status:"Andamento", status_color:"green" },
    //     { expired_at:"01/02/2023", nf:"123456789", description:"Texto descritivo", receiver:"Mariana da Fonseca da Silva", category:"Texto descritivo", value:"R$ 123.456.123,12", status:"Andamento", status_color:"green" },
    //     { expired_at:"01/02/2023", nf:"123456789", description:"Texto descritivo", receiver:"Mariana da Fonseca da Silva", category:"Texto descritivo", value:"R$ 123.456.123,12", status:"Andamento", status_color:"green" },
    //     { expired_at:"01/02/2023", nf:"123456789", description:"Texto descritivo", receiver:"Mariana da Fonseca da Silva", category:"Texto descritivo", value:"R$ 123.456.123,12", status:"Andamento", status_color:"green" },
    //     { expired_at:"01/02/2023", nf:"123456789", description:"Texto descritivo", receiver:"Mariana da Fonseca da Silva", category:"Texto descritivo", value:"R$ 123.456.123,12", status:"Andamento", status_color:"green" },
    //     { expired_at:"01/02/2023", nf:"123456789", description:"Texto descritivo", receiver:"Mariana da Fonseca da Silva", category:"Texto descritivo", value:"R$ 123.456.123,12", status:"Andamento", status_color:"green" },
    //     { expired_at:"01/02/2023", nf:"123456789", description:"Texto descritivo", receiver:"Mariana da Fonseca da Silva", category:"Texto descritivo", value:"R$ 123.456.123,12", status:"Andamento", status_color:"green" },
    //     { expired_at:"01/02/2023", nf:"123456789", description:"Texto descritivo", receiver:"Mariana da Fonseca da Silva", category:"Texto descritivo", value:"R$ 123.456.123,12", status:"Andamento", status_color:"green" },
    //     { expired_at:"01/02/2023", nf:"123456789", description:"Texto descritivo", receiver:"Mariana da Fonseca da Silva", category:"Texto descritivo", value:"R$ 123.456.123,12", status:"Andamento", status_color:"green" },
    //     { expired_at:"01/02/2023", nf:"123456789", description:"Texto descritivo", receiver:"Mariana da Fonseca da Silva", category:"Texto descritivo", value:"R$ 123.456.123,12", status:"Andamento", status_color:"green" },
    //     { expired_at:"01/02/2023", nf:"123456789", description:"Texto descritivo", receiver:"Mariana da Fonseca da Silva", category:"Texto descritivo", value:"R$ 123.456.123,12", status:"Andamento", status_color:"green" },
    //     { expired_at:"01/02/2023", nf:"123456789", description:"Texto descritivo", receiver:"Mariana da Fonseca da Silva", category:"Texto descritivo", value:"R$ 123.456.123,12", status:"Em aberto", status_color:"red" },
    //     { expired_at:"01/02/2023", nf:"123456789", description:"Texto descritivo", receiver:"Mariana da Fonseca da Silva", category:"Texto descritivo", value:"R$ 123.456.123,12", status:"Em aberto", status_color:"red" },
    //     { expired_at:"01/02/2023", nf:"123456789", description:"Texto descritivo", receiver:"Mariana da Fonseca da Silva", category:"Texto descritivo", value:"R$ 123.456.123,12", status:"Em aberto", status_color:"red" },
    //     { expired_at:"01/02/2023", nf:"123456789", description:"Texto descritivo", receiver:"Mariana da Fonseca da Silva", category:"Texto descritivo", value:"R$ 123.456.123,12", status:"Em aberto", status_color:"red" },
    // ]



    // const [loading, setLoading] = useState(false)
    const remove = async row => {
        // setLoading(true)
        // const user = await ReadByEmail(row?.email)
        // if(user?.[0]?.id){
        //     await DeleteUser(user?.[0]?.id)
        // }
        // const result = await Delete(row?.id)
        // if(result && !exposeStrapiError(result)){
        //     toast.success('Removido com sucesso') ;
        // }
        // init()
    }

    const [rows, setRows] = useState([ ])
    const [loading, setLoading] = useState(false)

    const parseRowRequest = (item, creq) => {

        const currentMode = creq?.type === 'service' ? `contract_item` : `material`

        const nitem = {
            ...item,
            ...item?.[currentMode]
        }

        return ({ 

            item: nitem?.item || nitem?.id, 
            description: nitem?.title || nitem?.details, 
            qtd: nitem?.quantity || 1, 
            unit: nitem?.unit || 1, 
            unitDisc: "R$ 0,00",
            unitCost: parseCurrency(nitem?.value), 
            total: parseCurrency(nitem?.value * (nitem?.quantity || 1)), 
            raw_unitCost: (nitem?.value||0), 
            raw_total: ((nitem?.value||0) * (nitem?.quantity || 1)), 

        })
    }

    const parseRow = async item => {

        if(item?.id){

            const resultMedition = await ReadMedition(item?.medition?.data?.id)
            const normalMedition = normalizeStrapiRegister(resultMedition)
            console.log("normalMedition", normalMedition)
            
            const resultOrder = await ReadOrder(normalMedition?.order?.id)
            const normalOrder = normalizeStrapiRegister(resultOrder)
            console.log("normalOrder", normalOrder)
            
            const resultRequest = await ReadOneRequest(normalOrder?.request?.id)
            const normalRequest = normalizeStrapiRegister(resultRequest)
            console.log("normalRequest", normalRequest)
            
            const resultSupply = await ReadOneSupply(normalOrder?.supplier?.id)
            const normalSupply = normalizeStrapiRegister(resultSupply)
            console.log("normalSupply", normalSupply)

            const nxtRows = normalRequest?.RequestItem?.map((m, k) => ({
                ...m,
                contract_item: normalizeStrapiRegister(m?.contract_item),
                material: normalizeStrapiRegister(m?.material)
            }))
    
            const nextRows = (nxtRows||[]).map((item) => parseRowRequest(item, normalRequest))
    
    
            const subtotal = nextRows?.reduce((p, c) => p + (c?.[`raw_total`]||0),0)
            const taxrate = normalRequest?.type === 'service' ? .17 : .35
            const taxes = taxrate * subtotal
    
            const shippingrate = .01
            const shippings = shippingrate * subtotal
    
            const discountrate = .05
            const discounts = discountrate * subtotal
    
            const total = ((taxes + shippings + subtotal) - discounts)
    
            // setRowsTotal([
            //     { title:"Subtotal", value: parseCurrency(subtotal) , nbsp },
            //     { title:"Impostos", value: parseCurrency(taxes), nbsp },
            //     { title:"Desconto geral", value: parseCurrency(discounts), nbsp },
            //     { title:"Fretes", value: parseCurrency(shippings), nbsp },
            //     { title:"Total geral", value: parseCurrency(total), nbsp },
            // ])

            // Em atraso - red
            // Em aberto - yellow

            


            const date1 = moment(item?.construction?.data?.attributes?.date_start);
            const date2 = moment(normalMedition?.date_medition); 

            const isSameMonth = date1.isSame(date2, 'month');

            return ({
                expired_at: parseDatestringUSBR(normalMedition?.date_medition),
                nf: normalMedition?.invoice,
                description: nextRows?.[0]?.description,
                favored: normalSupply?.name,
                category: normalRequest?.type === 'service' ? "Serviço" : "Material",
                value: parseCurrency(total||0),
                status: !item?.bank_invoice?.data?.url ? "Em aberto" : "Pago",
                status_color: !item?.bank_invoice?.data?.url ? "yellow" : "green",
                invoice: item?.bank_invoice?.data?.url,
                
                payed: !!item?.bank_invoice?.data?.url,
                chart: [ moment(normalMedition?.date_medition).format("MM"), isSameMonth ? item?.construction?.data?.attributes?.total : 0, !!item?.bank_invoice?.data?.url ? (total||0) : 0]
            })

        }


        // return ({
        //     expires_at: "01/02/2023",
        //     nf: "123456789",
        //     description:"Texto descritivo",
        //     favored:"Mariana da Fonseca da Silva",
        //     category:"Texto descritivo",
        //     value:"R$ 123.456.123,12",
        //     status:"Pago",
        //     status_color:"green"
        // })
        return null
    }

    const init = async () => {
        setLoading(true)
        const result = await ReadAll()
        const normalResult = normalizeStrapiList(result)
        
        console.log("normalResult", normalResult)
        
        const promises = normalResult.map( parseRow )
        const nextRows = await Promise.all(promises)
        setRows(nextRows?.filter(f => f))
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    return ( 
        <>
            <ContainerAuthenticated page={page || 5} subpage={subpage || 0}> 
                <BetweenCenter spaced>
                    <DashboardTitle>Relatório - Centro de custos - Titulo do centro de custo</DashboardTitle>
                    {/* <EmptyDiv>
                        <Button secondary nospace>Novo</Button>
                    </EmptyDiv> */}
                </BetweenCenter>
                <Row>  
                    <Col sm={12}>
                        <DashboardCardWrapper custom={
                            <DashboardListActions />
                        }>

                            <DashboardCardChartReport loading={loading} rows={rows} />
                            <DashboardCardTable loading={loading} rows={rows} columns={columns} blue />
                            {/* <DashboardCardTotal /> */}



                        </DashboardCardWrapper>
                    </Col> 
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}