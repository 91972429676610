import React, { useEffect, useMemo, useState } from "react";

import { Delete, Read } from "services/materials";

import { ContentTableAction, ContentTableActionIconEdit, ContentTableActionIconRemove, ContentTableItem } from "./styled";

import { exposeStrapiError, normalizeStrapiList } from "utils";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

import { Read as ReadSubservices } from "services/sub-services";
import { Read as ReadServices } from "services/services";

export default function useController() {
 
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const [loading, setLoading] = useState(false) 
    const [rows, setRows] = useState([])

    const columns = [
        { title:'Descrição', ref:'details' },
        { title:'Categoria do material / serviços', ref:'service' },
        { title:'Subcategoria', ref:'sub_service' },
        { 
            title:' ', 
            renderCell: ({ row }) => <>
                <ContentTableAction>
                    <ContentTableItem  onClick={() => navigate(`dashboard/register/materials/edit/${ row.id }`)}>
                        <ContentTableActionIconEdit />
                    </ContentTableItem>
                    <ContentTableItem onClick={() => remove(row)}>
                        <ContentTableActionIconRemove />
                    </ContentTableItem>
                </ContentTableAction>
            </>
        }

    ] 

    const parseRow = (item, subservices, services) => {
        return { 
            ...item,
            service: services?.find(f => f?.id === item?.service?.data?.id)?.name,
            sub_service: subservices?.find(f => f?.id === item?.sub_service?.data?.id)?.name,
        }
    }

    const init = async () => {
        setLoading(true)
        const result = await Read() 
        if(result && !exposeStrapiError(result)){
            if(result?.data?.length){
                const normalResult = normalizeStrapiList(result)

                const resSubservice = await ReadSubservices()
                const subservices = resSubservice?.data?.length ? normalizeStrapiList(resSubservice)?.map(m => ({ ...m, title: m?.name })) : []
        
                const resServices = await ReadServices()
                const services = resServices?.data?.length ? normalizeStrapiList(resServices)?.map(m => ({ ...m, title: m?.name })) : []
                
                setRows(normalResult?.map(m => parseRow(m, subservices, services)))
            }else{
                setRows([])
            }
        }
        setLoading(false)
    }

    useEffect(() => { init() ;}, [])

    const remove = async row => {
        setLoading(true) 
        const result = await Delete(row?.id)
        if(result && !exposeStrapiError(result)){
            toast.success('Removido com sucesso') ;
        }
        init()
    }
    
    return {
        loading,
        columns,
        rows,
        navigate
    }
}   