import React from "react";  

import { 
    FormActions, FormWhite, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
// import { formAddress, formContacts, formMapConcurrence, formPersonalDataCustomer } from "utils/forms";
import useController from "./controller";
import BasicTable from "components/Form/Table";
import { ButtonCenter, ButtonContent, CenterCenter } from "ui/styled";
import Button from "components/Form/Button";

export default function DashboardDetailsContructionMap(){   
    

    const { 

        refForm, 
        register,
        loading, 

        actions,
        actionsForm,
        
        actionsTab,
        active, 
        setActive, 

        rows,
        columnsAfterRows,

        columns,
        preColumns,

        payColumns,
        payPreColumns,
        payRows,

        formMapConcurrence,
        avaSup,
        addSupplier,

        form

    } = useController()


    return ( 
        <>
        <ContainerAuthenticated noside page={2} subpage={3}>
            <DashboardCardWrapper backable title={`Mapa de concorrência - ${ register?.name }`} actions={!rows?.length || !form?.selectedSupplier ? null : actionsForm} loading={loading}>

                {/* 
                    <FormWhite>
                        <DashboardActions actions={actionsTab} />
                    </FormWhite>
                */}

               <DashboardCardWrapper closeable title={"Criar Mapa de concorrência"} actions={!rows?.length || true ? null : actions} white>
                    <DashboardFormsCore formItems={formMapConcurrence} register={register} ref={refForm} />
                </DashboardCardWrapper>
                {
                    !rows?.length ? null : <>
                        <BasicTable rows={rows} columns={columns} preColumns={preColumns} columnsAfterRows={columnsAfterRows} blue />
                        
                            <ButtonCenter>
                                <Button onClick={addSupplier} outline primary>Adicionar novo</Button>
                            </ButtonCenter>

                        { avaSup?.length ? <BasicTable rows={payRows} columns={payColumns} preColumns={payPreColumns} blue /> : null }
                    </>
                }



            </DashboardCardWrapper>

                <FormActions> 
                    <DashboardActions actions={!rows?.length || !form?.selectedSupplier ? null : actionsForm} loading={loading} />
                </FormActions> 
        </ContainerAuthenticated>
        </>
    );
}