import React from "react";  

import {  
    CardHomeChart
} from "./styled";

import { Chart } from "react-google-charts";

// import { monthsOptions } from "utils/brazil";
import DashboardCardWrapper from "../Wrapper";
import { theme } from "ui/theme-color";
import { yearOptions } from "utils";
import { Load, LoadCenter } from "ui/styled";

export default function DashboardCardChart({ filterYear, loading, items }){  

    // const mockValues = monthsOptions.map((m,k) => [ m?.acron , (200 * (k % 3 === 0 ? k : 2)) ]) || []
    const data = [
        ["Mês", "Total"],
        ...items
    ];
      
    const options = {
        curveType: "function",
        legend: { position: "none" },
        colors:[theme.palette.primary.main],
        backgroundColor: theme.palette.colors.backgroundColor,
        tooltip: {trigger: 'selection'},
    };

    return ( 
        <>
            <DashboardCardWrapper title={"Contas a pagar"} selectable={{ options:yearOptions, placeholder:"Ano", onChange: filterYear }}>
                <CardHomeChart>
                    {
                        loading ? 
                            <LoadCenter> <Load /> </LoadCenter>
                        : 
                        <Chart
                            chartType="LineChart"
                            width="100%"
                            height="300px"
                            data={data}
                            options={options}
                                />
                    }
                </CardHomeChart>
            </DashboardCardWrapper>
        </>
    );
}