import React, { useEffect, useState } from "react";  

import { useHistory } from "react-router-dom";

import { ContentTableAction, ContentTableActionIconEdit, ContentTableActionIconRemove, ContentTableItem, ContentTableRowTabs, DashboardTitle } from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import DashboardCardTable from "components/Cards/Table";
import DashboardCardWrapper from "components/Cards/Wrapper";

import Button from "components/Form/Button";
import { BetweenCenter, DashboardIcon, EmptyDiv, StatusItem, StatusItemAction, StatusItemContent } from "ui/styled";
import DashboardListActions from "components/Dashboard/ListActions";
import { Read, ReadAll } from "services/medition-payments";

import { ReadOne as ReadMedition } from "services/meditions";
import { ReadOne as ReadOrder } from "services/orders";
import { ReadOne as ReadOneSupply } from "services/suppliers";
import { ReadOne as ReadOneRequest } from "services/requests";

import { normalizeStrapiList, normalizeStrapiRegister } from "utils";
import { parseCurrency, parseDatestringUSBR } from "utils/parsers";

export default function DashboardListFinanceControl(){  

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const columns = [
        { title:'Vencimento', ref:'expires_at' },
        { title:'NF', ref:'nf' },
        { title:'Descrição', ref:'description' },
        { title:'Favorecido', ref:'favored' },
        { title:'Categoria', ref:'category' },
        { title:'Valor a pagar', ref:'value' },
        
        { 
            title:'Status',  
            renderCell: ({ row }) => <>
                <StatusItemContent>
                    <StatusItem color={row?.status_color}>
                        { row?.status }
                    </StatusItem>
                </StatusItemContent>
            </>
        },        
        { 
            title:'Comprovante',  
            renderCell: ({ row }) => !row?.invoice ? null : <>
                <StatusItemContent>
                    <StatusItemAction onClick={() => window.open(row?.invoice, "new")}>
                        <DashboardIcon nospace icon={"proof"} />
                    </StatusItemAction>
                </StatusItemContent>
            </>
        },        
        // { 
        //     title:' ', 
        //     renderCell: ({ row }) => <>
        //         <ContentTableAction>
        //             {/* <ContentTableItem  onClick={() => navigate(`dashboard/measurements/edit/${ row.id }`)}>
        //                 <ContentTableActionIconEdit />
        //             </ContentTableItem> */}
        //             <ContentTableItem onClick={() => remove(row)}>
        //                 <ContentTableActionIconRemove />
        //             </ContentTableItem>
        //         </ContentTableAction>
        //     </>
        // }
    ] 

    const [rows, setRows] = useState([ ])

    const parseRowRequest = (item, creq) => {

        const currentMode = creq?.type === 'service' ? `contract_item` : `material`

        const nitem = {
            ...item,
            ...item?.[currentMode]
        }

        return ({ 

            item: nitem?.item || nitem?.id, 
            description: nitem?.title || nitem?.details, 
            qtd: nitem?.quantity || 1, 
            unit: nitem?.unit || 1, 
            unitDisc: "R$ 0,00",
            unitCost: parseCurrency(nitem?.value), 
            total: parseCurrency(nitem?.value * (nitem?.quantity || 1)), 
            raw_unitCost: (nitem?.value||0), 
            raw_total: ((nitem?.value||0) * (nitem?.quantity || 1)), 

        })
    }

    const parseRow = async item => {

        if(item?.id){

            const resultMedition = await ReadMedition(item?.medition?.data?.id)
            const normalMedition = normalizeStrapiRegister(resultMedition)
            console.log("normalMedition", normalMedition)
            
            const resultOrder = await ReadOrder(normalMedition?.order?.id)
            const normalOrder = normalizeStrapiRegister(resultOrder)
            console.log("normalOrder", normalOrder)
            
            const resultRequest = await ReadOneRequest(normalOrder?.request?.id)
            const normalRequest = normalizeStrapiRegister(resultRequest)
            console.log("normalRequest", normalRequest)
            
            const resultSupply = await ReadOneSupply(normalOrder?.supplier?.id)
            const normalSupply = normalizeStrapiRegister(resultSupply)
            console.log("normalSupply", normalSupply)

            const nxtRows = normalRequest?.RequestItem?.map((m, k) => ({
                ...m,
                contract_item: normalizeStrapiRegister(m?.contract_item),
                material: normalizeStrapiRegister(m?.material)
            }))
    
            const nextRows = (nxtRows||[]).map((item) => parseRowRequest(item, normalRequest))
    
    
            const subtotal = nextRows?.reduce((p, c) => p + (c?.[`raw_total`]||0),0)
            const taxrate = normalRequest?.type === 'service' ? .17 : .35
            const taxes = taxrate * subtotal
    
            const shippingrate = .01
            const shippings = shippingrate * subtotal
    
            const discountrate = .05
            const discounts = discountrate * subtotal
    
            const total = ((taxes + shippings + subtotal) - discounts)
    
            // setRowsTotal([
            //     { title:"Subtotal", value: parseCurrency(subtotal) , nbsp },
            //     { title:"Impostos", value: parseCurrency(taxes), nbsp },
            //     { title:"Desconto geral", value: parseCurrency(discounts), nbsp },
            //     { title:"Fretes", value: parseCurrency(shippings), nbsp },
            //     { title:"Total geral", value: parseCurrency(total), nbsp },
            // ])

            // Em atraso - red
            // Em aberto - yellow

            return ({
                expires_at: parseDatestringUSBR(normalMedition?.date_medition),
                nf: normalMedition?.invoice,
                description: nextRows?.[0]?.description,
                favored: normalSupply?.name,
                category: normalRequest?.type === 'service' ? "Serviço" : "Material",
                value: parseCurrency(total||0),
                status: !item?.bank_invoice?.data?.url ? "Em aberto" : "Pago",
                status_color: !item?.bank_invoice?.data?.url ? "yellow" : "green",
                invoice: item?.bank_invoice?.data?.url 
            })

        }


        // return ({
        //     expires_at: "01/02/2023",
        //     nf: "123456789",
        //     description:"Texto descritivo",
        //     favored:"Mariana da Fonseca da Silva",
        //     category:"Texto descritivo",
        //     value:"R$ 123.456.123,12",
        //     status:"Pago",
        //     status_color:"green"
        // })
        return null
    }

    // const [loading, setLoading] = useState(false)
    const remove = async row => {
        // setLoading(true)
        // const user = await ReadByEmail(row?.email)
        // if(user?.[0]?.id){
        //     await DeleteUser(user?.[0]?.id)
        // }
        // const result = await Delete(row?.id)
        // if(result && !exposeStrapiError(result)){
        //     toast.success('Removido com sucesso') ;
        // }
        // init()
    }

    const [activeTab, setActiveTab] = useState(0)

    const tabs = [
        { title:"Todas" },
        { title:"Contas a pagar" },
        { title:"Contas a receber" },
    ]


    const init = async () => {
        const result = await ReadAll()
        const normalResult = normalizeStrapiList(result)

        console.log("normalResult", normalResult)

        const promises = normalResult.map( parseRow )
        const nextRows = await Promise.all(promises)
        setRows(nextRows?.filter(f => f))
    }

    useEffect(() => {
        init()
    }, [])

    return ( 
        <>
            <ContainerAuthenticated page={4} subpage={2}> 
                <BetweenCenter spaced>
                    <DashboardTitle>Controle financeiro</DashboardTitle>
                    <EmptyDiv>
                        <Button secondary nospace>Novo</Button>
                    </EmptyDiv>
                </BetweenCenter>
                {/* 
                <ContentTableRowTabs>
                    {
                        tabs?.map((item, key) => 
                            <EmptyDiv key={key}>
                                <Button primary onClick={() => setActiveTab(key)} outline={activeTab !== key}>{ item.title }</Button>
                            </EmptyDiv>
                        )
                    }
                </ContentTableRowTabs> 
                */}
                <Row>  
                    <Col sm={12}>
                        <DashboardCardWrapper custom={
                            <DashboardListActions />
                        }>
                            <DashboardCardTable rows={rows} columns={columns} blue />
                        </DashboardCardWrapper>
                    </Col> 
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}