import React, { useContext, useEffect, useRef, useState } from "react";

import { ModalBody, ModalCard, ModalHeader, ModalHeaderActions, ModalHeaderIconClose, ModalHeaderTitle, ModalSearchContent } from "./styled";

import ModalWrapper from "../Wrapper";

import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import { ButtonContent, InputContent, WrapContent } from "ui/styled";
import BasicTable from "components/Form/Table";

import { Read } from "services/contract-items";
import { Read as ReadCategories } from "services/services";
import { Read as ReadSubcategories } from "services/sub-services";

import { normalizeStrapiList } from "utils";
import { parseCurrency, parseOptions } from "utils/parsers";
import Input from "components/Form/Input";
import Select from "components/Form/Select";

export default function ModalContractItems(){

    const { modal, setModal } = useContext(CoreContext)

    const [loading, setLoading] = useState(false)
    const [searchExpression, setSearchExpression] = useState("")

    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const columns = [
        { title:'ID.', ref:'display_id' },
        { title:'Item', ref:'item' },
        { title:'Escopo', ref:'scope' },
        { title:'Qt.', ref:'quantity' },
        { title:'Un.', ref:'unit' },
        { title:'Custo Un. Mão de obra', ref:'cost' },
        { title:'Custo total Mão de obra', ref:'cost_total' },
        { title:'Custo Un. Material', ref:'material_cost' },
        { title:'Custo total Material', ref:'material_cost_total' },
        { title:'Valor total do item', ref:'value_item', color:'backgroundgrey' },
        { title:'Verba real', ref:'value', color:'backgroundgrey' }
    ] 

    const [selected, setSelected] = useState(modal?.selected || [ ])

    const [categories, setCategories] = useState([ ])
    const [subcategories, setSubcategories] = useState([ ])

    const [rows, setRows] = useState([ ])

    const parseRow = (item) => {
        return ({ 
            ...item,
            raw: item,
            description: item?.details,
            service: item?.service?.data?.attributes?.name,
            service_id: item?.service?.data?.id,
            sub_service: item?.sub_service?.data?.attributes?.name,
            sub_service_id: item?.sub_service?.data?.id,
            value_item: parseCurrency(item?.value_item),
            value: parseCurrency(item?.value)
        })
    }
    
    const close = () => {
        setModal(null)
    }
    
    const filterExpression = item => {
        return ( !searchExpression || Object.keys(item).filter(k => `${ item[k] }`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1 ).length > 0)
    }

    const filterForm = item => {
        return (!form?.category || ( item?.service_id === form.category )) || 
            (!form?.subcategory || ( item?.sub_service_id === form.subcategory ))
    }

    const confirm = () => {
        if( typeof modal?.confirm === "function"){
            modal?.confirm(selected, modal?.row)
        }
        close()
    }

    const init = async () => {
        setLoading(true)
        const result = await Read( modal?.contract_id )
        if(result?.data?.length){
            const normalResult = normalizeStrapiList(result)
            setRows(normalResult?.map(parseRow)?.filter(f => f.type === modal?.req_type))
        }
        
        const result_cat = await ReadCategories()
        if(result_cat?.data?.length){
            const normalResultCat = normalizeStrapiList(result_cat)
            setCategories(normalResultCat?.map(parseOptions))
        }
        
        const result_sub = await ReadSubcategories()
        if(result_sub?.data?.length){
            const normalResultSub = normalizeStrapiList(result_sub)
            setSubcategories(normalResultSub?.map(parseOptions))
        }
        setLoading(false)
    }

    useEffect(() => { init() ;}, [])
    
    return (
        <>
            <ModalWrapper>
                <ModalCard>
                    <ModalHeader>
                        <ModalHeaderTitle>Materiais</ModalHeaderTitle>
                        <ModalHeaderIconClose onClick={close} />
                    </ModalHeader>
                    <ModalSearchContent>
                        <InputContent>
                            <Input placeholder="Pesquisar" onChange={e => setSearchExpression(e.target.value)} value={searchExpression} />
                        </InputContent>
                        {/* <WrapContent>
                            <InputContent>
                                <Select placeholder="Categoria" options={categories} onChange={e => changeForm(e, 'category')} value={formValue('category')} />
                            </InputContent>
                            <InputContent>
                                <Select placeholder="Subcategoria" options={subcategories} onChange={e => changeForm(e, 'subcategory')} value={formValue('subcategory')} />
                            </InputContent>
                        </WrapContent> */}
                    </ModalSearchContent>
                    <ModalBody>
                        <BasicTable single={modal?.single} selectable={setSelected} selected={selected} loading={loading} columns={columns} blue rows={ rows?.filter(filterExpression)?.filter(filterForm) } />
                    </ModalBody>
                    <ModalHeaderActions>
                        <ButtonContent>
                            <Button primary outline onClick={close}>Cancelar</Button>
                        </ButtonContent>
                        <ButtonContent>
                            <Button secondary onClick={confirm}>Inserir itens</Button>
                        </ButtonContent>
                    </ModalHeaderActions>
                </ModalCard>
            </ModalWrapper>
        </>
    )
}