import React from "react";  

import { 
    FormActions, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import useController from "./controller";
import { formBankSupplier, formPersonalDataSupplier } from "utils/forms";

export default function DashboardFormsRegisterSuppliers(){   
    
    const {
        refForm,
        refFormBank,

        register,
        loading,
        saving,

        actions,
        
        remove,
        id
    } = useController()

    return ( 
        <>
        <ContainerAuthenticated page={1} subpage={3}>
            <DashboardCardWrapper backable title={"Novo fornecedor"} actions={actions} loading={loading}>

                <DashboardCardWrapper closeable title={"Dados do fornecedor"} white>
                    <DashboardFormsCore formItems={formPersonalDataSupplier} register={register} ref={refForm} />
                </DashboardCardWrapper>
                <DashboardCardWrapper closeable title={"Dados bancários"} white>
                    <DashboardFormsCore formItems={formBankSupplier} register={register} ref={refFormBank} />
                </DashboardCardWrapper>
                
                <FormActions> 
                    <DashboardActions removeable={!id ? null : remove} actions={actions} loading={saving} />
                </FormActions>

            </DashboardCardWrapper>
        </ContainerAuthenticated>
        </>
    );
}