import React from "react";  

import { 
    FormActions, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import { formAddress, formBuyRequest, formContacts, formPersonalDataCustomer } from "utils/forms";
import useController from "./controller";
import DashboardCardTable from "components/Cards/Table";
import DashboardCardTotal from "components/Cards/Total";
import Button from "components/Form/Button";
import { ButtonCenter, LoadCenter } from "ui/styled";
import BasicTable from "components/Form/Table";

export default function DashboardDetailsContructionMaterials(){   
    
    const { 

        refForm, 
        register,
        loading, 
        
        actionsForm,
        actions,
 
        rows,
        columns,

        addItem,
        formData

    } = useController()

    return ( 
        <>
        <ContainerAuthenticated noside page={2} subpage={1}>
            <DashboardCardWrapper backable title={`Solicitação de compra - ${ register?.name || "" }`} actions={actionsForm} loading={loading}>

                <DashboardCardWrapper closeable title={"Dados da solicitação"} white  actions={actions} >
                    <DashboardFormsCore formItems={formData} register={register} ref={refForm} />
                </DashboardCardWrapper>
                <DashboardCardWrapper closeable title={"Lista de itens"} white>
                        
                    <DashboardCardTable title={"1.0 - Serviços preliminares"} rows={rows} columns={columns} blue />
 
                    <ButtonCenter>
                        <Button secondary outline onClick={addItem}>Adicionar</Button>
                    </ButtonCenter> 

                </DashboardCardWrapper>                

            </DashboardCardWrapper>
        </ContainerAuthenticated>
        </>
    );
}