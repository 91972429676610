import React from "react";  

import { 
    FormActions, 
} from "./styled";


import DashboardActions from "components/Actions";
import DashboardCardWrapper from "components/Cards/Wrapper";
import DashboardFormsCore from "components/Dashboard/FormCore";

import ContainerAuthenticated from "containers/Authenticated";
import useController from "./controller";
import { formAddress, formBankColaborators, formJuridic, formPersonalDataColaborator } from "utils/forms";

export default function DashboardFormsRegisterColaboratos(){   

    const {
        refForm,
        refFormJuridic,
        refFormBank,
        refFormBank2,
        refFormAddress,
        
        formData,

        registerAddress,
        registerBank,
        registerOtherBank,
        
        fillAddress,
        register,
        loading,
        saving,

        actions,
        remove,
        id
    } = useController()

    return ( 
        <>
            <ContainerAuthenticated page={1} subpage={5}>
                <DashboardCardWrapper backable title={"Colaborador - Nome do colaborador"} actions={actions} loading={loading}>

                    <DashboardCardWrapper closeable title={"Dados pessoais"} white>
                        <DashboardFormsCore formItems={formData} register={register} ref={refForm} />
                    </DashboardCardWrapper>

                    <DashboardCardWrapper closeable title={"Dados de Pessoa Jurídica "} white>
                        <DashboardFormsCore formItems={formJuridic} register={register} ref={refFormJuridic} />
                    </DashboardCardWrapper>

                    <DashboardCardWrapper closeable title={"Endereço"} white>
                        <DashboardFormsCore formItems={formAddress} register={registerAddress} ref={refFormAddress} />
                    </DashboardCardWrapper>

                    <DashboardCardWrapper closeable title={"Dados bancários 1"} white>
                        <DashboardFormsCore formItems={formBankColaborators} register={registerBank} ref={refFormBank} />
                    </DashboardCardWrapper>

                    <DashboardCardWrapper closeable title={"Dados bancários 2"} white>
                        <DashboardFormsCore formItems={formBankColaborators} register={registerOtherBank} ref={refFormBank2} />
                    </DashboardCardWrapper>
                    
                    <FormActions> 
                        <DashboardActions removeable={!id ? null : remove} actions={actions} loading={saving} />
                    </FormActions>

                </DashboardCardWrapper>
            </ContainerAuthenticated>
        </>
    );
}