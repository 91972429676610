import React, { useContext } from "react";
import ModalWrapper from "./Wrapper";
import { ThemedComponent } from "ui/theme";

import { CoreContext } from "context/CoreContext";
import ModalMaterials from "./Materials"; 
import ModalContractItems from "./ContractItems";
import ModalDailyPhoto from "./DailyPhoto";
import ModalPayment from "./PaymentData";


export default function ModalController(){
    const { modal } = useContext(CoreContext)
    return (
        <>
            <ThemedComponent> 
                { modal?.type === 'materials' ? <ModalMaterials /> : null }
                { modal?.type === 'contract-items' ? <ModalContractItems /> : null }
                { modal?.type === 'daily-photo' ? <ModalDailyPhoto /> : null }
                { modal?.type === 'payment' ? <ModalPayment /> : null }
            </ThemedComponent>
        </>
    )
}