import React from "react";  

import { DashboardText, StatusItemContainer } from "./styled";

import DashboardCardTable from "components/Cards/Table";

import { BetweenCenter, StatusItem, StatusItemContent } from "ui/styled";

export default function DashboardCardTotal(){  

    const totalColumns = [
        { title:'', ref:'empty' },
        { 
            title:'',  
            renderCell: ({ row }) => <>
                <StatusItemContent >
                    <StatusItemContainer>
                        <DashboardText small>{ row?.title }</DashboardText>
                        <StatusItem blacked color={row?.status_color}>
                            { row?.status }
                        </StatusItem>
                    </StatusItemContainer>
                </StatusItemContent>
            </>
        }
    ] 

    const totalRows = [
        // { empty:" ", title:"Pago", status:"R$ 6.123,12", status_color:"subtotal" },
        // { empty:" ", title:"À pagar", status:"R$ 6.123,12", status_color:"subtotal" },
        // { empty:" ", title:"Atrasadas", status:"R$ 6.123,12", status_color:"subtotal" },
        // { empty:" ", title:"Total geral", status:"R$ 6.123,12", status_color:"total" },
    ]

    return ( 
        <>
            <BetweenCenter headed>
                <DashboardText>Total</DashboardText>
            </BetweenCenter>
            <DashboardCardTable rows={totalRows} columns={totalColumns} />
        </>
    );
}