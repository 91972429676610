import { createTheme } from '@mui/material/styles'; 

export const theme = createTheme({
  mode: 'light',
  palette: {
    primary: {
      main: '#19375D', 
      contrastText: '#fff',
    },
    secondary: {
      main: '#0095AA',
      contrastText: '#fff',
    },
    white: {
      main: '#ffffff',
      contrastText: '#19375D',
    },
    error: {
      main: '#dd4952',
    },
    warning: {
      main: '#ffa726',
    },
    green: {
      main: '#49BF00',
    },
    info: {
      main: '#a4a4a4',
      contrastText: '#fff',
    },
    success: {
      main: '#66bb6a',
    },
    colors:{


        primary: '#19375D', 
        secondary: '#0095AA',

        white: '#ffffff',
        black: '#000000',
        grey: '#4C4C4C',
        title: '#4D4D4D',
        text: '#333333',

        backgroundgrey: '#F2F2F2',
        backgroundcard: '#F7F7F7',
        lightgrey: '#A4A4A4',
        shadow: 'rgba(0,0,0,.16)',
        bordershadow: 'rgba(112,112,112,.1)',
        lightshadow: 'rgba(112,112,112,.06)',
        
        green: '#48BD85',
        yellow: '#D9AE15',
        blue: '#0095AA',
        red: '#E05F86',
        
        chart_blue: '#2E93FA',
        chart_green: '#00E396',
        chart_yellow: '#FFAD33',
        chart_cyan: '#0095AA',
        
        value: 'rgba(25,55,93,.1)',
        subtotal: 'rgba(0,149,179,.1)',
        total: 'rgba(0,149,179,.2)',
        
        subcyan: 'rgba(0,149,179,.4)',
        cyan:"#0095aa",
    }
  },
});  