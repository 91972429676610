import React, { useEffect, useState } from "react";

import { Read } from "services/users";

import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister } from "utils";
import moment from "moment/moment";
import { optionsUserCategory } from "utils/options";

export default function useController() {
 
    const [loading, setLoading] = useState(false) 
    const [rows, setRows] = useState([]) 

    const columns = [
        { title:'CPF', ref:'cpf' },
        { title:'Nome do usuário', ref:'name' },
        { title:'Obra', ref:'construction' },
        { title:'Categoria do usuário', ref:'category' },
        { title:'E-mail', ref:'email' }
    ] 

    const parseRow = item => {
        return { 
            ...item,
            name: item?.name, 
            cpf: item?.cpf, 
            construction: '', 
            category: optionsUserCategory?.find(f => f.ref === item?.category)?.title, 
            email: item?.email,
            // date: moment(normalItem?.createAt)?.format("L"),
        }
    }

    const init = async () => {
        setLoading(true)
        const result = await Read() 
        if(result && !exposeStrapiError(result)){
            if(result?.length){
                console.log("result", result)
                setRows(result?.map(parseRow))
            }else{ setRows([]) ;}
        }
        setLoading(false)
    }

    useEffect(() => { init() ;}, [])
    
    return {
        loading,
        columns,
        rows
    }
}   