import React from "react";  

import { useHistory } from "react-router-dom";

import { ContentTableAction, ContentTableActionIconEdit, ContentTableActionIconRemove, ContentTableItem, DashboardTitle, PDFFrame } from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import DashboardCardTable from "components/Cards/Table";
import DashboardCardWrapper from "components/Cards/Wrapper";

import Button from "components/Form/Button";
import { BetweenCenter, DashboardIcon, EmptyDiv, EndCenter, StartCenter, StatusItem, StatusItemContent } from "ui/styled";
import DashboardListActions from "components/Dashboard/ListActions";
 
export default function DashboardListCustomerManual({ page, subpage }){  

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const columns = [
        { title:'Guia de pagamentos', ref:'payment' },
        { title:'Data de envio', ref:'date' },
        { title:'Total a pagar', ref:'total' },
        { title:'Total pago da guia', ref:'payed' },
        { title:'Saldo a pagar', ref:'balance' },

        { 
            title:'Status',  
            renderCell: ({ row }) => <>
                <StatusItemContent>
                    <StatusItem color={row?.status_color}>
                        { row?.status }
                    </StatusItem>
                </StatusItemContent>
            </>
        },        
        { 
            title:' ', 
            renderCell: ({ row }) => <>
                <ContentTableAction>
                    <ContentTableItem  onClick={() => navigate(`dashboard/payments/edit/${ row.id }`)}>
                        <ContentTableActionIconEdit />
                    </ContentTableItem>
                    <ContentTableItem onClick={() => remove(row)}>
                        <ContentTableActionIconRemove />
                    </ContentTableItem>
                </ContentTableAction>
            </>
        }
    ] 

    const rows = [
        { payment:"Guia - 0012", date:"01/02/2023", total:"R$ 123.456.123,12", payed:"R$ 123.456.123,12", balance:"R$ 123.456.123,12", status:"Pago", status_color:"green" },
        { payment:"Guia - 0012", date:"01/02/2023", total:"R$ 123.456.123,12", payed:"R$ 123.456.123,12", balance:"R$ 123.456.123,12", status:"Pago", status_color:"green" },
        { payment:"Guia - 0012", date:"01/02/2023", total:"R$ 123.456.123,12", payed:"R$ 123.456.123,12", balance:"R$ 123.456.123,12", status:"Pago", status_color:"green" },
        { payment:"Guia - 0012", date:"01/02/2023", total:"R$ 123.456.123,12", payed:"R$ 123.456.123,12", balance:"R$ 123.456.123,12", status:"Pago", status_color:"green" },
        { payment:"Guia - 0012", date:"01/02/2023", total:"R$ 123.456.123,12", payed:"R$ 123.456.123,12", balance:"R$ 123.456.123,12", status:"Em aberto", status_color:"yellow" },
        { payment:"Guia - 0012", date:"01/02/2023", total:"R$ 123.456.123,12", payed:"R$ 123.456.123,12", balance:"R$ 123.456.123,12", status:"Em aberto", status_color:"yellow" },
        { payment:"Guia - 0012", date:"01/02/2023", total:"R$ 123.456.123,12", payed:"R$ 123.456.123,12", balance:"R$ 123.456.123,12", status:"Atrasado", status_color:"red" },
        { payment:"Guia - 0012", date:"01/02/2023", total:"R$ 123.456.123,12", payed:"R$ 123.456.123,12", balance:"R$ 123.456.123,12", status:"Atrasado", status_color:"red" },
        { payment:"Guia - 0012", date:"01/02/2023", total:"R$ 123.456.123,12", payed:"R$ 123.456.123,12", balance:"R$ 123.456.123,12", status:"Atrasado", status_color:"red" },
        { payment:"Guia - 0012", date:"01/02/2023", total:"R$ 123.456.123,12", payed:"R$ 123.456.123,12", balance:"R$ 123.456.123,12", status:"Atrasado", status_color:"red" },
        { payment:"Guia - 0012", date:"01/02/2023", total:"R$ 123.456.123,12", payed:"R$ 123.456.123,12", balance:"R$ 123.456.123,12", status:"Atrasado", status_color:"red" },
        
    ]


    // const [loading, setLoading] = useState(false)
    const remove = async row => {
        // setLoading(true)
        // const user = await ReadByEmail(row?.email)
        // if(user?.[0]?.id){
        //     await DeleteUser(user?.[0]?.id)
        // }
        // const result = await Delete(row?.id)
        // if(result && !exposeStrapiError(result)){
        //     toast.success('Removido com sucesso') ;
        // }
        // init()
    }

    return ( 
        <>
            <ContainerAuthenticated page={page || 6} subpage={ subpage || 3}> 
                <BetweenCenter spaced>
                    <DashboardTitle>Manual de obra</DashboardTitle>
                    {/* <EmptyDiv>
                        <Button secondary nospace>Novo</Button>
                    </EmptyDiv> */}
                </BetweenCenter>
                <Row>  
                    <Col sm={12}>
                        <DashboardCardWrapper>
                            {/* <EndCenter>
                                <EmptyDiv>
                                    <Button outline primary>
                                        <DashboardIcon icon={"download"} />
                                        Baixar
                                    </Button>
                                </EmptyDiv>
                            </EndCenter> */}
                            
                            <PDFFrame src={"/etimo.pdf"} />
                        </DashboardCardWrapper>


                        <DashboardCardWrapper title={"Acesse os arquivos relacionados ao seu projeto"}>
                            <StartCenter>
                                <EmptyDiv>
                                    <Button secondary link>www.linkdoarquivo.com</Button>
                                </EmptyDiv>
                                <EmptyDiv>
                                    <Button secondary outline>Acessar</Button>
                                </EmptyDiv>
                            </StartCenter>
                        </DashboardCardWrapper>
                    </Col> 
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}