import { useCallback, useEffect, useMemo, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister, valid } from "utils";
import { toast } from "react-toastify";
import { formPersonalData } from "utils/forms";

import { Create, ReadOne, Update } from "services/constructions";
import { ReadAddressesByZipCode } from "services/api";
import BasicTable from "components/Form/Table";
import { HeaderCheck, HeaderCheckText, HeaderCheckTitle } from "./styled";
import Select from "components/Form/Select";
import { StatusItem } from "ui/styled";
import Check from "components/Form/Check";
import Radio from "components/Form/Radio";
import { Read } from "services/suppliers";
import { Read as ReadSupplierMaterials } from "services/supplier-materials";
import { Read as ReadContractItems } from "services/contract-items";
import { Read as ReadRequests } from "services/requests";
import { optionsRate } from "utils/options";
import { parseCurrency, parseDatestringUSBR } from "utils/parsers";

export default function useController(){   

    const history = useHistory(); 
    const back = () => { history.goBack() ;}
    const navigate = to => history.push(`/${ to }`); 

    const { id } = useParams()

    const refForm = useRef() 
     
    const [register, setRegister] = useState(null)
    const [loading, setLoading] = useState(false) 
    
    const [active, setActive] = useState(0)
    const [rows, setRows] = useState([ ])

    const [avaSup, setAvaSup] = useState([])
    const [avaSupMat, setAvaSupMat] = useState([])


    const [suppliers, setSuppliers] = useState([])



    const [currentRequest, setCurrentRequest] = useState(null)
    const [availableRequests, setAvailableRequests] = useState([])

    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }); } 

    const formMapConcurrence = useMemo(() => {

        const serviceOptions = availableRequests?.map(m => ({
            ...m,
            title: `${ m?.type === 'service' ? `Serviço` : `Material` } ${ m?.order_number || '' } (${ m?.RequestItem?.length } items)`
        }))

        return  [
            {
                placeholder: "Serviço",
                ref: "service",
                options: serviceOptions,
                onBlur: () => changeService()
            },
            {
                placeholder: "Data",
                ref: "date",
                mask: "99/99/9999"
            },
            {
                placeholder: "Emissor",
                ref: "emissor"
            },
        ]
    }, [ availableRequests, currentRequest, avaSupMat, register ])

    
    const preColumns = useMemo(() => {

        const supplierOptions = suppliers?.map(m => ({ ...m, title: m?.name }))

        return [
            { },
            { },
            { },
            { },
            { },
            { },
            
            { },
            { },
            ...avaSup.map( (m, k) => ({ 
                prerenderCell: () => {
                    const currentSupplier = supplierOptions?.find(f => f.id === formValue(`supplier${m}`) )
                    return <>
                        <HeaderCheck>
                            <Select placeholder={"Fornecedor"} options={supplierOptions} value={formValue(`supplier${k}`)} onChange={val => { changeForm(val, `supplier${k}`); }} />
                            {
                                !currentSupplier ? null : <>
                                    <HeaderCheckText>{ currentSupplier?.contact_name }</HeaderCheckText>
                                    <HeaderCheckText>{ currentSupplier?.phone }</HeaderCheckText>
                                    <HeaderCheckText>{ currentSupplier?.site }</HeaderCheckText>
                                    <StatusItem card color={optionsRate?.find(f => f.id === currentSupplier?.evaluation)?.color}>{ optionsRate?.find(f => f.id === currentSupplier?.evaluation)?.title }</StatusItem>
                                </>
                            }
                        </HeaderCheck>
                    </>
                },
            }))
        ]
    }, [ avaSup, suppliers, form ])
    
    const payPreColumns = useMemo(() => {

        const supplierOptions = suppliers?.map(m => ({ ...m, title: m?.name }))

        return [
            { },
            { },
            { }, 
            
            ...avaSup.map( m => ({ 
                prerenderCell: () => {
                    const currentSupplier = supplierOptions?.find(f => f.id === formValue(`supplier${m}`) )
                    return !currentSupplier ? null : <>
                        <HeaderCheck>
                            <Radio checked={formValue("selectedSupplier") === `${m}`} onChange={val => { changeForm(`${m}`, "selectedSupplier"); }} label={ currentSupplier?.name } />
                            <HeaderCheckTitle>{ currentSupplier?.name }</HeaderCheckTitle>
                        </HeaderCheck>
                    </>
                },
            })),

            {}
        ]
    }, [ avaSup, suppliers, form ])
    
    const payRows = useMemo(() => {
        
        const supplierOptions = suppliers?.map(m => ({ ...m, title: m?.name }))

        let bestQuotation = 0
        const budget = rows?.reduce((p, c) => p + (c?.[`raw_value_total`]||0),0)
        const supQuote = avaSup.map((m, k) => {
            
            const currentSupplier = supplierOptions?.find( f => f.id === formValue(`supplier${m}`) )

            const subtotal = rows?.reduce((p, c) => p + (c?.[`raw_supplier_value_total${k}`]||0),0)
            const taxrate = currentRequest?.type === 'service' ? .17 : .35
            const taxes = taxrate * subtotal

            const shippingrate = .01
            const shippings = shippingrate * subtotal

            const discountrate = .05
            const discounts = discountrate * subtotal

            const total = ((taxes + shippings + subtotal) - discounts)

            if( !bestQuotation || bestQuotation > total){
                bestQuotation = total
            }

            console.log("currentSupplier", currentSupplier)
            
            return ({
                ...m,
                k,
                subtotal: parseCurrency(subtotal),
                shipping: parseCurrency(shippings),
                taxes: parseCurrency(taxes),
                discount: parseCurrency(discounts),
                total: parseCurrency(total),
                pagto: "à vista",
                term: "10 dias",
            })
        })

        return [
            {
                resume:"Valor orçado",
                resume_value: parseCurrency(budget),
                balance:"Subtotal",
                ...supQuote.reduce((p, c) => ({ ...p, [`quote${c?.k+1}`]: c.subtotal }), {}),
            },
            {
                resume:"Melhor cotação",
                resume_value: parseCurrency(bestQuotation),
                balance:"frete",
                ...supQuote.reduce((p, c) => ({ ...p, [`quote${c?.k+1}`]: c.shipping }), {}),
            },
            {
                resume:"Saldo",
                resume_value: parseCurrency(budget - bestQuotation),
                balance:"Impostos",
                color: "green",
                ...supQuote.reduce((p, c) => ({ ...p, [`quote${c?.k+1}`]: c.taxes }), {}),
            },
            { resume:" ", resume_value:" ", balance:"Descontos", ...supQuote.reduce((p, c) => ({ ...p, [`quote${c?.k+1}`]: c.discount }), {}) },
            { resume:" ", resume_value:" ", balance:"Total Geral", ...supQuote.reduce((p, c) => ({ ...p, [`quote${c?.k+1}`]: c.total }), {})  },
            { resume:" ", resume_value:" ", balance:"Cond. Pagto", ...supQuote.reduce((p, c) => ({ ...p, [`quote${c?.k+1}`]: c.pagto }), {})  },
            { resume:" ", resume_value:" ", balance:"Prazo de entrega", ...supQuote.reduce((p, c) => ({ ...p, [`quote${c?.k+1}`]: c.term }), {})  }
        ]
    }, [rows, avaSup, suppliers, form])

    const columns = [
        { title:'Item', ref:'item' },
        { title:'Descrição do serviço', ref:'details' },
        { title:'Qt.', ref:'quantity' },
        { title:'Un.', ref:'unit' },
        { title:'Valor Unit.', ref:'value_unit' },
        { title:'Valor Total', ref:'value_total' },
        
        { title:'Saldo (em R$)', ref:'balance_unit', color:"backgroundcard" },
        { title:'Valor Total', ref:'balance_total', color:"backgroundcard" },
    ] 

    const payColumns = useMemo(() => {
        return [
            { title:'Resume da cotação', ref:'resume' },
            { title:' ', ref:'resume_value' },
            { title:'Saldo', ref:'balance' },
            ...avaSup.map((m, k)=> ({
                title:`${k+1}º Melhor cotação`, ref:`quote${k+1}`
            })),

            {}
        ]
    }, [avaSup, form])
    
    const columnsAfterRows = useMemo(() => {

        return [
            ...avaSup.map((m, k) => {

                const supColumns = [
                    { title:'Saldo (em R$)', ref:`supplier_value_unit${k}` },
                    { title:'Valor Total', ref:`supplier_value_total${k}`, color:"backgroundcard" },
                ]
        
                return { 
                    columns: supColumns,
                    renderCell: ({ row }) => <>
                        <BasicTable noHeader rows={[ row ]} columns={supColumns} blue />
                    </>,
                }
            })
        ] 
    }, [avaSup, form])

    const actions = [
        { title:'Imprimir', outline:true, icon:'print', primary:true, action:() => null },
        { title:'Baixar', outline:true, icon:'download', primary:true, action:() => null },
        // { title:'Cancelar', outline:true, action:() => history.goBack() },
        // { title:'Salvar', action:() => save(), loadable:true }
    ]

    const actionsForm = [
        // { title:'Editar', outline:true, action:() => null },
        { title:'Gerar ordem de compra', action:() => save(), loadable:true }
    ]

    const actionsTab = [
        { title:'Mapa de concorrência', primary:true, outline:true, action:() => null },
        { title:'Ordem de compra', primary:true, action:() => navigate(`dashboard/details/construction/orders/${id}`), loadable:true }
    ]
 
    const parseRow = (item, sups, supsMaterials, creq) => {

        const currentMode = creq?.type === 'service' ? `contract_item` : `material`

        const nitem = {
            ...item,
            ...item?.[currentMode]
        }

        let supvalues = {}

        sups?.forEach((m,k) => {

            const supplierOptions = suppliers?.map(m => ({ ...m, title: m?.name }))
            const currentSupplier = supplierOptions?.find(f => f.id === formValue(`supplier${m}`) )
            
            const sup_value = supsMaterials?.find(f => {

                const fmod = f?.[currentMode]?.data?.attributes

                const res = (
                    (
                        (fmod?.title && fmod?.title === nitem?.title) ||
                        (fmod?.details && fmod?.details === nitem?.details )
                    ) && 
                    fmod?.type === nitem?.type && 
                    f?.supplier?.data?.id === currentSupplier?.id 
                )

                // console.log("VALSUP", fmod, f, res)

                return res
            })?.value || 0; 
            supvalues[`supplier_value_unit${k}`] = !sup_value ? "-" : parseCurrency(sup_value)
            supvalues[`supplier_value_total${k}`] = !sup_value ? "-" : parseCurrency(sup_value * (nitem?.quantity || 1))
            supvalues[`raw_supplier_value_unit${k}`] = !sup_value ? 0 : (sup_value)
            supvalues[`raw_supplier_value_total${k}`] = !sup_value ? 0 :(sup_value * (nitem?.quantity || 1))
        })

        // console.log('supvalues', supvalues, supsMaterials, nitem, creq, currentMode)

        return ({ 
            ...nitem,
             
            item: nitem?.item || nitem?.id, 
            details: nitem?.title || nitem?.details, 
            quantity: nitem?.quantity || 1, 
            unit: nitem?.unit || 1, 

            value_unit: parseCurrency(nitem?.value), 
            value_total: parseCurrency(nitem?.value * (nitem?.quantity || 1)), 
            raw_value_unit: (nitem?.value||0), 
            raw_value_total: ((nitem?.value||0) * (nitem?.quantity || 1)), 

            balance_unit: parseCurrency(nitem?.value), 
            balance_total: parseCurrency(nitem?.value * (nitem?.quantity || 1)), 

            ...supvalues

            // supplier_value_unit: parseCurrency(sup_value), 
            // supplier_value_total: parseCurrency(sup_value * item?.quantity), 
        })
    }

    const changeService = () => {
        const cform = refForm?.current?.getForm()
        
        const crequest = availableRequests?.find(f => f?.id === cform?.service )
        const requestedMaterials = (crequest?.RequestItem || [])
        // console.log("cform", cform, requestedMaterials)

        // setAvaMat(requestedMaterials)
        setRows(requestedMaterials.map(m => parseRow(m, avaSup, avaSupMat, crequest)))

        setRegister({
            ...register,
            service: cform?.service,
            date: parseDatestringUSBR(crequest?.delivery_date),
            emissor: crequest?.requester,
        })

    }

    const addSupplier = () => {
        setAvaSup([
            ...avaSup,
            avaSup?.length
        ])
    }

    const save = async () => {
        const cform = refForm?.current?.getForm()
        const currentSupplier = suppliers?.find(f => f.id === formValue(`supplier${ form?.selectedSupplier }`) )
        navigate(`dashboard/details/construction/orders/${id}/${cform?.service}/${currentSupplier?.id}`)
    }
    
    const init = useCallback(async () => {
        setLoading(true)
        
        if(id){ 
            const result = await ReadOne(id)
            const normalResult = result?.data?.id ? normalizeStrapiRegister(result) : {}
            setRegister({ ...normalResult, customer: normalResult?.customer?.name })
            
            const suppliers = await Read()
            const normalSup = suppliers?.data?.length ? normalizeStrapiList(suppliers) : []
            setSuppliers(normalSup)
            
            const requests = await ReadRequests(id)
            const normalReq = requests?.data?.length ? normalizeStrapiList(requests) : []

            const preq = normalReq?.map((item, key) => ({
                ...item,
                RequestItem: item?.RequestItem?.map((m, k) => ({
                    ...m,
                    contract_item: normalizeStrapiRegister(m?.contract_item),
                    material: normalizeStrapiRegister(m?.material)
                }))
            }))
            
            setAvailableRequests(preq)

            const suppliersMat = await ReadSupplierMaterials()
            const normalSupMat = suppliersMat?.data?.length ? normalizeStrapiList(suppliersMat) : []

            setAvaSupMat(normalSupMat)
        }    

        setLoading(false)
    }, [id])
    
    useEffect(() => { changeService() ;} , [form])
    useEffect(() => { init() ;} , [id, init])

    return {
        refForm, 
        register,
        loading, 

        actions,
        actionsForm,
        actionsTab,

        active, 
        setActive, 

        columns,
        preColumns,

        rows,
        columnsAfterRows,

        payColumns,
        payPreColumns,
        payRows,

        formMapConcurrence,
        avaSup,
        addSupplier,

        form
    }
}